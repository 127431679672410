$mobile-navbar-height: 55px;
$desktop-navbar-height: 75px;
$desktop-nav-breakpoint: 'nav_lg';

body {
	&.nav-open {
		overflow: hidden;
	}
}


.page-main {
	position: relative;
	z-index: 0;
}

a {
	&:active {
		text-decoration: none;
	}
}

.mage-error {
	color: #e02b27;
	font-size: 12px;
	margin-block-start: 7px;
}

@include respond-to(769px) {
	.page-wrapper .breadcrumbs-top-wrapper {
		display: none;
	}
}

@include respond-to(medium) {
	.catalog-category-view.page-layout-1column .column.main {
		min-height: inherit;
	}
}

.category-in-stock-rifles {
	.category-image {
		display: none;
	}
}

canvas {
	display: none;
}

.mageplaza-betterpopup-block {
	position: relative;

	.mageplaza-betterpopup-content {
		display: table;
		width: 100%;
		height: 100%;
		font-family: 'Muli',sans-serif;
	}

	#bio_ep_close {
		position: absolute;
		z-index: 99;
		top: 8px;
		right: 8px;
		color: #fff;
		font-size: 20px;
		font-weight: 700;
		cursor: pointer;
	}

	.better-popup-content {
		position: relative;
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		height: 100%;
		color: #FFFFFF;

		#popup-success-content {
			height: 100%;
		}

		.email-popup-container {
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.content {
			position: absolute;
			top: 50%;
			left: 12%;
			display: block;
			width: 75%;
			padding: 1.5em;
			transform: translateY(-50%);
			text-align: center;

			h2 {
				font-size: 33px;
				font-weight: 700;
				line-height: 1;
			}

			p {
				margin: 30px 0;
				font-size: 26px;
			}

			.form {
				width: 70%;
				margin: 0 auto;

				.subscribe {
					width: 100%;
				}

				.newsletter {
					width: 100%;
					margin: 10px 0;

					.control {
						display: block;
    					margin: 0;

						input {
							width: 100%;
							min-height: 45px;
							padding-left: 11px;
							border: none;
							color: #979797;
							font-size: 14px;
							font-style: italic;
							font-weight: 600;
							letter-spacing: 0.5px;
							line-height: 18px;
						}
					}
				}

				.actions {
					width: 100%;

					.primary {
						display: inline-block;
						vertical-align: middle;
						width: 100%;
						height: 48px;
						padding: 7px 15px;
						border: 1px solid #d32700;
						border-radius: 0;
						background-color: #d32700;
						color: #fff;
						font-size: 14px;
						font-weight: 600;
						cursor: pointer;

						span {
							font-size: 16px;
							font-weight: 800;
							letter-spacing: 2px;
							line-height: 16px;
							text-align: center;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}
