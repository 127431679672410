// Cart
// sass-lint:disable-all

.checkout-cart-index {
	.page-wrapper {
		background-color: #0a0a0a;
	}

	.page-main {
		max-width: 1280px;

		> .page-title-wrapper {
			margin-bottom: 68px;
			padding-top: 82px;

			.page-title {
				margin: 0;
				font-size: 30px;
				color: #fff;
				font-weight: 700;
				letter-spacing: 4px;
				text-transform: uppercase;

				@include respond-to('small') {
					font-size: 40px;
					letter-spacing: 12px;
				}
			}
		}
	}

	.cart-empty {
		color: #fff;

		a {
			color: #000;

			&:hover {
				color: #fff;
			}
		}
	}

	.cart-container {
		padding-bottom: 60px;

		.form-cart {
			@include respond-to('nav_md') {
				padding-right: 34px;
				width: 68.88%;
				padding-left: 5px;
			}

			.cart.table-wrapper {
				.items {
					min-width: 100%;
					width: auto;

					thead + .item {
						border-top: 0;
					}

					> .item {
						border-bottom: 1px solid #777;

						&:nth-last-of-type(1) {
							border-bottom: 1px solid #777;
						}
					}

					.item {
						border-top: none;
						position: relative;

						.product-item-details {
							display: block;

							@include respond-to('large') {
								padding-bottom: 35px;
								display: table-cell;
							}
						}

						.product-item-photo {
							max-width: 100%;
							position: relative;
							padding-bottom: 30px;

							@include respond-to('nav_md') {
								padding: 0 20px 0 0;
							}
						}

						.product-item-name {
							font-size: 16px;
							display: block;
							margin: 5px 0 9px;
							color: #fff;
							font-weight: 700;

							a {
								color: #fff;

								&:hover {
									text-decoration: underline;
								}
							}
						}

						.col {
							padding: 36px 8px 10px 0;
							min-height: 75px;
							position: relative;
							color: #fff;

							&::before {
								color: #fff;
								opacity: 0.5;
								text-transform: uppercase;
								font-size: 14px;
								font-weight: 500;
							}

							&.price,
							&.qty {
								text-align: left;

								@include respond-to('nav_md') {
									text-align: center;
								}
							}

							&.price,
							&.subtotal,
							&.msrp {
								padding: 36px 20px 10px 0;

								@include respond-to('nav_md') {
									padding: 36px 20px 10px;
								}
							}

							&.subtotal,
							&.price {
								.price-excluding-tax {
									font-size: 14px;
									margin-top: 8px;
									color: #fff;
									font-weight: 400;
								}
							}

							&.subtotal {
								text-align: left;

								@include respond-to('nav_md') {
									text-align: right;
								}
							}

							&.qty .input-text {
								border-radius: 0;
								height: 32px;
								width: 32px;
								margin-top: 0;
								padding: 3px;
								border: 0;
								color: #000;
								text-align: center;
								min-height: 0;
							}
						}

						.item-actions {
							td {
								padding: 0;
							}
						}

						.actions-toolbar {
							padding-top: 16px;
							padding-bottom: 26px;
							text-align: left;
							min-height: 20px;
							position: relative;

							@include respond-to('nav_md') {
								text-align: right;
								padding-bottom: 13px;
								padding-top: 0;
							}

							> .action {
								margin: 0;
								margin-right: 6px;
								position: static;
								display: inline-block;
								text-decoration: none;
								background: none;
								border: 0;
								padding: 0;
								opacity: 0.7;

								&:hover {
									opacity: 1;
								}

								span {
									display: none;
								}

								&::before {
									background-position: center;
									background-repeat: no-repeat;
									background-size: 20px;
									content: '';
									width: 20px;
									height: 20px;
									display: block;
								}

								&.action-edit {
									&::before {
										background-image: url('../images/cart-edit.svg');
									}
								}

								&.action-delete {
									&::before {
										background-image: url('../images/cart-delete.svg');
									}
								}
							}
						}
					}
				}

				thead tr th.col.item {
					padding-left: 0;
				}

				thead tr th.col {
					font-size: 12px;
					border-bottom: 1px solid #777;
					opacity: .5;
					padding-bottom: 12px;
					padding-top: 24px;
					color: #fff;
					text-transform: uppercase;

					&.price,
					&.qty {
						text-align: left;

						@include respond-to('nav_md') {
							text-align: center;
						}
					}
				}
			}
		}

		.cart-discount-action {
			width: 100%;
			float: left;
			display: flex;
			justify-content: space-between;

			@include respond-to('nav_md') {
				padding-right: 34px;
				width: 68.88%;
				padding-left: 5px;
			}

			.action.continue {
				font-size: 12px;
				position: relative;
				display: inline-block;
				color: #fff;
				font-weight: 700;
				text-transform: uppercase;

				&:hover {
					text-decoration: underline;
				}

				&::before {
					border: solid #fff;
					border-width: 0 1px 1px 0;
					display: inline-block;
					padding: 4px;
					transform: rotate(135deg);
					content: '';
				}
			}

			.cart-discount {
				position: relative;
				display: flex;
				gap: 30px;

				.block.giftcard {
                    margin-bottom: 25px;

                    .giftcard.info {
                        margin-bottom: 8px;

                        p {
                            color: #fff;
                            margin-bottom: 5px;

                            &:nth-last-of-type(1) {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
			}

			.block.giftcard .amcard-field-block {
				display: flex;

				.amgcard-datalist-container {
					width: calc(100% - 160px);
				}
			}

			.block.giftcard,
			.block.discount {
				.payment-option-title,
				.title {
					position: relative;
					display: inline-block;
					color: #fff;
					cursor: pointer;
					margin-bottom: 11px;

					.action-toggle,
					strong {
						font-size: 12px;
						font-weight: 700;
						text-transform: uppercase;
					}

					&::after {
						position: relative;
						top: -2px;
						margin-left: 5px;
						border: solid #fff;
						border-width: 0 1px 1px 0;
						display: inline-block;
						padding: 4px;
						transform: rotate(45deg);
						content: '';
					}
				}

				input {
					border-radius: 0;
					font-size: 14px;
					border: 0;
					background: #fff;
					color: #000;
					min-height: 0;
				}

				.amcard-title {
					display: none;
				}

				.amcard-datalist-message {
					margin-bottom: 15px;
					font-size: 12px;
					color: #fff;
				}

				.amcard-check,
				.amcard-button {
					font-size: 12px;
					border-radius: 0;
					box-shadow: none;
					border: none;
					margin: 0;
					padding: 10px 15px;
					height: 36px;
					width: 160px;
					color: #fff;
					background: #d32700;
					line-height: 1;
					letter-spacing: 2px;
					font-weight: 800;
				}

				.amcard-check {
					margin-top: 20px;
				}

				.payment-option-content,
				.content {
					padding-top: 0;
					padding-left: 0;
					padding-right: 0;
					width: 400px;
					max-width: unset;
					margin: 0;
					position: absolute;
					right: 0;

					.fieldset {
						display: flex;

						> .field:not(.choice) {
							width: 60%;
							margin: 0;

							> .label {
								display: none;
							}

							> .control {
								width: 100%;
							}
						}

						.actions-toolbar {
							width: 40%;
							margin: 0;

							.primary button {
								font-size: 11px;
								border-radius: 0;
								box-shadow: none;
								border: none;
								margin: 0;
								padding: 10px 15px;
								height: 36px;
								width: 100%;
								color: #fff;
								background: #d32700;
								line-height: 1;
								letter-spacing: 2px;
								font-weight: 800;
							}
						}
					}
				}
			}
		}

		.cart-summary-ffl-fyi-container {
			@include respond-to('nav_md') {
				float: right;
				order: 2;
				width: 31.12%;
				position: relative;
			}

			.cart-summary {
				width: 100%;
				padding: 30px 20px;
				background: #d32700;
				color: #fff;
				margin-bottom: 45px;

				@include respond-to('large') {
					padding: 38px 40.5px 37px;
				}

				> .title {
					display: block;
					margin: 0 0 13px;
					padding-bottom: 20px;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 20px;
					border-bottom: 1px solid #e99380;
				}

				.block {
					> .title {
						border: 0;
						margin-bottom: 13px;
						padding-left: 0;
						padding-top: 0;
						padding-bottom: 0;
						font-size: 16px;
						position: relative;
						padding: 0 40px 0 0;

						&::after {
							right: 0;
							top: -16px;
							font-size: 40px;
							transition: all 0.3s ease-out;
						}
					}

					&.active {
						> .title {
							&::after {
								transform: rotate(90deg);
							}
						}
					}

					.fieldset {
						margin: 10px 0 0;

						.field.note {
							font-size: 13px;
						}

						.item-options {
							margin-bottom: 50px;
						}

						.methods .field > .label {
							margin: 0 0 8px;
							display: inline-block;
							color: #fff;
							font-size: 14px;
						}

						input[type="radio"] {
							+ label::before {
								border: 1px solid #fff;
							}

							&:checked {
								+ label::before {
									background: #fff;
								}

								+ label::after {
									background-color: #d32700;
								}
							}
						}

						.field:not(.choice) {
							> .label {
								box-sizing: content-box;
								float: none;
								width: auto;
								text-align: left;
								padding: 0;
								margin: 0 0 8px;
								display: inline-block;
								font-weight: 500;
							}

							select {
								min-height: 45px;
								background-position: right 5px top 10px;
								border: none;
							}

							.input-text {
								min-height: 45px;
							}
						}
					}
				}

				.cart-totals {
					padding-top: 0;
					border-top: 1px solid #e99380;

					.amount,
					.mark {
						padding-left: 0;
						border-bottom: 1px solid #e99380;
					}

					.grand.totals {
						.amount,
						.mark {
							padding-top: 32px;
							border-bottom: 0;
							font-size: 22px;
							font-weight: 700;
						}
					}
				}
			}

			[data-content-type="row"][data-appearance="contained"] {
				float: right;
				.federal-firearms-license-container {
					justify-content: flex-start;
					display: flex;
					flex-direction: column;
					background-position: left top;
					background-size: cover;
					background-repeat: no-repeat;
					background-attachment: scroll;
					border-style: none;
					border-width: 1px;
					border-radius: 0px;
					margin: 0px;
					padding: 30px 20px;
					box-shadow: 0 0 9px 10px rgba(0,0,0,.3);
					background-color: #121212;
					color: #fff;

					@include respond-to('large') {
						padding: 55.5px 40px;
					}

					h1 {
						font-size: 18px;
						margin-bottom: 17px;
						font-weight: 700;
					}

					p {
						font-size: 12px;
						margin-bottom: 10px;
						opacity: .7;
						line-height: 21px;
					}
				}
			}
		}

		.checkout-methods-items {
			margin: 10px 0 0;
			text-align: center;

			.action.primary {
				width: 100%;
				padding: 15px;
				background-color: #fff;
				line-height: 1;
				height: 59px;
				border: 0;

				&:hover {
					background-color: #f2f2f2;
				}

				span {
					font-size: 20px;
					vertical-align: initial;
					color: #000;
					font-weight: 700;
					letter-spacing: 2px;
					line-height: 16px;
					text-align: center;
					text-transform: uppercase;
				}
			}

			.action.multicheckout {
				color: #fff;

				&:hover {
					color: #eeaf00;
				}
			}
		}
	}
}

@include respond-to($screen__l) {
	.federal-firearms-license-container {
		padding: 55.5px 40px !important;
	}
}