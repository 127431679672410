.animate {
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;

	&.animate-slideright {
		&.enter {
			animation-name: slideInRight;
		}
	}

	&.animate-slideleft {
		&.enter {
			animation-name: slideInLeft;
		}
	}

	&.animate-slideup {
		&.enter {
			animation-name: slideInUp;
		}
	}

	&.animate-slidedown {
		&.enter {
			animation-name: slideInDown;
		}
	}
}

@keyframes slideInDown {
	from {
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	from {
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}
