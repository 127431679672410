.block-search {

    .block-content {
        position: absolute;
        top: 101%;
        left: 0;
        width: 100%;
        padding: 26px;
        box-shadow: 0 2px 4px rgba(22,22,22,0.21);
        background-color: color(white-regular);
    }

    .minisearch {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        padding-bottom: 23px;
        border-bottom: 1px solid #e8e8e8;
        @include respond-to(768px, max) {
            padding-bottom: 18px;
            align-items: center;
        }
    }

    .search {
        width: 80%;
        label {
            display: none;
        }
        .control {
            padding-block-end: 0;
            @include respond-to(768px, max) {
                border-top: none;
            }

            input {
                padding: 10px 15px;
                border: 0;
                border-left: 1px solid #d32700;
                background: none;
                color: #979797;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.5px;
                line-height: 18px;

                &::placeholder {
                    color: #979797;
                }
                @include respond-to(768px, max) {
                    position: static;
                }
            }
        }
    }

    .actions {
        display: block;
        width: 20%;
        height: 42px;
        @include respond-to(768px, max) {
            width: 140px;
        }

        .action.search {
            width: auto;
            padding: 8px 12px;
            opacity: 1 !important; // sass-lint:disable-line no-important
            border-radius: 0;
            background: #d32700;

            @include respond-to(768px, max) {
                display: block;
                height: 42px;
                border: none;
                position: relative;
                width: 110px;
            }

            &:hover {
                background: #d32700 !important; // sass-lint:disable-line no-important
            }

            span {
                position: relative;
                color: #fff;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: 2px;
                text-align: center;
                text-transform: uppercase;
            }

            &::before {
                display: none;
            }
        }
    }

    &.active {
        .block-content {
            display: block;
        }
    }
}

.block-account {
    position: relative;

    .block-content {
        position: absolute;
        top: 30px;
        z-index: 99;
        right: -92px;
        display: none;
        width: 410px;
        padding: 44px 46px 30px;
        box-shadow: 0 2px 4px rgba(22,22,22,0.21);
        background-color: color(white-regular);
        @include respond-to(430px, max) {
            width: 314px;
            right: -71%;
        }
    }

    &.logged-in {
        .block-content {
            right: 0;
            display: none;
            width: 145px;
            padding: 15px 24px;
        }
    }

    .logged-in-links {
        display: block;

        > ul {
            margin: 0;
            padding: 0;

            li > a > span {
                color: #333;
                font-size: 14px;
                font-weight: 400;
            }

            li + li {
                margin-block-end: 0;
            }
        }
    }

    .form-login {
        .fieldset .field {
            display: flex;
            flex-direction: column;
            margin-block-end: 8px;

            > .label {
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;
            }

            .control {
                width: 100%;
                margin-block-start: 5px;
            }

            input {
                width: 100%;
                height: 45px;
                border: 1px solid #000;
                background-color: #fff;
                color: #979797;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 19px;
            }
        }

        .actions-toolbar {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-block-start: 20px;
            margin-block-end: 28px;
            padding-block-end: 28px;

            button {
                width: 100%;
                height: auto;
                padding: 14px 10px;
                border-radius: 0;
                background: #d32700;
                font-size: 16px;
                font-weight: 800;
                letter-spacing: 2px;
                line-height: 19px;
                text-align: center;
                text-transform: uppercase;
            }

            .secondary  {
                text-align: right;

                .action {
                    color: #000;
                    font-size: 12px;
                    font-weight: 800;
                    line-height: 16px;
                    margin-block-start: 18px;
                }
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                border: 0.5px solid #e8e8e8;
                content: '';
            }
        }

        .register-button {
            .register {
                display: block;
                width: 100%;
                padding: 14px;
                border: 1px solid #000;
                border-radius: 0;
                background: #fff;
                color: #000;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: 2px;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    &.active {
        .block-content {
            display: block;
        }
    }
}

.minicart-wrapper {

    .block-minicart {
        right: 0 !important; // sass-lint:disable-line no-important
        width: 408px !important; // sass-lint:disable-line no-important
        padding: 31px 29px 5px;
        border: 0;
        box-shadow: 0 2px 4px rgba(22,22,22,0.219023);
        margin-block-start: 0;
        @include respond-to(430px ,max) {
            width: 320px !important;
        }

        &::before,
        &::after,
        .close  {
            display: none;
        }

        .subtitle {
            padding: 0;
            color: #000;
            font-size: 14px;
            font-weight: 800;
            margin-block-end: 31px;
        }

        .items-total {
            font-size: 14px;
        }

        .subtotal {
            .label {
                display: none;
            }

            .price-container {
                font-size: 16px;
                font-weight: 700;
            }
        }

        .block-content {
            > .actions > .primary {
                margin: 0;
            }
        }

        .options.active .toggle {
            margin-bottom: 5px;
        }

        .content > .options {
            font-size: 12px;

            dt {
                margin-bottom: 0;
            }
        }
    }

    .toggle > span {
        font-size: 12px;
        text-decoration: underline;
    }

    .counter.qty {
        position: absolute;
        top: -7px;
        right: -4px;
        min-width: 20px !important; // sass-lint:disable-line no-important
        height: 20px !important; // sass-lint:disable-line no-important
        margin-top: 0 !important; // sass-lint:disable-line no-important
        padding: 0 3px !important; // sass-lint:disable-line no-important
        border-radius: 50% !important; // sass-lint:disable-line no-important
        background: #fff !important; // sass-lint:disable-line no-important
        color: #d32700 !important; // sass-lint:disable-line no-important
        line-height: 17px !important; // sass-lint:disable-line no-important

        .counter-number {
            font-size: 14px;
        }
    }

    .minicart-items-wrapper {
        margin: 0 auto;
        padding: 0;
    }

    .block-content > .actions {
        > .primary .action.primary {
            display: block;
            width: 100%;
            height: 42px;
            margin-bottom: 20px;
            padding: 0 14px;
            border: 0;
            background: #d32700;
            color: #fff;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 1;
            text-transform: uppercase;

            &:hover {
                background: #ba2200;
            }
        }

        .viewcart {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            text-decoration: underline;

            span {
                color: #000;
            }
        }
    }

    .product-item {
        &:first-child {
            padding-block-start: 22px;
        }
    }

    .product {
        .product-item-photo {
            float: inherit;

            @include respond-to(500px) {
                float: left;
                width: 142px;
            }

            .product-image-container {
                width: 100% !important; // sass-lint:disable-line no-important
            }

            .product-image-photo {
                width: 142px !important; // sass-lint:disable-line no-important
                height: 80px !important; // sass-lint:disable-line no-important
                object-fit: cover;
            }
        }

        .product-item-details {

            @include respond-to(500px) {
                float: right;
                width: calc(100% - 172px);
                padding-left: 0;
            }
        }

        .product-item-name {
            margin: 0 0 7px;
            font-size: 14px;
            font-weight: 700;
            word-wrap: break-word;
            hyphens: auto;

            a {
                color: #000;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .minicart-price {

        .price {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .qty {
        margin-top: 5px !important; // sass-lint:disable-line no-important

        label {
            width: auto !important; // sass-lint:disable-line no-important
            font-size: 12px;
        }

        .cart-item-qty {
            height: auto;
            min-height: 0;
            padding: 0;
            opacity: 1;
            border: 0;
            background: none;
            color: #000;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
        }
    }
}
