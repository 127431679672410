// sass-lint:disable-all

//
//  Common
//  _____________________________________________

body.catalog-product-view {

	.page-wrapper {
		position: unset;
		overflow: hidden !important;
        @include respond-to(large) {
            position: relative;
        }
	}

	&.page-product-configurable {
		.page-wrapper {
			overflow: initial;
		}

		.product-add-form > form {
			.product-options-bottom{
				position: sticky;
				top: 100px;
			}
		}
	}

	.page-main {
		max-width: 1440px;
		width: 100%;
		margin-top: 90px;

        .product-section-title {
            text-transform: uppercase;
            font-family: 'Muli';
            font-size: 24px;
            font-weight: 700;
            line-height: 37.65px;
            letter-spacing: 1px;
            color: #000000;
            @include respond-to(large) {
                font-size: 30px;
            }
            @include respond-to(medium) {
                font-size: 28px;
            }
            @include respond-to(small) {
                font-size: 26px;
                text-align: left;
            }
        }
	}
    .pagebuilder-attribute-wrapper {
        [data-content-type='text'] {
            padding: 0 20px;
        }
    }

	.products-grid .product-item {
		width: 100%;
	}

	.a-class {
		font-size: 12px;
		font-weight: 800;
		line-height: 15px;
		text-decoration-line: underline;
		text-transform: uppercase;
		color: #838a94;
	}

	.product-add-form {
		.swatch-select {
			height: 45px;
			background-position: right 10px top 10px;
		}
	}

    .product.media {
        .gallery-placeholder {
            .gallery-placeholder__image {
                display: none;
            }
        }
    }

    .table-wrapper {
        .table-caption {
            font-family: 'Muli';
            font-size: 26px;
            font-weight: 700;
            line-height: 37.65px;
            letter-spacing: 1px;
            text-transform: uppercase;
            max-width: 100%;
            width: 100%;
            padding-bottom: 25px;
            color: #333333;
            margin: 0;
            @include  respond-to(medium) {
                font-size: 28px;
            }
            @include respond-to(small) {
                text-align: left;
            }
            @include respond-to(large) {
                font-size: 30px;
            }
        }
    }

	.product-info-main {
		position: relative;

		.product.attribute.overview {
			margin: 14px 0 8px;

			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 30px;
				color: #000;
			}
		}


		.product-add-form {
			padding-top: 36px;

			.product-options-wrapper {
				.swatch-option {
					border: 1px solid #000;
					height: 30px;
					background: #fff;
				}
			}

			.product-options-bottom {
				.fieldset {
					display: flex;
					flex-direction: row;
					align-items: center;

					@include respond-between(nav_md, xlarge) {
						flex-direction: column;
						align-items: inherit;
					}

					& > .field {
						&.qty {
							margin-right: 20px;
						}

						& > .control .options-list > .field {
							padding: 35px 22px;

						}
					}
				}

				.box-tocart .actions button {
					width: 100%;
				}
			}
		}

		> .product-social-links {
			.action.tocompare {
				display: none;
			}

			.action.towishlist {
				position: absolute;
				bottom: 55px;
				left: 230px;

				span {
					font-size: 0;
				}

				&::before {
					font-size: 22px;
					content: '\e932';
					width: 22px;
					height: 22px;
					color: #333;
				}

				&:hover::before {
					content: '\e931';
					color: #333;
				}

				&.inwishlist::before {
					content: '\e931';
					color: #ff0000;
				}
			}
		}

		.product-reviews-summary {
			display: none;
		}
	}

	.product-item-info {
		width: 100%;

		.page-title-wrapper h1 {
			font-size: 28px;
			font-weight: 300;
			line-height: 35px;
			letter-spacing: 1.75px;
			text-transform: uppercase;
			margin-bottom: 8px;
		}
	}

	.product-info-price {
		.price-container {
			.price-label {
				font-size: 21px;
			}

			.price {
				font-size: 22px;
				font-weight: 700;
			}
		}
	}

	.product.attribute.overview {
		.value {
			h2 {
				font-size: 24px;
				font-weight: 300;
			}
		}

		* {
			margin: 0;
		}
	}

	.product-info-stock-sku {
		padding: 0;

		.stock.available,
		.product.attribute.sku .type {
			display: none;
		}

		.product.attribute.sku .value {
			font-size: 16px;
			display: block;
			text-align: left;
			font-weight: 800;
			line-height: 20px;
			letter-spacing: 1.14286px;
			text-transform: uppercase;
			margin-bottom: 8px;
		}
	}

	.product.media {
		.product-info-price {
			border-bottom: 0;
			margin-bottom: 0;

			.price-container {
				.price {
					font-size: 26px;
					color: #fff;
					font-weight: 800;
					line-height: 48px;
				}

				> span {
					margin-bottom: 0;
				}
			}
		}
        .gallery-placeholder{
            .fotorama-item{
                .fotorama__spinner{
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
	}
    .fotorama-item {
        .fotorama__spinner{
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .product-info-main {
		.page-title-wrapper {
			.page-title {
				font-weight: 300;
				font-size: 38px;
			}
		}
	}

	.product-reviews-summary {
		.reviews-actions {
			margin-top: 0;

			.action.add {
				@extend .a-class;
			}
		}
	}

	.product {
		&.info.detailed {
			margin-bottom: 0;

			.tabs-container > .tab {
				display: flex;
				margin: 0 auto;
				max-width: max-content;
			}

			#tab-label-specs,
			#tab-label-customize,
			#tab-label-details {
				margin-right: 74px;
			}

			.data.item {
				a.data {
					font-size: 22px;
					font-weight: 800;
					line-height: 16px;
					letter-spacing: 2.44444px;
					text-transform: uppercase;
					color: #000;
					padding-bottom: 10px;
				}

				.counter {
					&::before {
						content: '(';
					}

					&::after {
						content: ')';
					}
				}

				&.active a {
					border-bottom: 2px solid #d32700;
				}
			}
		}

		&.data.items {
			> .item.content {
				display: flex;
				justify-content: space-evenly;
				border: 0;
				background: #0a0a0a;
				position: relative;
				padding: 127px 25px 85px;
				margin-bottom: 0;

				&#reviews {
					flex-direction: column;
				}

				&::before,
				&::after {
					content: '';
					position: absolute;
					background: #0a0a0a;
					height: 100%;
					width: 10000px;
					top: 0;
				}

				&::before {
					left: 100%;
				}

				&::after {
					right: 99%;
				}
			}

			.table-wrapper {
				background: rgba(164, 164, 164, 0.1);
				border: 1px solid #1a1a1a;
				max-width: 706px;
				width: 100%;
				padding: 12px 0 42px;

				label.table-title {
					font-size: 32px;
					font-weight: 800;
					line-height: 40px;
					letter-spacing: 2px;
					text-transform: uppercase;
					color: #fff;
					margin-bottom: 45px;
					display: block;
					padding: 36px 0 0 46px;
				}

				.additional-attributes {
					width: 100%;
				}

				tr:nth-child(odd) {
					background: rgba(170, 171, 172, 0.06);
				}

				th.label {
					font-size: 16px;
					padding: 12px 15px 12px 46px;
					font-weight: 800;
					line-height: 20px;
					letter-spacing: 0.5px;
					text-transform: uppercase;
					color: #fff;
					position: relative;

					&::after {
						content: ':';
						position: absolute;
					}
				}

				td.data {
					font-size: 16px;
					padding: 12px 46px 12px 15px;
					line-height: 24px;
					color: rgba(255, 255, 255, 0.7);
				}
			}

			.additional-attributes-wrapper {
				display: inline-block;
				vertical-align: top;

				&.description {
					max-width: 392px;
					width: 100%;
					margin-top: 50px;
					margin-left: 20px;

					[data-content-type='heading'],
					h2.title {
						font-size: 18px;
						font-weight: 800;
						line-height: 16px;
						letter-spacing: 2px;
						color: #fff;
						margin-bottom: 20px;
						text-transform: uppercase;
						text-align: left;
					}

					p {
						font-size: 16px;
						font-weight: 400;
						line-height: 32px;
						color:#fff;
						margin-bottom: 64px;
					}

					[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
						padding: 10px 10px 10px 0 !important;
					}

					[data-content-type='button-item'] [data-element='link'] {
						line-height: 1;

						span {
							line-height: 1;
						}
					}

					[data-content-type='button-item'] [data-element='link'] {
						position: relative;
						background:#1a1a1a;
						border: 0;
						border-radius: unset;

						&::before {
							font-size: 24px;
							content: '\e91f';
							position: absolute;
							top: 12px;
						}

						span {
							padding-left: 30px;
							letter-spacing: 0.971429px;
							text-transform: uppercase;
						}
					}

					.extreme-precision {
						margin-top: 50px;
						padding: 10px 10px 10px 0;

						p {
							margin-bottom: 20px;
						}

						div[data-content-type='text'] {
							position: relative;
							padding-left: 38px !important;
							margin-bottom: 10px !important;

							&::before {
								font-size: 24px;
								position: absolute;
								top: 0;
								left: 0;
								font-weight: 800;
								color: #fff;
								content: '';
								width: 24px;
								height: 24px;
								background-repeat: no-repeat;
								background-size: 24px 24px;
							}

							&.ammo::before {
								background-image: url(../images/bullet.svg);
							}

							&.warranty::before {
								background-image: url(../images/warranty.svg);
							}

							&.guarantee::before {
								background-image: url(../images/smile.svg);
							}

							p {
								font-size: 14px;
								font-weight: 400;
								line-height: 18px;
								color: rgba(255, 255, 255, 0.7);
							}
						}
					}
				}
			}
		}
	}

	.block.review-add {
		.review-control-vote {
			margin-top: 15px;
			margin-left: -13px;
			display: block;
            position: relative;

			&::before {
                position: relative;
				font-size: 58px;
                position: relative;
			}
		}

		.fieldset .review-legend.legend {
			span, strong {
				color: #fff;
			}
		}

		.fieldset > .field > .label {
			color: #fff;
			width: 100%;
			text-align: left;
			float: inherit;
			padding:  0 0 10px 0;
		}

		.fieldset {
			margin: 0;
		}

		.fieldset > .field {
			margin: 0 0 20px 0;
		}

		input[type="text"] {
			height: 45px;
		}

		.fieldset > .field > .control {
			width: 100%;
			float: inherit;
			height: 50px;
		}

		.actions-toolbar {
			margin-left: 0;
		}

		> .block-content {
			display: block;
		}

		input[type="radio"] + label {
			position: absolute;
			&::before {
				position: absolute;
				top: -28px;
				left: 0;
				width: 0;
				height: 0;
				border: none;
				background-color: transparent;
				font-size: 58px;
			}

			&::after {
				content: none;
			}
		}
	}

	.review-field-rating .label span {
		color: #fff;
	}

	.review-form {
		.action.submit.primary {
			margin: 0;
			padding: 7px 15px;
			font-size: 18px;
			background: #d32700;
			color: #fff;
			border-color:#d32700;
			line-height: 26px;
			font-weight: 700;
			height: 48px;
		}

		.review-field-rating > label {
			display: none;
		}
	}

	.block-title.review {
		margin: 0 0 30px;

		strong {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #fff;
		}
	}

	#product-review-container,
	.block.review-add {
		max-width: 1190px;
		width: 100%;
		margin: 0 auto;
	}

	.review-toolbar {
		    border: none;
        .disabled-pager-action{
            opacity: 0.3;
            pointer-events: none;
        }
        .action {
            border: none;
        }
        .pages{
            border: none;
        }
    }

    .no-review {
        text-align: center;
        padding: 20px 0;
        p {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

	.review-items {
		margin: 0;

		.review-item {
			border-bottom: 0;
			border-top:  1px solid rgba(151, 151, 151, 0.5);
			padding: 48px;

			.rating-label {
				display: none;
			}

			.rating-summary .rating-result {
				width: 200px;
				&:before {
					display: none;
				}
			}

			.rating-summary .rating-result > span::before {
				color: #fff;
				font-size: 40px;
				height: 30px;
				letter-spacing: 0;
				line-height: 30px;
			}

			.review-content,
			.review-details {
				margin-left: 248px;
			}

			.review-content {
				font-size: 20px;
				font-weight: 800;
				line-height: 25px;
				color: #fff;
			}

			.review-details {
				.review-title,
				.review-date {
					font-weight: 400;
					line-height: 27px;
				}

				.review-title {
					font-size: 18px;
					color: #979797;
					margin: 0 0 16px;
				}

				.review-date,
				.review-author {
					display: inline;
					font-size: 14px;
					color: rgba(255, 255, 255, 0.7);

					span, strong {
						color: rgba(255, 255, 255, 0.7);
					}
				}
			}
		}
	}

	.write-review-action {
		a {
			font-size: 18px;
			font-weight: 800;
			line-height: 16px;
			text-align: center;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #000;
			padding: 14px;
			background: #fff;
			border-radius: 0;
			display: block;
			max-width: max-content;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.block.related {
		margin: 102px auto 112px;
		max-width: 1440px;
		width: 100%;
		position: relative;
		padding: 0 180px;

		.product-items {
			position: unset;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.product-item {
			width: 100%;

			@include respond-to($screen__l) {
				margin-bottom: 0;
				width: 48%;
			}
		}

		.product-item-details .product-item-actions,
		.short-description-container {
			display: none;
		}

		.block-title {
			text-align: center;
			margin-bottom: 30px;

			@include respond-to($screen__l) {
				margin-bottom: 60px;
			}
		}

		.title strong {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
			text-align: center;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #000;
		}

		.block-actions {
			display: none;
		}

		.product-item-photo {
			display: block;
			text-align: center;
			margin-bottom: 58px;
		}

		.products-grid .product-item .product-item-details {
			border-top: 1px solid#1a1a1a;
			margin: 0;
			padding-top: 24px;

			span.sku {
				font-size: 14px;
				font-weight: 800;
				line-height: 16px;
				text-align: center;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: #000;
			}

			.product-item-name {
				font-size: 18px;
				font-weight: 300;
				line-height: 23px;
				color: #000;
				margin: 4px 0 0;
			}

			.price-box .price {
				font-size: 14px;
				font-weight: 800;
				line-height: 18px;
				color: #000;
			}

			.field.choice {
				display: none;
			}
		}
	}

	.full-details-actions .action {
		@extend .a-class;
	}

	.box-tocart {
		width: 100%;

		@include respond-to(xlarge) {
			margin-bottom: 36px;
		}

		.fieldset {
			width: 100%;
		}

		.fieldset > .field.qty {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			> .label {
				font-size: 18px;
				font-weight: 400;
				line-height: 23px;
				text-transform: uppercase;
				margin-right: 18px;
			}
		}

		.input-text.qty {
			height: 45px;
			width: 40px;
			background-color: #fff;
		}

		.actions {
			padding-top: 0;

			@include respond-between(nav_md, xlarge) {
				padding-top: 20px;
			}
		}

		button.action.primary {
			max-width: unset;
			width: auto;
			padding: 0 32px;
			line-height: 0;
			margin-bottom: 0;
			height: 48px;

			@include respond-to(nav_md) {
				padding: 20px 32px;
			}

			span {
				font-size: 18px;
			}
		}

		.reset-btn {
			padding: 13px 20px;

			.reset-summary {
				color: #000;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.fotorama,
	.fotorama__nav {
		overflow: unset;
	}

	.fotorama__fullscreen-icon:focus:after {
		box-shadow: unset;
	}

	.product-info-main {
		.product-options-wrapper {
			.swatch-attribute-label {
				font-size: 15px;
			}

			.swatch-attribute {
				margin-bottom: 10px;

				&.smr_port_length {
					width: auto;
				}

				&.smr_thread_pitch {
					width: auto;
				}
			}

			.swatch-select {
				background-color: transparent;
			}
		}
	}

	.content-reviews {
		background-color: #fff !important;

		@include respond-to(medium, 'max') {
			padding: 0;

			.review-items {
				.review-item {
					width: 100%;
				}
			}
		}

		&::before,
		&::after {
			display: none !important;
		}
	}

    .block-review {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;

        @include respond-to(small) {
            justify-content: space-between;
        }

        .block-title.review {
            margin: 0;
            width: 100%;
            text-align: center;

            @include respond-to(small) {
                width: auto;
                text-align: left;
            }

            strong {
                color: #333;
                font-size: 26px;
                font-weight: 700;
                letter-spacing: 1px;
                text-transform: uppercase;
                @include respond-to(large) {
                    font-size: 30px;
                }
                @include respond-to(medium) {
                    font-size: 28px;
                }
            }
        }

        .write-review-action {
            a {
                display: inline-block;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
                text-transform: uppercase;
                padding: 12px 32px;
                background: #C2391D;;
                transition: background-color 0.3s ease-in-out;
                font-family: Helvetica Neue;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 1.100000023841858px;



                &:hover {
                    background: #006ACC;
                    transition: background-color 0.3s ease-in-out;
                }
            }
        }
    }

    .block.review-list {
        margin-bottom: 80px;
    }

    .no-review {
        text-align: center;
        padding: 20px 0;
        p {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    .review-items {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 20px;
        @include respond-to(medium) {
            flex-direction: row;
        }

        .review-item {
            width: 100%;
            border: 1px solid #000;
            display: flex;
            padding: 20px 22px;
            align-items: flex-start;
            gap: 10px;
            @include respond-to(medium) {
                width: calc(33% - 11px);
            }

            &:last-child {
                border: 1px solid #000;
            }

            .review-content {
                color: #333;
                margin: 0;

                .review-author {
                    display: block;
                    color: #333;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 0.667px;
                }

                .review-ratings {
                    float: none;
                    display: block;
                    margin-bottom: 16px;

                    .rating-summary .rating-result {
                        width: 136px;

                        > span::before {
                            color: #333;
                            font-size: 32px;
                            letter-spacing: -6px;
                        }
                    }
                }

                .review-title {
                    color: #333;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 0.6px;
                    margin-bottom: 16px;
                }

                .review-details {
                    margin: 0 0 10px;
                    color: #333;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 1px;
					max-height: 150px;
					overflow: auto;
                }
            }
        }
    }

    .modal-popup .modal-inner-wrap {
        width: 100%;
        max-width: 600px;

        .modal-header {
            padding: 0 0 50px;
        }

        .block.review-add .fieldset {
            .review-legend.legend {
                display: flex;
                align-items: center;
                gap: 10px;

                strong,
                span {
                    color: #333;
                }
            }

            > .field {
                > .label {
                    color: #333;
                }

                > .control {
                    height: auto;

                    textarea {
                        height: 100px;
                    }
                }
            }
        }
       .block.review-add input[type="radio"]+label::before {
           position: absolute;
           top: -28px;
           left: 0;
           width: 0;
           height: 0;
           border: none;
           background-color: transparent;
           font-size: 3.625rem;
       }
    }
}


body.simple-product-index {
	.fotorama__nav__frame--dot {
		display: none;
	}

	.column.main {
		padding-bottom: 0;

		.product.data.items {
			margin-bottom: 0;
		}
	}
}


body.page-product-configurable {
	.swatch-opt {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		.swatch-attribute:not(.smr_port_length, .smr_thread_pitch) {
			width: 100%;
		}
	}

	.reset-btn {
		padding: 20px;
		font-style: italic;
		margin-left: auto;
		cursor: pointer;
	}

    .modals-wrapper {
        .modal-popup.modal-slide  {
            @include respond-to(medium, max) {
                left: 10%;
                right: 10%;
            }
        }
    }
}

body.simple-product-index {
	.product-info-main {
		.box-tocart .actions,
		.product-options-bottom .box-tocart .actions {
			display: block;
			text-align: left;
			margin-top: 30px;
		}
	}
}

body.page-layout-product-rifle-model {
    .page-wrapper {
        overflow: unset !important;
    }

	.reset-btn {
		display: none;
	}

	.page-main {
		margin: 0 auto;
		padding: 0;
		max-width: 100%;

		.breadcrumbs {
			margin: 20px auto 0;
            max-width: 1340px;
			padding: 0 20px 20px;
            @include respond-to(medium) {
                padding: 20px;
            }
            @include respond-to(1120px) {
                padding:  0 20px;
            }

			.item {
				a {
					color: #C2391D;
					font-size: 14px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0.1px;
				}

				strong {
					font-size: 14px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0.1px;
				}
			}
		}
	}

	.product-detail {
		padding: 20px;
		margin: 0 auto 40px;
		width: 100%;
		max-width: 1220px;

		@include respond-to(768px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 auto 80px;
            padding: 0 20px;
		}
        @include respond-to(large,max) {
            align-items: center;
        }

		.product-info-main {
            margin-top: 45px;
			@include respond-to(large) {
				width: 27%;
			}

			@include respond-to(768px) {
				width: 40%;
				padding: 0;
			}

			@include respond-to(767px, 'max') {
				padding: 0;
				margin: 0;
			}

			.sub-title {
				color: #7D7D7D;
				font-size: 16px;
				font-weight: 800;
				line-height: 28px;
				letter-spacing: 1px;
				text-transform: uppercase;
			}

			.page-title-wrapper {
				.page-title {
					margin: 10px 0;
					color: #2A2A2A;
					font-size: 40px;
					font-weight: 400;
					line-height: 42px;
					letter-spacing: -1px;

					@include respond-to(large) {
						font-size: 58px;
						line-height: 60px;
					}

					@include respond-to(medium) {
						font-size: 50px;
						line-height: 52px;
					}

					@include respond-to(small) {
						font-size: 44px;
						line-height: 50px;
					}
				}
			}

			.benefits-rifles {
				h3 {
					color: #2A2A2A;
					font-size: 18px;
					font-weight: 700;
					line-height: 28px;
					letter-spacing: 0.1px;
				}

				ul {
					margin: 0;
					padding: 0;

					li {
						padding: 0 0 0 47px;
						margin: 22px 0 0;
						color: #2A2A2A;
						font-size: 14px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: 0.1px;
						background-image: url(../images/benefits-rifle-icon.svg);
						background-position: left top;
						background-repeat: no-repeat;
						background-size: 37px 37px;
						list-style-type: none;
						min-height: 40px;
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}

	.column.main {
		padding: 0;
	}

	.fotorama__arr {
		display: none !important;
	}

	.fotorama__nav {
		overflow: hidden;
		text-align: center;

		&::before,
		&::after {
			display: none;
		}
	}

	.fotorama__thumb {
		background-color: #fff;
		border: 2px solid rgba(74, 72, 72, 1);
		width: calc(100% - 20px);
		height: calc(100% - 20px);
	}

	.fotorama__nav--thumbs {
		.fotorama__nav__frame {
            @include respond-to(450px, max) {
                width: 80px !important;
                height: 80px;
            }
			padding: 0;

			&.fotorama__active {
				&::after {
					background: #C2391D;
					width: calc(100% - 20px);
					height: 5px;
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					content: '';
				}
			}
		}
	}

	.fotorama__thumb-border {
		display: none;
	}

	.fotorama__dot {
		border-color: #fff;
		height: 13px;
		width: 13px;
		top: 25px;
	}

	.fotorama__active .fotorama__dot {
		background-color: #fff;
	}

	.swatch-option-tooltip {
		display: none !important;
	}

	.product.media {
		width: 100%;
		position: relative;
		margin-bottom: 3px;

		@include respond-to(large) {
			width: 67%;
		}

		@include respond-to(768px) {
			width: 55%;
		}

		.product-info-main {
			position: absolute;
			bottom: 100px;
			right: 0;
			z-index: 2;

			.price-container,
			.price-payment-overview {
				padding-right: 24px;
			}

			span {
				font-size: 18px;
				color: #fff;
				font-weight: 800;
				line-height: 33px;
				letter-spacing: 1px;
				text-transform: uppercase;
				text-align: right;
			}
		}

		.page-title-wrapper {
			text-align: center;
			position: absolute;
			top: 60px;
			left: 0;
			right: 0;

			.page-title span {
				font-size: 32px;
				font-weight: 800;
				line-height: 40px;
				text-align: center;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #fff;
			}
		}

		.price-payment-overview {
			margin-bottom: 8px;
			display: block;
			text-align: right;
			padding-right: 20px;
			position: absolute;
			bottom: 64px;
			right: 0;

			span {
				font-size: 14px;
				font-weight: 600;
				line-height: 18px;
				text-align: right;
				letter-spacing: 3.5px;
				text-transform: uppercase;
				color: #979797;
			}
		}

		button.action.primary.customize-btn {
			width: 255px;
			padding: 16px;
			height: 65px;
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			margin: 0 0 0 auto;

			a {
				font-size: 26px;
				padding: 0;
				color: #fff;
				font-weight: 800;
				letter-spacing: 1px;
				text-transform: uppercase;
				text-align: right;
				line-height: 24px;
			}
		}
	}

	.product-info-price > * {
		display: block;
	}

	.product-image-info-wrapper {
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
		width: 100%;
		padding: 64px 70px;
		position: relative;
		width: 100% !important;
		max-width: 1440px;

		.product-image-info {
			background: #161616;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.694411);
			position: relative;
			padding: 60px 26px 0;
			height: 426px;
		}

		.fotorama__wrap--css3 .fotorama__stage {
			height: 252px !important;
		}
	}

	.product.data.items {
		margin-bottom: 0;

		.nav-bar {
			width: 100%;
			background-color: #333;
            position: sticky;
            top: 0;
            z-index: 9999;

			.title-nav {
				display: flex;
				flex-wrap: wrap;
				gap: 0 10px;
                justify-content: center;
                align-items: center;

				@include respond-to(large) {
					gap: 0 40px;
				}

				@include respond-to(medium) {
					gap: 0 30px;

				}

				@include respond-to(small) {
					gap: 0 20px;
				}
			}

			.data.item {
				margin: 0 !important;

					& a.active {
						border: none;

						&::after {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							height: 5px;
							content: '';
							background-color: #C2391D;
						}
					}

				a.data,
				.switch {
					position: relative;
					display: inline-block;
					color: #FFF;
					text-align: center;
					font-size: 13px;
					font-weight: 800;
					line-height: 22px;
					letter-spacing: 1px;
					text-transform: uppercase;
					padding: 10px;

					@include respond-to(large) {
						font-size: 16px;
						padding: 16px;
						line-height: 28px;
					}

					@include respond-to(medium) {
						font-size: 15px;
						padding: 14px;
						line-height: 26px;
					}

					@include respond-to(small) {
						font-size: 14px;
						padding: 12px;
						line-height: 24px;
					}
				}
			}

			.getpriceing-title {
				margin: 0 !important;

				.switch {
					display: inline-block;
					color: #FFF;
					text-align: center;
					font-size: 13px;
					font-weight: 800;
					line-height: 22px;
					letter-spacing: 1px;
					text-transform: uppercase;
					padding: 16px 20px;
					background: #C2391D;

					@include respond-to(large) {
						font-size: 16px;
						padding: 16px 50px;
						line-height: 28px;
					}

					@include respond-to(medium) {
						font-size: 15px;
						padding: 14px 40px;
						line-height: 26px;
					}

					@include respond-to(small) {
						font-size: 14px;
						padding: 12px 30px;
						line-height: 24px;
					}

					&:hover {
						background: #d32700;
					}
				}
			}
		}

		.content-details,
		.content-customize,
		.content-reviews {
			width: 100%;
			max-width: 1220px;
			padding: 80px 20px 0;
			margin: 0 auto;
		}

        .content-details,{
            padding: 50px 20px 0;
        }
        .content-reviews {
            padding: 30px 10px 0;
        }
        .content-customize,{
            padding: 20px 32px 0;
            margin-bottom: 80px;
            @include respond-to(1200px, max) {
                padding: 20px 20px 0;
            }
        }
        .content-productdetail {
            padding: 30px 0px 0;
        }

		.content-details {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.additional-attributes-wrapper {
				background: none;
				border: none;
				color: #333;
				padding: 0;

				&.table-wrapper {
					width: 70%;
					max-width: 800px;
                    margin-bottom: unset;

					@include respond-to(768px, 'max') {
						width: 100%;
						order: 0;
                        margin-bottom: 1.375rem;
					}
				}

				&.extreme-precision {
					width: 27%;
					padding-top: 90px;

					@include respond-to(768px, 'max') {
						width: 100%;
						padding: 0;
					}

					h2 {
						margin-top: 0;
						color: #000;
						font-size: 21px;
						font-weight: 700;
						line-height: 28px;
						letter-spacing: 1px;
					}

					.ammo,
					.warranty,
					.guarantee {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
						letter-spacing: 0.1px;
						position: relative;
						padding-left: 2.875rem !important;
						padding-top: 5px !important;
						margin-bottom: 25px !important;

						&::before {
							content: '';
							background-image: url('../images/Checklist.svg');
							background-position: top center;
							background-repeat: no-repeat;
							background-size: 35px 35px;
							position: absolute;
							left: 0;
							top: 0;
							width: 35px;
							height: 35px;
						}
					}
				}

				&.table-wrapper {
					width: 70%;
					max-width: 800px;

					@include respond-to(768px, 'max') {
						width: 100%;

						table {
							padding: 0;
						}

						table:not(.totals):not(.cart):not(.table-comparison) {
							> tbody > tr {
								padding: 0;

								td[data-th]::before {
									font-size: 14px;
									line-height: 18px;
									letter-spacing: 0.7px;
									color: #000;
									text-transform: uppercase;
									font-weight: 800;
									margin-bottom: 9px;
								}
							}

							&.additional-attributes {
								padding: 0;

								tbody td:last-child {
									font-size: 14px;
									line-height: 20px;
									color: #000;
								}
							}
						}
					}
				}

				label.table-title {
					color: #000;
					font-size: 30px;
					font-weight: 700;
					line-height: normal;
					letter-spacing: 1px;
					text-transform: uppercase;
					padding: 0;
				}

				.table {
					border: 1px solid #D9D9D9;

					th.label {
						color: #000;
						font-size: 16px;
						font-weight: 800;
						line-height: 24px;
						letter-spacing: 0.1px;
						text-transform: none;
						padding: 16px;
					}

					td.data {
						color: #000;
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
						letter-spacing: 0.1px;
						padding: 16px;

						p {
							margin: 0;
						}
					}

					tr {
						background: #F4F4F4;
						border: 1px solid #D9D9D9;

						&:first-child {
							border-top: none;
						}

						&:last-child {
							border-bottom: none;
						}

						&:nth-child(2n+1) {
							background: #fff;
						}
					}
				}
			}
		}

		.content-customize {
			.customize-option,
			.product-add-form {
				width: 70%;
				max-width: 800px;

				@include respond-to(768px, 'max') {
					width: 100%;
				}
			}

			.customize-option {
				h2 {
					color: #000;
					font-size: 30px;
					font-weight: 700;
					line-height: normal;
					letter-spacing: 1px;
					text-transform: uppercase;
					padding: 0;
				}

				p {
					color: #000;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: 0.1px;
                    max-width: 790px;
				}
			}
		}
	}

	.columns-with-line {
		max-width: 1300px;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px;

		.pagebuilder-column-line {
			flex-wrap: wrap;

			@include respond-to(small) {
				flex-wrap: nowrap;
			}
		}

		.pagebuilder-column {
			padding: 20px 0;

			@include respond-to(medium) {
				padding: 40px;
			}

			@include respond-to(small) {
				padding: 20px;
			}

			+ .pagebuilder-column {
				border-top: 1px solid rgba(153, 153, 153, 1);

				@include respond-to(small) {
					border-left: 1px solid rgba(153, 153, 153, 1);
					border-top: 0;
				}
			}
		}
	}

	.video-popup {
		.pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
			@include respond-to(small, 'max') {
				min-height: 350px !important;
			}

			@include respond-to(medium, 'max') {
				min-height: 400px !important;
			}

			@include respond-to(large, 'max') {
				min-height: 500px !important;
			}
		}
	}

	.product-add-form {
		max-width: 1200px;
		width: 100%;
	}

	.product-add-form > form {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		align-content: space-between;
		justify-content: space-between;

        .product-options-wrapper {
            @include respond-to(large) {
                margin-top: 20px;
            }
        }

        .product-options-bottom {
            @include respond-to(large) {
                margin-top: 88px;
            }
        }

		.product-options-wrapper,
		.product-options-bottom {
			width: 100%;
			position: relative;
			margin-top: 20px;
			display: block;

			@include respond-to(medium) {
				margin-top: 60px;
			}

			@include respond-to(small) {
				margin-top: 40px;
			}

			&::before {
				position: absolute;
				left: 0;
				text-transform: uppercase;
				color: #fff;
				font-weight: 800;
			}
		}

		.product-options-wrapper {
			max-width: 100%;
			margin-top: 20px;
			margin-right: 0;
			order: 1;

			@include respond-to(medium) {
				margin-top: 60px;
			}

			@include respond-to(small) {
				margin-top: 40px;
			}

			[type="radio"] {

				&:checked,
				&:not(:checked) {

					+ label {
						&::before {
							background: transparent;
							width: 30px;
							height: 30px;
						}

						&::after {
							position: absolute;
							top: 10px;
							left: 7px;
							display: block !important;
							width: 16px;
							height: 16px;
							border-radius: 100%;
							content: '';
							transition: all .2s ease;
						}
					}
				}

				&:checked + label {
					&::after {
						background: #d32700;
					}
				}
			}

			[type="checkbox"] {

				& + label {
					&::before {
						position: absolute;
						left: 0;
						width: 30px;
						height: 30px;
						border: 1px solid #fff;
						content: '';
					}
				}

				&:checked + label .checker:after {
					transform: rotate(45deg);
					content: '';
					position: absolute;
					left: 8px;
					top: 12px;
					background: #fff;
					width: 6px;
					height: 6px;
					box-shadow: 2px 0 0 #ffffff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
				}
			}

			> .fieldset > .field {
                background: #F6F6F6;
				> label {
					display: flex;
					align-items: center;
					background:#F6F6F6;
					margin-bottom: 0;

					span.value {
						display: none;
					}

					span:not(.value) {
						display: block;
						padding: 10px 16px;
						position: relative;
						color: #000;
						font-size: 18px;
						font-weight: 700;
						line-height: 28px;
						letter-spacing: 1px;

						@include respond-to(small) {
							padding: 16px 30px;
							font-size: 21px;
						}
					}
				}

				span, label {
					color: #fff;
				}
                .option-note{
                    color: #0f0f0f;
                    padding: 0 18px;
                    @include respond-to(small) {
                        padding: 0 30px;
                    }
                }

				&.required .label:after {
					display: none;
				}

				> .control {
					padding: 16px;
					width: 100%;
					margin: 0 0 2px;
					border-top: 1px solid #D9D9D9;
					background-color: #F6F6F6;

					@include respond-to(small) {
						padding: 30px;
					}

					.value {
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: 0.1px;
						text-align: left;
						margin-bottom: 16px;
					}

					.options-list {
						> .field {
							position: relative;
							margin-bottom: 20px;
							display: flex;
							flex-wrap: wrap;
							width: 100%;
							padding: 40px 17px;
							align-items: center;
							gap: 15px;
							background: #fff;

							@include respond-to(small) {
								padding: 20px 17px;
							}

							&:last-child {
								margin: 0;
							}

							> label {
								padding: 0;
								max-width: 100%;
								width: 100%;
								color: #000;
								font-size: 16px;
								font-weight: 400;
								line-height: 24px;
								letter-spacing: 0.1px;
								position: static;

								@include respond-to(medium) {
									max-width: 350px;
								}

								&::before {
									display: none;
								}

								&::after {
									width: 100%;
									height: 100%;
									position: absolute;
									left: 0;
									top: 0;
									border-radius: 0;
									background: transparent;
								}
							}

							[type="radio"]:checked + label::after {
								border: 3px solid #C2391D;
							}

							> label span {
								display: block;
								color: #000;
								font-size: 16px;
								font-weight: 400;
								line-height: 24px;
								letter-spacing: 0.1px;

								&.price-notice {
									display: flex;
									color: #fff;
									font-weight: 700;

									span {
										color: #fff;
										font-weight: 700;
									}
								}

								&.brand,
								&.learn-more-desc {
									text-transform: uppercase;
								}

								&.brand {
									font-size: 14px;
									font-weight: 400;
									line-height: 18px;
								}

								&.learn-more-desc {
									font-size: 12px;
									font-weight: 800;
									line-height: 15px;
									text-decoration-line: underline;
									color: #333;
									margin-top: 10px;
									position: relative;
									max-width: max-content;
									padding-right: 10px;

									&::after {
										content: '\e906';
										position: absolute;
										color: #333;
										right: -10px;
										top: 3px;
									}

									&.active:after {
										content: '\e909';
									}
								}
							}
						}

						.product-image-container {
							max-width: 100%;
							width: 100% !important;
							margin-left: auto;

							@include respond-to(eleven) {
								max-width: 270px;
							}

							@include respond-to(large) {
								max-width: 210px;
							}
						}

						.option-description {
							width: 100%;
							display: none;

							&.active {
								display: block;
							}

							p {
								font-size: 14px;
								font-weight: 400;
								line-height: 21px;
								color: #333;
							}

							[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
								padding: 10px 10px 0 55px !important;
							}
						}

						.price-notice {
							position: absolute;
							top: 0;
							right: 0;
							padding: 4px 15px;
							background: #C2391D;
							z-index: 1;

							span {
								font-size: 14px;
								font-weight: 700;
								line-height: 17px;
							}
						}
					}
				}

				&.cerakote-option {
					> .control .options-list {
						position: relative;
						margin-bottom: 0;
						display: flex;
						flex-wrap: wrap;
						width: 100%;
						padding: 0;
						align-items: center;
						gap: 15px;

							> .field {
								outline: unset;
								padding: 0;
								height: 64px;
								width: 64px;
								position: relative;
								z-index: 2;
								margin: 0;
								background: none;

								[type="radio"] {
									&:not(:checked) + label::before {
										background: transparent;
										width: 64px;
										height: 64px;
										border: 3px solid transparent;
									}

									&:checked {
										z-index: 6;

										+ label {
											&::before {
												display: block;
												top: 0;
												width: 64px;
												height: 64px;
												background: unset !important;
												border: 3px solid #C2391D;
											}

											&::after {
												display: none !important;
											}
										}
									}
								}

							> label {
								position: absolute;
								height: 64px;
								width: 64px;
								z-index: 5;

								&:hover {
									span.title {
										font-size: 14px;
										position: absolute;
										width: 120px;
										bottom: 64px;
										left: 50%;
										transform: translateX(-50%);
										background-color: black;
										border-radius: 5px;
										padding: 10px;
										height: auto;
										text-align: center;
										color: #fff;
									}
								}

								span.title {
									font-size: 0;
									width: 64px;
									height: 64px;
									left: 0;
									position: absolute;
									z-index: 10;
									line-height: 1.2;
								}
							}

							.product-image {
								&-container {
									position: absolute;
									z-index: 1;
								}

								&-wrapper {
									overflow: unset;
								}

								&-photo {
									border-radius: 50%;
									max-height: unset !important;
								}
							}
						}

						.price-notice {
							display: none;
							background: unset;
							top: -20px;
							right: -5px;
						}
					}
				}

				&.cerakote-option,
				&.custom-option-value {
					> label {
						display: flex;

						span:after {
							top: 24px;
						}

						span {
							white-space: nowrap;
						}

						span.value {
							font-size: 14px;
							color: #646464;
							position: relative;
							padding: 24px 46px 24px 30px;
							line-height: 28px;
							text-transform: unset;
							font-weight: 400;
							white-space: unset;

							&::after {
								display: none;
							}
						}
					}
				}
			}
		}

		.product-options-bottom {
			max-width: 388px;
			background:#131313;
			padding: 50px;
			position: relative;
			order: 2;

			&::before {
				font-size: 22px;
				content: 'Your Rifle';
				line-height: 28px;
				letter-spacing: 1.1px;
				top: -82px;
			}

			.price-box {
				padding-right: 60px;

				.price-container {
					> span {
						font-size: 14px;
						font-weight: 600;
						line-height: 18px;
						letter-spacing: 3.5px;
						text-transform: uppercase;
						color: #979797;
						margin-bottom: 0;
					}

					.price {
						font-size: 26px;
						font-weight: 800;
						line-height: 33px;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: #fff;
					}
				}
			}

			.box-tocart {
				.fieldset {
					&:first-child {
						margin-bottom: 38px;
					}

					> .field.qty {
						position: absolute;
						top: 50px;
						right: 50px;

						label span {
							font-size: 12px;
							font-weight: 400;
							line-height: 15px;
							text-transform: uppercase;
							color: #fff;
						}
					}
				}

				.input-text.qty {
					font-size: 20px;
					border: 0.5px solid rgba(255, 255, 255, 0.5);
					box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.0421766);
					background: none;
					font-weight: 800;
					line-height: 25px;
					color: #fff;
					width: 45px;
				}

				.actions {
					margin-top: 37px;

					button.action.primary {
						padding: 18px;
						height: unset;
						width: 100%;
						margin-bottom: 0;

						span {
							font-size: 25px;
							letter-spacing: 1.25px;
							line-height: 14px;
							font-weight: 800;
						}
					}
				}
			}
		}

		.summary-wrapper {
			position: relative;
			padding: 52px 0;

			&::before {
				content: '';
				position: absolute;
				width: 388px;
				height: 2px;
				background: #000;
				top: 0;
				left: -50px;
			}

			> h2 {
				font-size: 14px;
				font-weight: 800;
				line-height: 18px;
				letter-spacing: 1.01111px;
				text-transform: uppercase;
				color: #fff;
				margin: 0;
				padding: 0 0 32px;
			}

			#configurator-summary {
				> li {
					margin: 0 0 14px;

					&:last-child {
						margin: 0;
					}
				}

				.label {
					font-size: 14px;
					font-weight: 800;
					line-height: 18px;
					letter-spacing: 0.7px;
					text-transform: uppercase;
					color: #fff;
				}

				.value {
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					color: rgba(255, 255, 255, 0.7);
				}
			}
		}

		.swatch-opt {
			overflow: hidden;
			margin-top: 0;

			> div {
				display: flex;
				flex-wrap: wrap;
				margin: 0 0 30px;
				background-color: #F6F6F6;
			}

			> div.smr_caliber {
				.swatch-attribute-options {
					display: flex;
					flex-direction: column;
					padding: 16px;

					@include respond-to(small) {
						padding: 30px;
					}
				}

				.swatch-select {
					height: 45px;
					background-position: right 10px top 10px;
					background-color: #fff;
					border: 1px solid #000;
					background-image: url('../images/red-arrow-down.svg');
				}

				.swatch-option.text {
					font-size: 18px;
					background: #1a1a1a;
					font-weight: 800;
					line-height: 24px;
					color: #fff;
					max-width: unset;
					width: 100%;
					height: auto;
					text-align: left;
					border: unset;
					border-radius: unset;
					position: relative;
					padding: 44px 44px 44px 88px;
					outline: 1px solid rgba(255, 255, 255, 0.1);
					margin-bottom: 4px;

					&:last-child {
						margin-bottom: 0;
					}

					&:hover {
						background: unset !important;
					}

					&::before {
						content: '';
						position: absolute;
						display: block !important;
						left: 44px;
						top: 50%;
						transform: translateY(-50%);
						width: 30px;
						height: 30px;
						border: 1px solid #ccc;
						border-radius: 100%;
					}

					&.selected {
						background: unset !important;

						&::after {
							content: '';
							display: block !important;
							background:#1a1a1a;
							position: absolute;
							top: 48px;
							border-radius: 100%;
							-webkit-transition: all 0.2s ease;
							transition: all 0.2s ease;
							width: 16px;
							height: 16px;
							left: 51px;
						}
					}
				}
			}

			.swatch-attribute-label {
				display: block;
				padding: 10px 16px;
				position: relative;
				color: #000;
				font-size: 18px;
				font-weight: 700;
				line-height: 28px;
				letter-spacing: 1px;

				@include respond-to(small) {
					padding: 16px 30px;
					font-size: 21px;
				}
			}

			.swatch-attribute-selected-option {
				position: relative;
				padding: 16px 30px;
				line-height: 28px;
				display: none;
			}

			.swatch-attribute-options {
				width: 100%;
				margin: 0 0 2px;
				border-top: 1px solid #D9D9D9;
				padding: 16px;

				@include respond-to(small) {
					padding: 30px;
				}
			}
		}

		.swatch-option {
			&.selected,
			&.image:not(.disabled):hover {
				border-radius: 50%;
				outline: unset;
				border: 2px solid #fff;
			}

			&.text {
				font-size: 16px;
				width: 75px;
  				height: 44px;
				border: 0;
				outline: 0;
				color: #000;
				text-align: center;
				font-weight: 800;
				line-height: 28px;
				letter-spacing: 1px;
				text-transform: uppercase;
				display: flex;
				padding: 8px 16px;
				justify-content: center;
				align-items: center;
				gap: 8px;
				background: #fff !important;

				&.selected,
				&:hover {
					background: #fff !important;
					color: #000;
					border: 3px solid #C2391D;
					border-radius: 0;
				}

				&.disabled {
					opacity: 0.8;
					cursor: no-drop;
					border: 0;
					color: rgba(0, 0, 0, 0.5);

					&.selected {
						border: 0;
						color: rgba(0, 0, 0, 0.5);
					}

					&::after {
						display: none;
					}
				}
			}
		}
	}
	.content-similarproducts {
		padding: 50px 0 45px;
		background: #F6F6F6;

		.block.related {
			max-width: 1220px;
			padding: 0 20px;
			margin: 0 auto;

			.title strong {
				color: #000;
				text-align: center;
				font-size: 30px;
				font-weight: 700;
				letter-spacing: 1px;
				text-transform: uppercase;
			}

			.short-description-container {
				display: block;
			}


			.product-items {
				display: flex;
				gap: 30px;
				margin-bottom: 30px;
				justify-content: center;

				@include respond-to(medium, 'max') {
					flex-wrap: wrap;
					gap: 20px;
					margin-bottom: 20px;
				}

				.product-item {
					width: 33%;
					background: #FFF;
					box-shadow: 0px 0px 9.3px 5px rgba(0, 0, 0, 0.05);
					position: relative;
					padding: 39px;

					@include respond-between(nav_xs, medium) {
						width: 100% !important;
						flex-direction: row !important;
						padding-bottom: 60px !important;
						gap: 30px;
					}

					@include respond-to(medium, 'max') {
						width: 100% !important;
						flex-direction: row !important;
						padding-bottom: 60px !important;
						gap: 30px;
					}

					.product-item-photo {
						margin: 0;
					}

					.product-image-container {
						width: 100% !important;

						.product-image-wrapper {
							overflow: visible;
							padding: 0 !important;
							height: auto;
						}
					}

					.product-item-details {
						border-top: none;
						padding-top: 0;
						position: static;
					}

					.product-item-name {
						margin: 0;
						color: #000;
						font-size: 24px;
						font-weight: 700;
						line-height: 42.86px;
						letter-spacing: 1px;
						text-transform: uppercase;

						a {
							color: #000;
						}
					}

					h3 {
						color: #818181;
						font-size: 22px;
						font-weight: 400;
						line-height: 31.43px;
						margin-bottom: 16px;
						margin-top: 0;
					}

					p {
						color: #000;
						font-size: 14px;
						font-weight: 400;
						line-height: 18.57px;

						a {
							margin: 20px 0 0;
							padding: 0 0 5px;
							border-bottom: 2px solid #ba2200;
							color: #000;
							font-size: 16px;
							font-weight: 700;
							line-height: 16.623px;
							text-transform: uppercase;
							display: inline-block;

							&:hover {
								border-bottom: 2px solid #000;
							}
                            @include respond-to(large) {
                                margin: 70px 0 0;
                            }
						}
					}


					img {
						width: 100%;
						position: static;
					}

					[data-content-type="buttons"] {
						position: absolute;
						left: 30px;
						bottom: 30px;

						@include respond-between(nav_xs, medium) {
							left: 250px;
						}
					}

					.pagebuilder-button-link {
						margin: 0;
						color: #fff !important;
						font-size: 14px;
						padding: 0;
						border-bottom: 1px solid #ba2200;

						&:hover {
							border-bottom: 1px solid #fff;
						}
					}
				}
			}

		}
	}
    .content-bookyourconsulation {
        a{
            display: inline-block;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-weight: 900;
            line-height: 1.5rem;
            letter-spacing: 0.0625rem;
            text-transform: uppercase;
            background: #C2391D;
            padding: 12px 32px 12px 32px;
            height: 48px;
            margin-top: 10px;
            transition: background-color 0.3s ease-in-out;
            &:hover {
                transition: background-color 0.3s ease-in-out;
                background: #006ACC;
            }
        }
    }

	.product-info-main > .product-social-links {
		.action.towishlist {
			display: none;
			position: absolute;
			top: 103px;
			left: -130px;

			&::before,
			&:hover::before {
				color: #fff;
			}
		}
	}
}

@include respond-to($screen__l, 'max') {
	body.page-layout-product-rifle-model {
		div.product-add-form > form {
			flex-direction: column;
			padding: 20px 0;

			.product-options-bottom {
				//overflow: hidden;

				&::before {
					top: -55px;
					font-size: 18px;
				}
			}

			> div {
				max-width: unset;
			}

			.summary-wrapper::before {
				width: calc(100% + 100px);
			}
		}
	}
}

@include respond-to($screen__m, 'max') {
	body.catalog-product-view {
		.product.data.items {
			.item.title {
				margin-bottom: 40px;

				a:after {
					display: none;
				}
			}

			> .item.content {
				display: flex;
				flex-wrap: wrap;
				justify-content: normal;
				padding: 50px 20px 85px;

				&#reviews {
					padding: 50px;

					.review-items .review-item {
						display: flex;
						flex-direction: column;

						.review-details .review-title {
							word-break: break-word;
						}

						> div {
							margin-left: 0;
						}
					}
				}
			}

			.additional-attributes-wrapper.description {
				order: 1;
				margin: 0 0 70px 0;
				padding: 0 20px;

				[data-content-type='heading'] {
					font-size: 18px;
					font-weight: 800;
					line-height: 23px;
					letter-spacing: 1.3px;
					text-transform: uppercase;
					margin-bottom: 22px;
					text-align: left;
				}

				p {
					font-size: 16px;
					color: #fff;
					font-weight: 400;
					line-height: 30px;
				}
			}

			.table-wrapper {
				order: 2;
				padding-bottom: 0;

				label.table-title {
					padding: 29px 15px 46px;
					line-height: 23px;
					margin-bottom: 0;
					font-size: 18px;
					letter-spacing: 1.3px;
				}

				table:not(.totals):not(.cart):not(.table-comparison) {
					> tbody > tr {
						padding: 22px 14px;

						td[data-th]::before {
							font-size: 14px;
							line-height: 18px;
							letter-spacing: 0.7px;
							color: #fff;
							text-transform: uppercase;
							font-weight: 800;
							margin-bottom: 9px;
						}
					}

					&.additional-attributes tbody td:last-child {
						font-size: 14px;
						line-height: 20px;
						color: #fff;
					}
				}
			}
		}

		.product-add-form > form div.product-options-bottom {
			padding: 0;

			@include respond-to(medium) {
				padding: 30px 20px 25px;
			}

			> .fieldset > .field > .control .options-list > .field {
				padding: 35px 22px;
			}

			.price-box span.price-container  {
				.price {
					font-size: 20px;
				}

				> span {
					font-size: 10px;
					line-height: 10px;
				}
			}

			.box-tocart {
				.fieldset > .field.qty {
					margin-bottom: 0;
					top: 30px;
					right: 25px;

					> .label {
						font-size: 14px;
					}
				}

				.actions {
					@include respond-to(xlarge) {
						margin-top: 25px;
					}
				}

				.actions button.action.primary span {
					font-size: 16px;
					line-height: 14px;
				}
			}
		}

		div.product.info.detailed {
			.data.item a.data {
				font-size: 14px;
				letter-spacing: 0.7px;
				padding: 0 2px 10px;
			}

			#tab-label-specs,
			#tab-label-customize,
			#tab-label-details {
				margin-right: 28px;
			}
		}

		.product-info-main {
			margin-top: 40px;
			padding: 20px;

			.product-add-form {
				padding-top: 24px;
			}

			> .product-social-links .action.towishlist {
				bottom: 76px;
			}
		}

		div.block.related {
			padding: 60px 38px;

			.title strong {
				font-size: 14px;
				font-weight: 800;
				line-height: 18px;
				text-align: center;
				letter-spacing: 1.01111px;
				text-transform: uppercase;
			}
		}

		div#product-review-container {
			margin: 0;
		}

		div.block-title.review strong {
			font-size: 18px;
			text-align: left;
		}

		div.block.review-add {
			margin: 0;
			width: 100%;
		}

		.review-field-rating {
			padding-bottom: 40px;

			.control {
				display: block;
				margin: unset;
			}
		}
	}

	.page-layout-product-rifle-model {
		.product {
			.product-image-info-wrapper {

				.product-image-info {
					padding: 60px 26px 0;
				}

				.page-title-wrapper  {
					top: 38px;

					.page-title span {
						font-size: 18px;
						letter-spacing: 1.3px;
					}
				}

				div.fotorama {
					.fotorama__nav-wrap {
						position: absolute;
						bottom: -65px;
					}

					.fotorama__wrap--css3 .fotorama__stage {
						height: 180px !important;
					}

					.fotorama__dot {
						height: 7px;
						width: 7px;
						top: -55px;
					}
				}
			}

			&.media {
				width: 100%;
				position: relative;
				margin-bottom: 45px;
				.product-info-main {
					left: 0;
					margin: 0 auto;
					display: block;
					text-align: center;
					padding: 0;

					span {
						font-size: 18px;
						margin: 0 auto;
						text-align: center;
					}
				}

				.price-payment-overview {
					left: 0;
					bottom: 82px;
					text-align: center;
					padding-right: 0;

					span {
						font-size: 12px;
					}
				}

				button.action.primary.customize-btn {
					bottom: 33px;
					right: 0;
					left: 0;
					margin: 0 auto;
					padding: 12px;
					height: auto;

					a {
						font-size: 18px;
						letter-spacing: 2px;
					}
				}
			}

			.product-info-main > .product-social-links .action.towishlist {
				top: -266px;
				left: 20px;
			}
		}

		&.fotorama__fullscreen .fotorama__wrap--css3 .fotorama__stage {
			height: 809px !important;
		}

		div.product-add-form > form {
			.product-options-wrapper {
				> .fieldset > .field.cerakote-option > .control .options-list {
					> .field {
						flex-direction: column;
						margin: 15px 10px !important;
					}

					.product-image-container {
						margin: 30px 0 0;
						max-width: unset;
						top: -60px !important;
					}
				}
			}

			.swatch-option.text {
				font-size: 12px;
				width: 40px;
				height: 40px;
				line-height: 24px;
			}

			.product-options-wrapper > .fieldset > .field > label span {
				font-size: 14px;
				padding: 28px 6px 28px 22px;
				line-height: 18px;
				letter-spacing: 0.7px;
			}

			.swatch-opt {
				.swatch-attribute-options {
					padding: 24px 28px;
				}

				> div.smr_caliber .swatch-option.text {
					padding: 24px 28px 24px 84px;

					&::before {
						left: 28px;
					}

					&.selected:after {
						top: 28px;
						left: 35px;
					}
				}

				.swatch-attribute-selected-option {
					padding: 0 46px 0 30px;
					line-height: 74px;
				}

				.swatch-attribute-label {
					display: block;
					padding: 10px 16px;
					position: relative;
					color: #000;
					font-size: 18px;
					font-weight: 700;
					line-height: 28px;
					letter-spacing: 1px;

					@include respond-to(small) {
						padding: 16px 30px;
						font-size: 21px;
					}
				}
			}
        }

		.product.data.items {
			border: 0;
		}

		div.block.related {
			padding: 102px 80px;

			.product-item-info {
				padding: 30px 0;
				box-shadow: unset;
			}

			.products-grid .product-item .product-item-details {
				.product.name a {
					font-size: 14px;
					line-height: 18px;
					text-align: right;
					letter-spacing: 1.01111px;
				}

				.short-description-container p {
					font-size: 12px;
					line-height: 15px;
				}
			}

			.owl-carousel .owl-nav button {
				&::before {
					top: 46%;
					background: #000;
					padding: 22px 10px;
				}

				&.owl-prev::before {
					left: 0;
				}

				&.owl-next::before {
					right: 0;
				}
			}
		}
	}
}

@include respond-to($screen__xs, 'max') {
    body.catalog-product-view {
        div.write-review-action a {
          	font-size: 16px;
            width: 100%;
            max-width: unset;
        }
    }
}


.product-gift-card {
	.product-info-main {
		.product-info-price {
			display: flex;
			flex-direction: column;
		}

		.product-info-stock-sku {
			text-align: left;
		}

		.price-box {
			width: 100%;
		}

		.product-add-form {
			.-hidden[name="am_giftcard_image"] {
				display: none;
			}

			input {
				margin-bottom: 20px;
			}

			.amcard-button {
				margin-bottom: 20px;
				color: #fff;
				background: #d32700;
				padding: 10px 20px;
				border: none;

				&:hover {
					background: #ba2200;
				}
			}

			input[type="text"],
			input[type="number"],
			input[type="email"] {
				height: 45px;
				background: #fff;
			}

			textarea {
				height: 100px;
			}

			.box-tocart {
				.actions {
					.action {
						width: 200px;
					}
				}
			}

			.amcard-preview-container {
				.amcard-button {
					display: none;
				}
			}
		}
	}
}

body.product-set-smr-prb-carbon-barrel,
body.product-set-barrel {
    .page-wrapper {
        .page-main {
            @include respond-to(768px, max) {
                padding: unset;
            }
            margin-top: 30px;
            @include respond-to(medium) {
                margin-top: 5.625rem;
            }
            @include respond-to(small) {
                margin-top: 60px;
            }
            .product-info-main {
                .page-title{
                    font-size: 32px;
                    @include respond-to(large) {
                        font-size: 38px;
                    }
                    @include respond-to(medium) {
                        font-size: 36px;
                    }
                    @include respond-to(small) {
                        font-size: 34px;
                    }
                }
                .overview{
                    h3 {
                        font-size: 18px;
                        @include respond-to(large) {
                            font-size: 21px;
                        }
                        @include respond-to(medium) {
                            font-size: 20px;
                        }
                        @include respond-to(small) {
                           font-size: 19;
                        }
                    }
                }
                .product-add-form {
                    padding-top: 0px;
                    @include respond-to(large) {
                        padding-top: 36px;
                    }
                    @include respond-to(medium) {
                        padding-top: 20px;
                    }
                    @include respond-to(small) {
                        padding-top: 10px;
                    }

                    .box-tocart .fieldset {

                        @include respond-to(large,max) {
                            flex-direction: column;
                            align-items: inherit;
                            gap: 15px;
                        }
                        @include respond-to(768px,max) {
                            flex-direction: row !important;
                            align-items: center !important;
                            gap: unset !important;
                        }
                        @include respond-to(430px,max) {
                            flex-direction: column !important;
                            align-items: inherit !important;
                            gap: 15px !important;
                        }
                        .field.qty {
                            margin-right: 1.25rem;
                            @include respond-to(large, max) {
                                margin-right: unset;
                            }
                        }
                    }
                    .box-tocart .fieldset .actions{
                        button {
                            padding: 0 2rem;
                            @include respond-to(768px) {
                                padding: 10px;
                            }
                            @include respond-to(large) {
                                padding: 1.25rem 2rem;
                            }
                            span {
                                font-size: 1rem;
                                @include respond-to(large) {
                                    font-size: 1.125rem;

                                }
                                @include respond-to(medium) {
                                    font-size: 16px;
                                }
                            }
                        }
                        .action {
                            display: inline-block;
                            color: #FFF;
                            text-align: center;
                            font-size: 1rem;
                            line-height: 0.7rem;
                            letter-spacing: 0.0625rem;
                            text-transform: uppercase;
                            padding: 1rem 2rem;
                            background: #d32700;
                            transition: background-color 0.3s ease-in-out;
                        }
                        .box-tocart .reset-btn {
                            padding: 0.8125rem 1.25rem;
                            @include respond-to(large, max) {
                                padding: 0px 0px 0px 15px;
                            }
                        }
                    }
                }
            }
        }
        .page-main-pagebuilder-attributes {
            .product-full-width-section {
                background-color: #f6f6f6;
                margin-top: 20px;
                padding: 0.5em 0 0;
                @include respond-to(large) {
                    margin-top: 80px;
                }
                @include respond-to(medium) {
                    margin-top: 50px;
                }
                @include respond-to(small) {
                    margin-top: 30px;
                }
                .page-main {
                    margin-top: 58px;
                    @include respond-to(large) {
                        padding: unset;
                    }
                    .product-section-title {
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font-family: 'Muli';
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 37.65px;
                        letter-spacing: 1px;
                        text-align: center;
                        max-width: 1185px;
                        margin: auto;
                        color: #000000;
                        @include respond-to(768px, max) {
                            padding: 0 20px;
                        }
                        @include respond-to(large) {
                            max-width: 1190px;
                            margin: auto;
                            padding: 0 18px;
                            font-size: 30px;
                        }
                        @include respond-to(medium) {
                            font-size: 28px;
                        }
                        @include respond-to(small) {
                            font-size: 26px;
                            text-align: left;
                        }
                    }
                }
                .pagebuilder-attribute-wrapper {
                    .row-full-width-inner {
                        margin-bottom: 30px;
                        max-width: 1190px;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 0 20px;
                        @include respond-to(large) {
                            padding: 0 20px;
                        }
                        div[data-content-type='text'] {
                            max-width: 840px;
                            margin: unset;
                        }
                        h3 {
                            font-family: 'Muli';
                            font-weight: 700;
                            font-size: 17px;
                            line-height: 28px;
                            letter-spacing: 1px;
                            text-align: left;
                            text-wrap: pretty;
                            color: #2A2A2A !important;
                            @include respond-to(large) {
                                font-size: 21px;
                            }
                            @include respond-to(medium) {
                                font-size: 20px;
                            }
                            span {
                                color: #2A2A2A !important;
                            }
                        }
                        ul {
                            list-style: none;
                            padding: unset;
                        }
                        p, li{
                            font-family: 'Muli';
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0.10000000149011612px;
                            text-align: left;
                            color: #2A2A2A !important;
                            span {
                                color: #2A2A2A !important;
                            }
                            @include respond-to(medium) {
                                font-size: 15px;
                            }
                            @include respond-to(large) {
                                font-size: 16px;
                            }
                        }
                    }
                    .video-popup .pagebuilder-poster-content p {
                        color: #FFF;
                        text-align: center;
                        font-weight: 700;
                        line-height: 1.75rem;
                        letter-spacing: 0.00625rem;
                        margin: 0;
                        background-image: url(../images/play-button.svg);
                        background-position: top center;
                        background-repeat: no-repeat;
                        padding: 5rem 0 0;
                        background-size: 70px 70px;
                        font-size: 1rem;

                        @include respond-to(large) {
                            padding: 8.125rem 0 0;
                            background-size: 120px 120px;
                            font-size: 1.125rem;
                        }
                        @include respond-to(medium) {
                            padding: 6.25rem 0 0;
                            background-size: 90px 90px;
                            font-size: 1.0625rem;
                        }
                    }
                    .video-popup .pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
                        min-height: 350px !important;
                        @include respond-to(large) {
                            min-height: 658px !important;
                        }
                        @include respond-to(medium) {
                            min-height: 500px !important;
                        }
                        @include respond-to(small) {
                            min-height: 400px !important;

                        }
                    }
                }
            }
        }
        .page-main.page-main-details {
            max-width: none;
            padding: unset;
            margin: unset;
            .product-full-width-section{
                width: 100%;
                h2 {
                    display: none;
                }
            }
            .product-full-width-section#reviews {
                max-width: 1210px;
                margin: auto;
                padding: 0 20px;
                @include respond-to(large) {
                    padding: 0 18px;
                }
                .block.review-list {
                    margin-bottom: 50px;
                    @include respond-to(large) {
                        margin-bottom: 98px;
                    }
                    @include respond-to(medium) {
                        margin-bottom: 78px;
                    }
                    @include respond-to(small) {
                        margin-bottom: 60px;
                    }
                }
            }
            .table-wrapper {
                display: flex;
                flex-direction: column;
                margin-top: 45px;
                margin-bottom: 45px;
                justify-content: right;
                align-items: center;
                gap: 0px;
                max-width: 1370px;
                margin-left: auto;
                margin-right: auto;
                padding: unset;
                padding: 0 20px;
                @include respond-to(large) {
                    gap: 91px;
                    margin-top: 90px;
                    margin-bottom: 100px;

                }
                @include respond-to(medium) {
                    margin-top: 70px;
                    margin-bottom: 70px;
                    flex-direction: row;
                    justify-content: center;
                    gap: 20px;
                }
                @include respond-to(small) {
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
                .table:not(.cart):not(.totals):not(.table-comparison) {
                    display: flex;
                    flex-direction: column;
                }
                .table:not(.cart):not(.totals):not(.table-comparison)>tbody>tr td {
                    display: table-cell;
                    @include respond-to(640px, max) {
                        display: flex;
                    }
                }

                .table-content {
                   display: block;
                    margin-left: 51px;
                    width: 100%;
                    max-width: 100%;
                    @include respond-to(1370px,max) {
                        margin-left:unset
                    }
                    @include respond-to(medium) {
                        width: 50%;
                    }
                    .additional-attributes {
                        width: 100%;
                    }
                }
                .table-caption {
                    font-family: 'Muli';
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 37.65px;
                    letter-spacing: 1px;
                    text-align: center;
                    text-transform: uppercase;
                    max-width: 100%;
                    width: 100%;
                    overflow: visible;
                    position: relative;
                    height: auto;
                    padding-bottom: 30px;
                    color: #333333;
                    @include  respond-to(medium) {
                        max-width: 340px;
                        font-size: 28px;
                    }
                    @include respond-to(small) {
                        text-align: left;
                    }
                    @include respond-to(large) {
                        font-size: 30px;
                    }
                }
                tbody {
                    display: flex;
                    flex-direction: column;
                    th {
                        padding: 0.34375rem 0 0.625rem 0;
                        line-height: 24px;
                        letter-spacing: 0;
                        text-align: left;
                        color: #333333;
                        font-family: 'Muli';
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 1px;
                        text-align: left;

                        &:after {
                            content: ":";
                        }
                    }
                    td {
                        padding: 0.34375rem 5px 0.625rem;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #333333;
                        font-family: 'Muli';
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 1px;
                        text-align: left;
                        ul {
                            list-style: none;
                            padding: unset;
                            li {
                                display: inline;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                text-align: left;
                                color: #333333;
                                font-family: 'Muli';
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                letter-spacing: 1px;
                                text-align: left;
                            }
                        }
                    }
                }
                .information_image{
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    @include respond-to(medium) {
                        max-width: 738px;
                        width: calc(100% - 38%);

                    }
                }
            }

            .block.upsell {
                padding: 3.125rem 0 2.8125rem;
                background: #F6F6F6;
                .block-title, .title strong {
                    color: #000;
                    text-align: center;
                    font-size: 26px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-bottom: 3.75rem;
                    @include respond-to(large) {
                        font-size: 30px;
                    }
                    @include respond-to(medium) {
                        font-size: 28px;
                    }
                }
                .block-title, .block-content {
                    max-width: 1220px;
                    padding: 0 20px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .short-description-container {
                    display: block;
                    ul {
                        padding: unset;
                        list-style: none;
                    }
                }


                .product-items {
                    display: flex;
                    gap: 30px;
                    margin-bottom: 30px;
                    justify-content: center;

                    @include respond-to(medium, 'max') {
                        flex-wrap: wrap;
                        gap: 20px;
                        margin-bottom: 20px;
                    }

                    .product-item {
                        width: 33%;
                        background: #FFF;
                        box-shadow: 0px 0px 9.3px 5px rgba(0, 0, 0, 0.05);
                        position: relative;
                        padding: 39px;

                        @include respond-between(nav_xs, medium) {
                            width: 100% !important;
                            flex-direction: row !important;
                            padding-bottom: 60px !important;
                            gap: 30px;
                        }

                        @include respond-to(medium, 'max') {
                            width: 100% !important;
                            flex-direction: row !important;
                            padding-bottom: 60px !important;
                            gap: 30px;
                        }

                        .product-item-photo {
                            margin: 0;
                        }

                        .product-image-container {
                            width: 100% !important;

                            .product-image-wrapper {
                                overflow: visible;
                                padding: 0 !important;
                                height: auto;
                            }
                        }

                        .product-item-details {
                            border-top: none;
                            padding-top: 0;
                            position: static;
                            margin: auto;
                            .short-description-container{
                               h2 {
                                   font-size: 24px;
                                   word-wrap: break-word;
                                   @include respond-to(large) {
                                       font-size: 30px;
                                   }
                                   @include respond-to(medium) {
                                       font-size: 28px;
                                   }
                                   @include respond-to(small) {
                                       font-size: 26px;
                                   }

                               }
                            }
                        }

                        .product-item-name {
                            margin: 0;
                            color: #000;

                            font-weight: 700;
                            line-height: 42.86px;
                            letter-spacing: 1px;
                            font-size: 20px;
                            text-transform: uppercase;

                            @include respond-to(medium) {
                                font-size: 24px;
                            }
                            @include respond-to(small) {
                                font-size: 22px;
                            }

                            a {
                                color: #000;
                            }
                        }
                        .price{
                            font-size: 14px;
                            font-weight: 600;
                        }

                        h3 {
                            color: #818181;
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 31.43px;
                            margin-bottom: 16px;
                            margin-top: 0;
                        }

                        p {
                            color: #000;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18.57px;

                            a {
                                margin: 20px 0 0;
                                padding: 0 0 5px;
                                border-bottom: 2px solid #ba2200;
                                color: #000;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 16.623px;
                                text-transform: uppercase;
                                display: inline-block;

                                &:hover {
                                    border-bottom: 2px solid #000;
                                }

                                @include respond-to(large) {
                                    margin: 70px 0 0;
                                }
                            }
                        }

                        .price-label {
                            display: none;
                        }

                        img {
                            width: 100%;
                            position: static;
                        }

                        [data-content-type="buttons"] {
                            position: absolute;
                            left: 30px;
                            bottom: 30px;

                            @include respond-between(nav_xs, medium) {
                                left: 250px;
                            }
                        }

                        .pagebuilder-button-link {
                            margin: 0;
                            color: #fff !important;
                            font-size: 14px;
                            padding: 0;
                            border-bottom: 1px solid #ba2200;

                            &:hover {
                                border-bottom: 1px solid #fff;
                            }
                        }
                    }
                }
            }
            .full-width-two-column-content {
                a{
                    display: inline-block;
                    color: #FFF;
                    text-align: center;
                    font-size: 1rem;
                    font-weight: 900;
                    line-height: 1.5rem;
                    letter-spacing: 0.0625rem;
                    text-transform: uppercase;
                    background: #C2391D;
                    padding: 0.75rem 2rem 0.75rem 2rem;
                    height: 48px;
                    margin-top: 0.625rem;
                    transition: background-color 0.3s ease-in-out;
                    &:hover {
                        transition: background-color 0.3s ease-in-out;
                        background: #006ACC;
                    }
                }
            }
        }
    }
}
