// sass-lint:disable-all

//
//  Common
//  _____________________________________________

.cms-page-view {

	.page-main {
		max-width: none;
		padding: 0;
	}

	.columns .column.main {
		.about-us-additional-info-block {
			background-color: #fff;

			h1 {
				font-size: 30px;
				color:#000;
				margin-bottom: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
				font-weight: bold;
			}

			p {
				letter-spacing: 1.5px;
			}

			.row-full-width-inner {
				padding: 100px 20px;
			}
		}

		.about-us-image-block {
			.row-full-width-inner {
				min-height: 1px;
			}

			.pagebuilder-column-group {
				justify-content: center;

				figure[data-content-type='image'] {
					min-height: 1px;
				}
			}

			.pagebuilder-column {
				width: 20% !important;

				.figure[data-content-type='image'] {
					font-size: 0;
					min-height: 1px;
				}
			}
		}

		.about-us-process-block {
			.row-full-width-inner {
				padding: 100px 20px;
				min-height: 1px;
			}

			h1 {
				font-size: 31px;
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 86px;
				color: #fff;
				letter-spacing: 2.5px;
			}

			.pagebuilder-column-group {
				padding-left: 0;

				.process-bar {
					width: 100% !important;
					max-width: 110px;

					figure {
						min-height: 1px;
					}
				}

				.process-content {
					flex: 1;
					width: 100% !important;

					.process-item {
						h2 {
							font-size: 50px;
							letter-spacing: 3.5px;
							opacity: 0.5;
							color: #fff !important;
							font-weight: bold;
						}

						p {
							font-size: 18px;
							opacity: 0.7;
							color: #fff;
							margin-top: 6px;

							span {
								color: #fff !important;
							}
						}
					}
				}
			}
		}

		.about-us-precision-from-the-start-block {
			background-color: #0a0a0a;
			color: #fff;

			.row-full-width-inner {
				padding: 100px 20px;
			}

			h2 {
				font-size: 26px;
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 22px;
				letter-spacing: 2.5px;
			}

			.precision-start-column {
				&-left p {
					margin-bottom: 20px;
				}

				&-left,
				&-right {
					p {
						opacity: 0.7;
					}
				}
			}
		}

		.about-us-call-to-action-block {
			background-color: #fff;
			color:#000;

			.row-full-width-inner {
				padding: 100px 20px;
			}

			h1 {
				font-size: 30px;
				font-weight: bold;
				letter-spacing: 2px;
				text-transform: uppercase;
				margin-bottom: 16px;
			}

			p {
				font-size: 20px;
				letter-spacing: 1.5px;
				margin-bottom: 31px;
			}

			a {
				font-size: 17px;
				position: relative;
				background-color: #d32700;
				margin: 0;
				padding: 9px 45px 9px 18px;
				width: 187px;
				letter-spacing: 1px;
				font-weight: bold;

				&:after {
					font-size: 40px;
					font-family: 'icons-blank-theme';
					content: '\e622' ;
					position: absolute;
					right: 10px;
					top: 9px;
					font-weight: normal;
					transform: rotate(270deg);
				}

				&:hover {
					background-color: #ba2200;
				}
			}
		}
	}
}

.cms-about-us,
.cms-about {
	.page-main {
		background-color: #0a0a0a;
		min-height: 1px;

		.columns .column.main {
			padding-bottom: 0;

			// p {
			// 	font-family: @font-family__base;
			// }
		}
	}

	.global-page-banner .pagebuilder-overlay .pagebuilder-poster-content {
		top: 45%;
	}
}

.call-to-action-block-wrapper {
	.block {
		margin-bottom: 0 !important;
	}

	.call-to-action-block .row-full-width-inner  {
		padding: 100px 20px;
	}
}

.call-to-action-block-wrapper [data-content-type$='block'] .call-to-action-block,
.call-to-action-block {
	h1 {
		font-size: 24px;
		color:#000;
		font-weight: bold;
		letter-spacing: 2.1px;
		text-transform: uppercase;
		margin-bottom: 16px;
	}

	p {
		letter-spacing: 1.5px;
		margin-bottom: 31px !important;
	}

	[data-content-type='button-item'] a[data-element='link'] {
		font-size: 17px;
		position: relative;
		display: inline-block;
		color: #fff;
		padding: 9px 51px 9px 19px;
		background-color: #d32700;
		font-weight: bold;
		width: 169px;
		letter-spacing: 1px;

		&:hover {
			text-decoration: none;
			background-color: #ba2200;
		}

		&:after {
			font-size: 40px;
			font-family: 'icons-blank-theme';
			content: '\e622' ;
			position: absolute;
			right: 10px;
			top: 9px;
			font-weight: normal;
			transform: rotate(270deg);
		}
	}
}

.contact-us-block {
	background-color:#000;

	> [data-content-type='block'] {
		min-height: 1px;
	}

	.row-full-width-inner {
		min-height: 1px;
	}

	.block {
		margin-bottom: 0 !important;
	}

	.content-center {
		//background-color: lighten(saturate(spin(@cod-gray, 0), 0), 1.9608);
		padding: 20px;
		text-align: center;
		width: 100% !important;
		max-width: 573px;
		max-height: 276px;

		h2 {
			font-size: 20px;
			color: #fff;
			font-weight: bold;
			margin-top: 41px;
			margin-bottom: 24px;
		}

		p {
			line-height: 1.5;
			margin: 0 auto 21px !important;
			width: 100%;
			max-width: 358px;
		}

		a {
			font-size: 16px;
			display: inline-block;
			background-color: #d32700;
			margin: 0;
			padding: 7px 20px;
			width: 100%;
			max-width: 186px;
			letter-spacing: 1px;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			margin-bottom: 47px;

			&:hover {
				text-decoration: none;
				background-color: #ba2200;
			}
		}
	}

	.image-left,
	.image-right {
		flex-direction: row;
		align-items: center;
		width: 100% !important;
		max-width: 399px;

		figure[data-content-type='image'] {
			font-size: 0;
			min-height: 1px;
			min-width: 1px;
		}
	}
}

@include respond-to($screen__m, 'max') {
    .cms-page-view .columns .column.main {
        .about-us-image-block .row-full-width-inner {
            padding: 15px !important;

            .pagebuilder-column {
                flex-basis: 1;
                font-size: 0;
                background-attachment: initial !important;
                width: 50% !important;
                margin: 0 !important;
                padding: 5px !important;
            }
        }

        .about-us-process-block {
            h1 {
                text-align: center;
            }

            .pagebuilder-column-group .process-bar {
                display: none !important;
            }
        }
    }

    .contact-us-block .pagebuilder-column-group > div {
        margin: 0 !important;
        max-width: 100%;
    }
}


@include respond-to($screen__xs, 'max') {
    .cms-page-view .columns .column.main {
        .about-us-image-block .row-full-width-inner {
            .pagebuilder-column {
                width: 100% !important;
            }
        }
    }
}

@include respond-to($screen__s) {
    .cms-page-view .columns .column.main {
        .about-us-process-block .pagebuilder-column-group .process-content .process-item {
			flex-direction: row !important;

            p {
                padding-left: 35px;
            }
        }
    }
}

@include respond-to($screen__m) {
    .cms-page-view .columns .column.main {
        .about-us-process-block {
            .row-full-width-inner {
                padding: 131px 50px 165px;
            }

            .process-content {
                padding: 0 0 0 50px !important;
            }
        }

        .about-us-additional-info-block .row-full-width-inner {
            padding: 120px 20px 97px;
        }

        .about-us-precision-from-the-start-block {
            .row-full-width-inner {
                padding: 126px 50px 114px;
            }

            .precision-start-column {
                &-right p {
                    margin-left: 36px;
                }

                &-left p {
                    margin-right: 36px;
                }

                &-right,
                &-left {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .about-us-call-to-action-block .row-full-width-inner {
            padding: 152.5px 20px;
        }
    }
}

@include respond-to($screen__l) {
    .cms-page-view .columns .column.main {
        .about-us-precision-from-the-start-block .row-full-width-inner {
            padding: 146px 100px 134px;
        }

        .about-us-process-block .row-full-width-inner {
            padding: 131px 100px 165px;
        }

        .about-us-process-block {
            .pagebuilder-column-group {
                padding-left: 22px;
            }
        }
    }
}

@include respond-to($screen__1200) {
    .cms-page-view .columns .column.main {
        .about-us-precision-from-the-start-block .row-full-width-inner {
            padding: 146px 196px 134px;
        }

        .about-us-process-block .row-full-width-inner {
            padding: 131px 157px 165px 153px;
        }

        .about-us-process-block .process-content {
            padding: 0 0 0 132px !important;
        }
    }

}
