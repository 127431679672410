// sass-lint:disable-all

.mpblog-post-view {
	margin-bottom: 50px;

	h1 {
		font-size: 40px;
		margin-top: 60px;
		margin-bottom: 15px;
		font-weight: bold;
		letter-spacing: 1px;
	}

	#sharing {
		padding: 0;
		background: transparent;

		.share-col-left {
			float: none;
			width: 100%;

			h5 {
				text-transform: uppercase;
				letter-spacing: 1px;
				margin-bottom: 10px;
			}
		}

		.share-col-right {
			width: 100%;

			.at-share-btn-elements {
				text-align: left;
			}
		}
	}

	.columns {
		.column.main {
			width: 74%;
			padding-bottom: 40px;
		}
	}

	.mp-blog-rss {
		text-align: right;
	}

	.block-blog-related.about-admin {
		display: none;
	}

	.post-view {
		margin-top: 10px;
		color: #000;

		.post-view-image {
			img {
				width: 100%;
			}
		}

		.post-post_content {
			font-size: 16px;
			margin: 30px 0;
			line-height: 1.5;
		}
	}

	.mp-post-location-time {
		display: flex;
		margin-bottom: 20px;

		.post-time,
		.post-location {
			display: flex;
			align-items: center;

			.content {
				font-size: 14px;
				font-weight: bold;
				color: #000;
			}

			&:before {
				font-size: 23px;
				display: inline-block;
				//font-family: @smr-icons__font-name;
				color: #000;
				margin-right: 5px;
			}
		}

		.post-time:before {
			content: '\e925';
		}

		.post-location {
			margin-right: 50px;

			&:before {
				content: '\e916';
			}
		}
	}
}

.mpblog-post-index,
.mpblog-category-view {
	.blog-category-title {
		display: none;
	}

	.post-list-content {
		.limiter,
		.toolbar-amount {
			display: none !important;
		}

		.pager .pages {
			margin: 93px 0 55px;

			.item {
				margin: 0 14px 0 0;
			}

			.action:before {
				font-size: 30px;
				font-weight: normal;
			}

			.pages-item-previous {
				margin: 0 65px 0 0;
			}

			.pages-item-next {
				margin: 0 0 0 51px;
			}

			strong.page {
				padding: 0 12px;
				background: #000;
				text-decoration: none;
				color: #fff;

				span {
					color: #fff;
					text-decoration: none;
				}
			}

			a.page {
				padding: 0 12px;
				font-weight: bold;

				&:hover {
					text-decoration: underline;

					span {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.page-main .columns {
		display: inline-flex;
		width: 100%;
	}

	.page-main {
		max-width: none;
		padding: 0;
	}

	[data-content-type='row'][data-appearance='contained'] {
		max-width: none;

		.events-info {
			background: #0a0a0a;
			padding: 108px 20px;

			.pagebuilder-column-group {
				justify-content: space-between;
				align-items: center;
				max-width: 1126px;
				width: 100%;
				margin: 0 auto;
			}

			.pagebuilder-column {
				&:first-child {
					max-width: 100%;
				}

				&:last-child {
					max-width: 100%;
					margin-top: 50px;

					figure[data-content-type='image'] {
						text-align: center;
					}
				}
			}

			[data-content-type='heading'] {
				font-size: 26px;
				font-weight: 800;
				line-height: 40px;
				color: #fff;
				text-transform: uppercase;
				margin-bottom: 28px;
				letter-spacing: 1px;
			}

			div[data-content-type='text'] p {
				font-size: 18px;
				font-weight: 4000;
				line-height: 30px;
				color: rgba(191, 202, 217, 0.7);
			}
		}
	}

	.smr-events-header {
		margin-top: -14px;

		h1 {
			text-align: center !important;  // sass-lint:disable-line no-important
			margin-bottom: 0;
            max-width: 1280px;
            margin: auto;
            letter-spacing: 0.625rem;

			@include respond-to(medium) {
				text-align: right !important;  // sass-lint:disable-line no-important
			}
		}
	}

	.page-title-wrapper {
		display: none;
	}

	.category-tabs {
		display: flex;
		justify-content: center;
		padding: 56px 20px 46px;

		a {
			font-size: 23px;
			font-weight: 800;
			text-transform: uppercase;
			letter-spacing: 2px;

			&.active, &:hover {
				border-bottom: solid #d32700;
				text-decoration: none;
			}
		}

		li:first-child {
			margin-right: 90px;
		}
	}

	.no-events {
		border-top: 1px solid #d8d8d8;
		text-align: center;
		padding: 60px 0 20px
	}

	.post-list-content {
		max-width: 1058px + 40px;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px;

		.post-list-body {
			border-top: 1px solid #d8d8d8;
		}
	}

	.post-list-item {
		border-bottom: 1px solid #d8d8d8;
		margin-bottom: 40px;
		padding: 74px 0;
	}

	.post-item-wraper {
		display: flex;
		flex-direction: column;
		border: none;
		padding: 0 !important;

		&:hover {
			border: none;
			box-shadow: none;
		}
	}

	.post-image {
		font-size: 0;
		min-height: 0;
		max-height: none;
		min-width: 0 !important;
		max-width: 439px;
		display: block !important;
		width: 100%;

		img.img-responsive {
			width: 100%;
			height: auto;
			margin: 0;
			position: static;
		}
	}

	.post-info-wraper {
		width: 100%;
		display: block !important;
		padding: 35px 0 0 0;

		.mp-post-info {
			font-size: 15px;
			font-weight: 800;
			color: #000;
			letter-spacing: 2px;
			margin-bottom: 9px;
		}

		.mp-post-title {
			font-weight: 800;
			margin-bottom: 19px;
		}

		.mp-blog-calendar-times {
			display: none;
		}

		.post-short-description {
			font-size: 18px;
			color: #000;
			line-height: 1.5;

			&.hide-description {
				display: none;
			}
		}

		.mp-post-location-time {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-top: 24px;
		}

		.post-location,
		.post-time {
			font-size: 14px;
			display: flex;
			font-weight: 700;
			text-transform: uppercase;

			&:before {
				margin-right: 5px;
			}

			.content {
				margin-top: 2px;
				line-height: 1.2;
			}
		}

		.post-location {
			margin-right: 0;
			margin-bottom: 10px;
			width: 100%;

			&:before {
				//.smr-font-icon();
				font-size: 23px;
				content: '\e916';
				color: #000;
			}
		}

		.post-time {
			width: 100%;

			&:before {
				//.smr-font-icon();
				font-size: 23px;
				content: '\e925';
				color: #000;
			}
		}
	}

	.morecontent {
		.morelink {
			font-size: 12px;
			font-weight: 800;
			letter-spacing: 1px;
			text-decoration: underline !important;
			text-transform: uppercase;
			vertical-align: middle;

			&.less {
				display: block;
				margin-top: 25px;
			}
		}
	}

	.limiter {
		margin-top: 50px;
	}
}

@include respond-to($screen__m, 'max') {
	.mpblog-post-view .mp-post-location-time {
	flex-direction: column;

		.post-location {
			margin: 0 0 10px 0;
		}
	}
}

@include respond-to($screen__s, 'max') {
	.mpblog-post-index,
	.mpblog-category-view {
		ul.category-tabs {
		flex-direction: column;

			li {
				text-align: center;
				margin: 0 0 20px 0;

				&:first-child {
					margin-right: 0;
				}
			}
		}

		.post-list-content .pager .pages .pages-items {
			padding: 0 40px 0 54px;

			.item.pages-item-previous,
			.item.pages-item-next {
				position: absolute;
				margin: 0;
			}

			li.item {
				padding-top: 5px;
			}
		}
	}
}

@include respond-to($screen__xs, 'max') {
	.mpblog-post-index,
	.mpblog-category-view {
		div.post-list-content .pager .pages {
			margin: 73px 0 25px;

			.pages-items {
				padding: 0 40px 0 50px;

				.item {
					margin: 0 10px 0 0;
				}
			}
		}
	}
}

@include respond-to($screen__m) {
	.mpblog-post-index,
	.mpblog-category-view {
	[data-content-type='row'][data-appearance='contained'] .events-info {
		.pagebuilder-column:last-child {
			margin-left: 25px;
			margin-top: 18px;
			max-width: 438px;
		}

		.pagebuilder-column:first-child {
			max-width: 488px;
		}
		}

		.post-info-wraper {
			padding: 0 0 0 20px;
		}

		.post-item-wraper {
			flex-direction: row;
		}
	}
}

@include respond-to(900px) {
	.mpblog-post-index,
	.mpblog-category-view {
		.post-info-wraper {
			flex: 1;
			padding: 34px 0 0 20px;
		}
	}
}

@include respond-to($screen__l) {
	.mpblog-post-index,
	.mpblog-category-view {
		[data-content-type='row'][data-appearance='contained'] .events-info [data-content-type='heading'] {
			padding-right: 40px;
		}

		.post-info-wraper {
			padding: 34px 39px 0 85px;

			.mp-post-location-time {
				flex-direction: row;
			}

			.post-location {
				margin-right: 47px;
				margin-bottom: 0;
				width: 54%;
			}

			.post-time {
				width: 45%;
			}
		}
	}
}
