/*   Global Mixins
	--------------------------------------------------------------------------  */

/**
 * Centers element within relative parent
 */
@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

/**
 * Vertically centers element within relative parent
 */
@mixin vertical-center {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

/**
 * Maintains aspect ratio of element based on
 * width and height values given
 */
@mixin maintain-aspect-ratio($width, $height) {
	position: relative;

	&::before {
		display: block;
		width: 100%;
		padding-top: ($height / $width) * 100%;
		content: ' ';
	}

	> .content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

@mixin tb($style: dotted, $color: #f00) {
	border: 1px $style $color;
}

/**
 * Applies clearfix to element
 */
@mixin clearfix {
	&::before,
	&::after {
		display: table;
		content: ' ';
	}

	&::after {
		clear: both;
	}
}

/**
 * Expands element to "cover" relative parent
 */
@mixin coverer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/**
 * Creates CSS triagle
 * @param {string} $direction Triangle direction, either `top`, `right`, `bottom` or `left`
 * @param {color} $color Triangle color
 * @param {integer} $size Triangle size
 */
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
	@if not index(top right bottom left, $direction) {
		@error 'Direction must be either "top", "right, "bottom" or "left".';
	}

	z-index: 2;
	width: 0;
	height: 0;
	content: '';
	border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top or $direction == bottom {
		border-right: $perpendicular-borders;
		border-left: $perpendicular-borders;
	} @else if $direction == right or $direction == left {
		border-top: $perpendicular-borders;
		border-bottom: $perpendicular-borders;
	}
}

/**
 * Applies font-smoothing
 */
@mixin font-smoothing {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

/**
 * Customizes browser scroll bars (does not work in all browsers)
 * @author Hugo Giraudel
 * @param {size} $size Horizontal scrollbar's height and vertical scrollbar's width
 * @param {color} $foreground-color Scrollbar's color
 * @param {color} $background-color [mix($foreground-color, white, 50%)] Scrollbar's color
 */
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, #fff, 50%)) {
	//scss-lint:disable PropertySpelling
	// For Google Chrome
	::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}

	::-webkit-scrollbar-track {
		background: $background-color;
	}

	// For Internet Explorer
	body {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}

/**
 * Positions element
 * @param {position} $position Either `relative`, `absolute` or `fixed`
 * @param {distance} $top Top offset
 * @param {distance} $right Right offset
 * @param {distance} $bottom Bottom offset
 * @param {distance} $left Left offset
 */
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

/**
 * Absolute positioning
 * @param {Arglist} $args - Offsets
 * @require {mixin} position
 */
@mixin absolute($args...) {
	@include position(absolute, $args...);
}

/**
 * Relative positioning
 * @param {Arglist} $args - Offsets
 * @require {mixin} position
 */
@mixin relative($args...) {
	@include position(relative, $args...);
}

/**
 * Fixed positioning
 * @param {Arglist} $args - Offsets
 * @require {mixin} position
 */
@mixin fixed($args...) {
	@include position(fixed, $args...);
}

/**
 * Event Wrapper
 * @author Harry Roberts
 * @param {Bool} $self (false) - Whether or not to include current selector
 * @see https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
 *
 * Example Usage:
 * .example {
 *     text-decoration: none;
 *
 *     @include on-event {
 *         text-decoration: underline;
 *     }
 * }
 */
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}

/**
 * Positions element to account for WordPress admin bar "bump"
 */
@mixin admin-sticky-fix($offset: 0) {
	$narrow-offset: 46px;
	$wide-offset: 32px;

	@if $offset != 0 and type-of($offset) == 'number' {
		$narrow-offset: $narrow-offset + $offset;
		$wide-offset: $wide-offset + $offset;
	}

	.admin-bar & {
		top: $narrow-offset;

		@media screen and (min-width: 783px) {
			top: $wide-offset;
		}
	}
}

/**
 * Horizontally centers block element
 */
@mixin margin-center {
	margin-right: auto;
	margin-left: auto;
}

/**
 * Hides element accessibly
 */
@mixin screen-reader-text {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px); // sass-lint:disable-line no-duplicate-properties
	white-space: nowrap;
}

/**
 * Flex styles wrappers
 */
@mixin flex-styles {
	@at-root {
		.flexbox &,
		.flexboxlegacy & {
			@content;
		}
	}
}

@mixin no-flexbox {
	@at-root {
		.no-flexbox &,
		.no-flexboxlegacy & {
			@content;
		}
	}
}

/**
 * Sets webkit tap highlight color
 * @type {[type]}
 */
@mixin tap-color($color: transparent) {
	//default: rgba(0, 0, 0, 0.4)
	-webkit-tap-highlight-color: $color;
}

/**
 * Convers Photoshop letter spacing value to EM
 */
@mixin ps-letter-spacing($val) {
	letter-spacing: $val * 0.001em;
}

/**
 * Sizes element to be square
 */
@mixin square($size) {
	width: $size;
	height: $size;
}

/**
 * Sets form element placeholder text color
 */
@mixin placeholder($color) {
	&::placeholder {
		color: $color;
	}
}
