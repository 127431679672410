// Programs
// sass-lint:disable-all
html {
    scroll-behavior: auto;
}

body.programs-product-view {
	.product-descriptions {
		width: 100%;
	}

	.product-anchor-nav-wrapper {
		margin: 30px 0;
		padding: 10px 20px;
		background-color: #fff;

		@include respond-to(medium) {
			position: sticky;
			top: 0;
			z-index: 99;
		}

		@include respond-to(small) {
			margin: 40px 0;
		}

		.anchor-nav-wrapper {
			position: relative;

			.anchor-nav-toggle {
				display: block;
				text-align: left;
				border: 1px solid #ccc;
				padding: 10px 20px;
				background-image: url('../images/arrow-down.svg');
				background-repeat: no-repeat;
				background-position: center right 10px;

				@include respond-to(medium) {
					display: none;
				}
			}
		}

		.anchor-nav {
			justify-content: center;
			flex-wrap: wrap;
			display: none;

			@include respond-to(medium) {
				display: flex;
			}

			@include respond-to(medium, 'max') {
				&.active {
					display: block;
					position: absolute;
					top: 100%;
					left: 0;
					background: #fff;
					border: 1px solid #ccc;
					padding: 20px;
					margin-top: -1px;
					width: 100%;
				}
			}

			a {
				color: #333333;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.53px;
				line-height: 20px;
				border-bottom: 1px solid #ECECEC;
				padding: 10px 21px;
				position: relative;

				@include respond-to(medium, 'max') {
					width: 100%;
					display: block;
					padding: 10px 0;
					text-align: left;
				}

				&::before {
					position: absolute;
					left: 0;
					bottom: 0;
					height: 3px;
					width: 0;
					background-color: #D32700;
					content: '';
					transition: all 0.3s ease-out;

					@include respond-to(medium, 'max') {
						display: none;
					}
				}

				&.active,
				&:hover {
					color: #D32700;
					&::before {
						width: 100%;
					}
				}
			}
		}
	}

	.product-class-overview {
		.row-full-width-inner {
			max-width: 810px;
			padding: 0 20px;
		}
	}

	.product-inline-cta {
		max-width: 970px;
		width: calc(100% - 40px);
		margin: 30px 20px;

		@include respond-to($screen__l) {
			width: 100%;
			max-width: 970px;
			margin: 30px auto;
		}

		@include respond-to($screen__m, 'max') {
			.pagebuilder-column-group {
				min-height: auto !important;
				padding: 0 20px !important;
				background-position: left center !important;
			}
		}

		.pagebuilder-column-line {
			flex-wrap: wrap;

			.pagebuilder-column {
				@include respond-to($screen__m) {
					width: 50%;
				}

				@include respond-to($screen__m, 'max') {
					width: 100% !important;
					margin: 0 0 20px !important;
				}
			}
		}
	}

	.product-daily-itinerary {
		margin-bottom: 60px;

		.row-full-width-inner {
			max-width: 810px;
			padding: 0 20px;

			.pagebuilder-column-group {
				margin-top: 60px;

				.pagebuilder-column {
					flex-basis: inherit;
				}

				h2 {
					opacity: 0.4;
					color: #888F9A;
					font-size: 24px;
					font-weight: bold;
					letter-spacing: 0.95px;
					line-height: 50px;
					margin: 0;

					@include respond-to(medium) {
						font-size: 40px;
					}

					@include respond-to(small) {
						font-size: 32px;
					}
				}
			}
		}
	}

	.cms-image-slider {
		.block {
			width: 100%;
			max-width: 100%;
			margin: 0 auto;
			padding: 0;

			.slick-slide {
				height: 300px;

				> div {
					height: 100%;

					figure {
						position: relative;
						height: 100%;
						cursor: pointer;

						img {
							width: 100%;
							height: 100% !important;
							object-fit: cover;
							object-position: top;
							opacity: 0.5;
						}

						&:before {
							position: absolute;
							top: 45%;
							right: 47%;
							background-image: url('../images/zoom-in.svg');
							width: 30px;
							height: 30px;
							display: none;
							content: '';
							background-size: cover;
						}

						&:hover {
							&:before {
								display: block;
							}
						}
					}
				}
			}

			.slick-next,
			.slick-prev {
				width: 57px;
				height: 90px;
				background: #000;

				&::before {
					border: solid #fff;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 7px;
					content: '';
				}
			}

			.slick-prev {
				left: 0;

				&::before {
					transform: rotate(135deg);
				}
			}

			.slick-next {
				right: 0;

				&::before {
					transform: rotate(-45deg);
				}
			}
		}

		.cms-page-slider-main-image {
			display: none !important;
		}
	}

	.product-two-column-bulleted-list {
		color: #fff;
		padding: 30px 20px;

		@include respond-to(medium) {
			padding: 80px 20px;
		}

		@include respond-to(small) {
			padding: 60px 20px;
		}

		.pagebuilder-column-group {
			max-width: 980px;
			margin: 0 auto;

			.pagebuilder-column-line:first-child {
				margin-bottom: 40px;
				flex-wrap: wrap;

				.pagebuilder-column {
					@include respond-to(medium, 'max') {
						width: 100% !important;
					}
				}
			}

			.pagebuilder-column-line:last-child {
				justify-content: space-between;
				flex-wrap: wrap;

				.pagebuilder-column {
					width: 100% !important;

					@include respond-to(medium) {
						width: 47% !important;
					}
				}
			}
		}

		h2 {
			font-size: 26px;
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 22px;
			letter-spacing: 2.5px;
		}
	}

	.product-provided-equipment-list {
		.row-full-width-inner {
			max-width: 1000px;
			margin: 0 auto;
			padding: 80px 20px;

			.pagebuilder-column-line {
				flex-wrap: wrap;
			}

			.pagebuilder-column {
				@include respond-to(medium, 'max') {
					width: 100%;
				}

				&:first-child {
					padding-right: 30px;
				}
			}
		}

		h2 {
			margin-top: 0;
		}

		ul {
			padding: 0 0 0 14px;
		}
	}

	.product-available-class-dates {
		color: #fff;

		.row-full-width-inner {
			max-width: 1200px;
			margin: 0 auto;
			padding: 30px 20px;

			@include respond-to(medium) {
				padding: 80px 20px;
			}

			@include respond-to(small) {
				padding: 60px 20px;
			}
		}

		.pagebuilder-column-group {
			.pagebuilder-column-line {
				gap: 30px;
				margin-bottom: 20px;

				@include respond-to(medium) {
					margin-bottom: 40px;
				}

				@include respond-to(medium, 'max') {
					flex-wrap: wrap;

					.pagebuilder-column {
						@include respond-to(medium, 'max') {
							width: 45% !important;
						}

						@include respond-to(small, 'max') {
							width: 100% !important;
						}
					}
				}

				&:first-child {
					.pagebuilder-column {
						@include respond-to(medium, 'max') {
							width: 100% !important;
						}
					}
				}

				strong {
					font-weight: 900;
					padding-bottom: 5px;
				}
			}
		}
	}

	.product-meet-the-instructors {
		.row-full-width-inner {
			max-width: 1000px;
			margin: 0 auto;
			padding: 30px 20px;

			@include respond-to(medium) {
				padding: 80px 20px;
			}

			@include respond-to(small) {
				padding: 60px 20px;
			}
		}

		.pagebuilder-column-group {
			margin: 40px 0 0;

			@include respond-to($screen__m, 'max') {
				margin: 20px 0 0;
			}
		}

		@include respond-to($screen__m, 'max') {
			.pagebuilder-column-line {
				flex-wrap: wrap;

				.pagebuilder-column {
					width: 100% !important;

					figure {
						margin-bottom: 10px;
					}
				}
			}
		}

		.pagebuilder-column-line {
			.pagebuilder-column {
				figure {
					img {
						@include respond-to(large) {
							max-width: 100% !important;
						}

						@include respond-to($screen__m) {
							max-width: 90% !important;
						}
					}
				}
			}
		}

		h2 {
			margin: 0 0 60px;
		}

		h3 {
			margin-top: 0;
		}
	}

	.product-discover-section {
		.row-full-width-inner {
			max-width: 1120px;
			padding: 20px 20px 40px;

			@include respond-to($screen__s) {
				padding: 50px 20px 100px;
			}

			.pagebuilder-column-group {
				.pagebuilder-column-line {
					color: #fff;

					@include respond-to($screen__s, 'max') {
						flex-wrap: wrap;
						text-align: center;
					}

					&:first-child {
						.pagebuilder-column img {
							max-width: 150px !important;
						}
					}

					&:last-child {
						margin-top: 60px;
					}

					.pagebuilder-column {
						@include respond-to($screen__s, 'max') {
							width: 100% !important;
							margin: 0 0 20px !important;
						}

						&.heading-block {
							margin-bottom: 60px;

							h2 {
								text-transform: uppercase;
								margin-top: 10px;
							}
						}
					}
				}
			}
		}
	}
}

.cms-slider-carousel {
	.pagebuilder-mobile-hidden {
		@include respond-to(766px, 'max') {
			display: block !important;
		}
	}

	.pagebuilder-mobile-only {
		display: none !important;
	}
}

.has-two-column-with-media {
	max-width: 1220px;
	margin: 0 auto;
	padding: 0 20px;

	@include respond-to(799px, 'max') {
		.pagebuilder-column-line {
			flex-wrap: wrap;

			> .pagebuilder-column {
				width: 100% !important;
				padding: 0 !important;
				margin: 0 !important;

				&:last-child {
					margin-top: 30px !important;
				}
			}
		}
	}
}

// Used Custum rifle page
.has-hero-banner-small {
	margin-bottom: 30px;

	@include respond-to(large) {
		margin-bottom: 97px;
	}

	@include respond-to(medium) {
		margin-bottom: 60px;
	}

	@include respond-to(small) {
		margin-bottom: 40px;
	}

	.pagebuilder-poster-content {
		max-width: 1240px;
		margin: 0 auto;
	}

	h1 {
		@include respond-to(medium) {
			letter-spacing: 1.27px;
		}

		@include respond-to(small) {
			letter-spacing: 0.5px;
		}
	}
}

.full-width-two-column-with-content {
	margin-bottom: 30px;

	@include respond-to(large) {
		margin-bottom: 80px;
	}

	@include respond-to(medium) {
		margin-bottom: 60px;
	}

	@include respond-to(small) {
		margin-bottom: 40px;
	}

	&.no-margin {
		margin: 0;

		@include respond-to(medium, 'max') {
			.pagebuilder-column-line {
				padding: 20px;
			}
		}
	}

	@include respond-to(medium, 'max') {
		.pagebuilder-column-line {
			flex-wrap: wrap;
			padding: 0 20px;
			gap: 30px;
		}
	}

	.pagebuilder-column {
		align-self: center !important;

		@include respond-to(medium, 'max') {
			width: 100% !important;
		}

		img {
			display: flex;
			width: 100%;
			height: 100% !important;
			object-fit: cover;
		}

		[data-content-type="buttons"] {
			margin-top: 20px;

			@include respond-to(large) {
				padding: 0 20%;
			}
			@include respond-to(medium) {
				padding: 0 5%;
			}
		}
	}

	.has-content-block {
		@include respond-to(large) {
			padding: 0 20%;
		}
		@include respond-to(medium) {
			padding: 0 5%;
		}
	}
}

.has-two-column-with-form {
	max-width: 1220px;
	margin: 0 auto;
	padding: 0 20px;

	@include respond-to(999px, 'max') {
		.pagebuilder-column-line {
			flex-wrap: wrap;

			> .pagebuilder-column {
				width: 100% !important;
				padding: 0 !important;
				margin: 0 !important;

				&:last-child {
					margin-top: 30px !important;
					padding: 30px !important;
				}
			}
		}
	}

	.form-privacy {
		margin: 0;
  		text-align: center;

		a {
			color: #333;

			&:hover {
				color: #000;
			}
		}
	}

	.amform-form {
		width: 100%;
		margin: 0;
		z-index: 10;

		.field {
			.radio-group label {
				margin-bottom: 5px;
			}

			.radio-box-design-main-container {
				font-size: 0px;
				margin-top: 20px;

				[type="radio"] {
					+ label {
						display: inline-block;
						padding: 0;
						margin: 0 0 19px 0;
						height: 170px;
						width: calc(100% - 20px / 2);
						background: #fff;

						&.odd {
							margin-right: 20px;
						}

						&:before {
							transform: translate(10px, 10px);
							position: static;
							border: 1px solid #ccc;
							background: #fff;
						}

						.radio-label {
							font-size: 14px;
							position: absolute;
							bottom: 0;
							width: 100%;
							background: #fff;
							padding: 8px 3px;
							text-align: center;
						}

						&:after {
							transform: none;
						}
					}

					&:checked + label:after {
						transform: translate(14px, 14px);
						transform: none;
						top: 0;
						left: 0;
					}
				}
			}

			h2 {
				font-size: 18px;
				font-weight: bold;
				color: #000;
			}

			.level-two-margin-bottom {
				margin-bottom: 24px;
			}

			.amform-text {
				font-size: 18px;
				color: #000;
				line-height: 1.5;
			}

			&.fb-h2 {
				margin-bottom: 22px;
			}

			input, textarea, select {
				color: #000;

				&:focus {
					box-shadow: none;
					border: 1px solid #000;
				}

				&.mage-error {
					border: 1px solid #ed8380 !important;
				}
			}

			select {
				color: #000;
				background: url(../images/icons/arrow-down-black.svg) no-repeat 96% 50%;

				&:hover {
					cursor: pointer;
				}
			}

			.bold-label + label {
				font-weight: bold;
			}

			[type="checkbox"] {
				top: auto;

				+ label {
					margin-bottom: 11px;
					display: inline-block;
				}
			}
		}

		.action.primary {
			font-size: 17px;
			border: none;
			background: #d32700;
			width: 100%;
			padding: 16px;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: bold;
			height: auto;

			&.amcform-next,
			&.amcform-prev,
			&.amcform-submit {
				margin: 15px 0 0 0;
			}

			&:hover {
				background: #ba2200;
			}
		}

		.amform-date {
			border-radius: 0;
			min-height: 45px;
			border: 1px solid #000;
			padding: 0 9px;
			text-align: center;
			color: #000;
			text-transform: uppercase;

			& ~ .ui-datepicker-trigger:before {
				color: #000;
			}
		}

		.form-control {
			height: 45px;
			background: #fff;
		}

		textarea.form-control {
			resize: none;
			min-height: 116px;
		}

		.fieldset {
			&:last-child {
				margin-bottom: 0;
			}

			& > .fields > .field > .label {
				color: #000;
				margin-bottom: 3px;
				font-weight: bold;

				&.fb-checkbox-group-label {
					margin-bottom: 15px;
				}

				&.fb-radio-group-label {
					margin-bottom: 8px;
				}
			}
		}

		.amform-layout-two:not(.editing) {
			max-width: 98%;
		}

		.amcform-page-titles {
			padding: 0;
		}

		.amform-form .fb-number {
			max-width: 200px;
		}

		.amform-date {
			max-width: 155px;
			background: #fff;
		}

		.amcform-title-wrap {
			display: none;
			max-width: 100%;
			min-width: 0;
			width: 100%;
			margin-bottom: 45px;

			&.-done + .amcform-title-wrap:before {
				content: none;
			}

			.amcform-title {
				margin: 0;
				max-width: 100%;
				width: 100%;
			}

			&.ui-state-active {
				display: block;

				& ~ .amcform-title-wrap:before {
					content: none;
				}

				.amcform-label {
					font-size: 25px;
					color: #000;
					letter-spacing: 3px;
					font-weight: bold;
				}
			}
		}
	}
}

.two-column-with-heading {
	margin-bottom: 30px;

	@include respond-to(large) {
		margin-bottom: 80px;
	}

	@include respond-to(medium) {
		margin-bottom: 60px;
	}

	@include respond-to(small) {
		margin-bottom: 40px;
	}

	.pagebuilder-column-line {
		max-width: 1040px;
		margin: 0 auto;
		padding: 0 20px;

		@include respond-to(small, 'max') {
			flex-wrap: wrap;
			gap: 10px;
		}

		.pagebuilder-column {
			align-self: center !important;

			@include respond-to(small, 'max') {
				width: 100%;
			}
		}

		p,
		h2 {
			margin: 0;
		}

		h2 {
			position: relative;

			@include respond-to(small) {
				text-align: right;
				padding-right: 30px;
				margin-right: 30px;
			}

			&::after {
				@include respond-to(small) {
					width: 1px;
					height: 40px;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					background-color: #979797;
					content: '';
				}
			}
		}
	}
}

.has-bottom-testimonial-slider {
	.slick-slider {
		max-width: 820px;
  		margin: 0 auto;

		.pagebuilder-poster-content {
			h2 {
				color: #fff;
			}

			p {
				color: #fff;

				&:last-child {
					color: #bfcad9;
				}
			}
		}
	}
}

.has-product-grid-rifle {
	padding-top: 40px;
	padding-bottom: 40px;

	@include respond-to(large) {
		padding-top: 80px;
		padding-bottom: 120px;
	}

	@include respond-to(medium) {
		padding-top: 70px;
		padding-bottom: 90px;
	}

	@include respond-to(small) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.short-description {
		padding: 0 20px;

		h2 {
			margin-top: 0;
		}

		+ [data-content-type="buttons"] {
			margin-top: 16px;
			margin-bottom: 50px;
		}
	}

	.pagebuilder-column-group {
		max-width: 1280px;
		margin: 0 auto;
		padding: 0 20px;

		&.full-width-card {
			&.has-white-card {
				.pagebuilder-column-line {
					background-color: #fff;
					box-shadow: 0 0 9.3px 5px rgba(0,0,0,0.05);

					.pagebuilder-column {
						.pagebuilder-button-link {
							margin: 0;
							color: #d32700 !important;
							font-size: 16px;
							padding: 0 0 2px;
							font-weight: 700;
							border-bottom: 2px solid #d32700;

							&:hover {
								color: #ba2200 !important;
								border-bottom: 2px solid #ba2200;
							}
						}
					}
				}
			}

			.pagebuilder-column-line {
				gap: 0;
				margin-bottom: 0;
				background-color: #1d1d1d;

				.pagebuilder-column {
					position: relative;
					padding-top: 60px !important;
					padding-bottom: 60px !important;

					@include respond-to(medium, 'max') {
						padding: 30px !important;
						flex-direction: column !important;

						img {
							width: auto;
						}
					}

					[data-content-type="buttons"] {
						position: static;
					}

					figure[data-content-type="image"] {
						@include respond-to(medium, 'max') {
							width: 100%;
						}
					}

					div[data-content-type="text"] {
						@include respond-to(medium, 'max') {
							width: 100%;
						}
					}
				}
			}
		}

		.pagebuilder-column-line {
			gap: 30px;
			margin-bottom: 30px;

			@include respond-to(medium, 'max') {
				flex-wrap: wrap;
				gap: 20px;
				margin-bottom: 20px;
			}

			.pagebuilder-column {
				position: relative;
				padding-bottom: 90px !important;

				@include respond-between(nav_xs, medium) {
					width: 100% !important;
					flex-direction: row !important;
					padding-bottom: 60px !important;
					gap: 30px;
				}

				figure[data-content-type="image"] {
					@include respond-between(nav_xs, medium) {
						width: 200px;
						display: flex;
						align-items: center;
					}
				}

				div[data-content-type="text"] {
					@include respond-between(nav_xs, medium) {
						width: calc(100% - 200px);
					}
				}

				img {
					width: 100%;
				}

				h2 {
					margin: 16px 0 10px;
				}

				[data-content-type="buttons"] {
					position: absolute;
					left: 30px;
					bottom: 30px;

					@include respond-between(nav_xs, medium) {
						left: 250px;
					}
				}

				.pagebuilder-button-link {
					margin: 0;
					color: #fff !important;
					font-size: 14px;
					padding: 0;
					border-bottom: 1px solid #ba2200;

					&:hover {
						border-bottom: 1px solid #fff;
					}
				}
			}
		}
	}
}

.has-faq-accordion {
	padding-top: 40px;
	padding-bottom: 40px;

	@include respond-to(large) {
		padding-top: 120px;
		padding-bottom: 120px;
	}

	@include respond-to(medium) {
		padding-top: 90px;
		padding-bottom: 90px;
	}

	@include respond-to(small) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	[data-content-type="html"] {
		padding: 0 20px;

		@include respond-to(small) {
			padding: 0 30px;
		}
	}

	.section-title {
		text-align: center;
		margin: 0 0 30px;

		@include respond-to(medium) {
			margin: 0 0 60px;
		}

		@include respond-to(small) {
			margin: 0 0 50px;
		}
	}

	.mageprince-faq-loader {
		text-align: center;
		display: none !important;
	}

	.faq-collection {
		max-width: 880px;
  		margin: 0 auto;
		display: block !important;

		.faq-groups {
			display: none;
		}

		.faq-accordion-header {
			border-top: 1px solid #b9b9b9;
			padding: 40px 0 0;
			margin: 40px 0 0;
			font-size: 18px;
			cursor: pointer;
			color: #000;

			&:first-child {
				border-top: none;
				padding: 0;
				margin: 0;
			}
		}

		[data-element="inner"] {
			padding: 0 !important;
		}
	}
}

.has-review-slider-wrapper {
	[data-content-type="heading"] {
		border-bottom: 1px solid #ececec;
		padding: 0 0 10px;

		@include respond-to(large) {
			margin: 30px 15% 0;
		}

		@include respond-to(medium) {
			margin: 30px 5% 0;
		}
	}

	.pagebuilder-column-line {
		@include respond-to(medium, 'max') {
			padding: 0 20px;
			flex-wrap: wrap;

			.pagebuilder-column {
				width: 100% !important;
			}
		}

		.pagebuilder-column {
			img {
				display: flex;
				width: 100%;
				height: 100% !important;
				object-fit: cover;
			}
		}
	}

	.review-slider {
		min-height: unset !important;
		margin-bottom: 30px;

		@include respond-to(large) {
			padding: 0 15%;
		}
		@include respond-to(medium) {
			padding: 0 5%;
		}

		.pagebuilder-slide-wrapper {
			min-height: unset !important;

			.pagebuilder-overlay {
				padding: 0;
				min-height: unset !important;
			}

			.pagebuilder-poster-content {
				h3 {
					margin-bottom: 20px;

					.rating-summary {
						clear: none;
  						display: inherit;

						.rating-result {
							&::before {
								font-size: 30px;
							}

							> span {
								&::before {
									color: currentColor;
									font-size: 30px;
								}
							}
						}
					}
				}

				p {
					margin-bottom: 20px;
					line-height: 1.4;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.slick-dots {
			text-align: left;

			li {
				width: 10px;
				height: 10px;

				&.slick-active button {
					border: 1px solid #d32700;
					background-color: #d32700;
					opacity: 1;
				}

				button {
					border: 1px solid #d32700;
					background-color: #d32700;
					width: 10px;
					height: 10px;
					opacity: 0.3;
				}
			}
		}
	}
}

.two-column-anchor-card {
	margin-bottom: 30px;

	@include respond-to(large) {
		margin-bottom: 80px;
	}

	@include respond-to(medium) {
		margin-bottom: 60px;
	}

	@include respond-to(small) {
		margin-bottom: 40px;
	}

	.pagebuilder-column-group {
		max-width: 1240px;
		margin: 0 auto;
		padding: 0 20px;

		.pagebuilder-column-line {
			gap: 20px;

			@include respond-to(medium, 'max') {
				flex-wrap: wrap;

				.pagebuilder-column {
					width: 100% !important;
					padding: 30px !important;
				}
			}
		}

		h2 {
			margin: 10px 0;
		}

		p {
			font-size: 18px;
			line-height: 1.5;
			text-align: center;

			@include respond-to(medium) {
				font-size: 22px;
			}

			@include respond-to(small) {
				font-size: 20px;
			}
		}

		.pagebuilder-button-link {
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
			border-bottom: 2px solid #D32700;
			padding: 0 0 6px;
			margin: 16px 0 0;
		}
	}
}

.two-column-anchor-content {
	margin-bottom: 30px;

	@include respond-to(large) {
		margin-bottom: 80px;
	}

	@include respond-to(medium) {
		margin-bottom: 60px;
	}

	@include respond-to(small) {
		margin-bottom: 40px;
	}

	.pagebuilder-column-group {
		max-width: 1240px;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px;
	}

	.pagebuilder-column-line {
		gap: 20px;

		@include respond-to(medium, 'max') {
			flex-wrap: wrap;

			.pagebuilder-column {
				width: 100% !important;
				padding: 0 !important;
			}
		}
	}

	.heading {
		font-size: 32px;
		font-weight: 700;
		text-transform: uppercase;

		@include respond-to(large) {
			font-size: 54px;
			letter-spacing: 1px;
		}

		@include respond-to(medium) {
			font-size: 44px;
		}

		@include respond-to(small) {
			font-size: 36px;
		}
	}

	.sub-heading {
		color: #333;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 1.3;
		text-transform: uppercase;

		@include respond-to(large) {
			font-size: 30px;
			letter-spacing: 1px;
		}

		@include respond-to(medium) {
			font-size: 26px;
		}

		@include respond-to(small) {
			font-size: 22px;
		}
	}

	p {
		font-size: 16px;
		line-height: 1.4;

		@include respond-to(small) {
			font-size: 18px;
		}
	}

	.heading,
	.sub-heading,
	.customer-guarantee,
	p,
	ul,
	hr {
		margin: 0 0 20px;

		@include respond-to(small) {
			margin: 0 0 30px;
		}
	}

	hr {
		border-top: 1px solid #333;
	}

	h3 {
		margin: 0 0 16px;

		@include respond-to(small) {
			margin: 0 0 20px;
		}
	}

	ul {
		padding-left: 20px;
	}

	.has-two-column-list {
		display: flex;
		flex-wrap: wrap;
  		gap: 0 10px;
		justify-content: space-between;

		li {
			width: 100%;

			@include respond-to(small) {
				width: 45%;
			}
		}

		&.has-check-mark {
			padding: 0;
			list-style-type: none;

			li {
				background-image: url('../images/check-circle.svg');
				background-repeat: no-repeat;
				background-position: left top;
				background-size: 24px 24px;
				padding-left: 34px;
			}
		}
	}

	.customer-guarantee {
		background-color: #F6F6F6;
		padding: 16px;

		h4 {
			margin: 0 0 10px;
			font-size: 16px;
			font-weight: 700;
			line-height: 1.5;
			letter-spacing: 0.1px;
			background-image: url('../images/icon-verified.svg');
			background-repeat: no-repeat;
			background-position: left 2px;
			background-size: 24px 24px;
			padding-left: 26px;

			@include respond-to(small) {
				font-size: 18px;
			}
		}

		p {
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.2;
			letter-spacing: 0.1px;
		}
	}

    .flip-on-mobile {
        .pagebuilder-column-line {
            @include respond-to(medium, 'max') {
                flex-direction: column-reverse;
            }
        }
    }
}

.has-max-width-40 {
	margin: 0 auto;

	@include respond-to(medium) {
		max-width: 40%;
	}
}

.has-max-width-50 {
	margin: 0 auto;

	@include respond-to(medium) {
		max-width: 50%;
	}
}

.has-max-width-60 {
	margin: 0 auto;

	@include respond-to(medium) {
		max-width: 60%;
	}
}

.has-max-width-70 {
	margin: 0 auto;

	@include respond-to(medium) {
		max-width: 70%;
	}
}

.has-max-width-80 {
	margin: 0 auto;

	@include respond-to(medium) {
		max-width: 80%;
	}
}

.full-width-two-column-content {
	@include respond-to(medium, 'max') {
		.pagebuilder-column-line {
			flex-wrap: wrap;
			gap: 30px;
		}
	}

	.pagebuilder-column {
		align-self: center !important;

		@include respond-to(medium, 'max') {
			width: 100% !important;
		}

		img {
			display: flex;
			width: 100%;
			height: 100% !important;
			object-fit: cover;
		}
	}

	.has-content-block {
		padding: 0 20px 40px;

		@include respond-to(medium) {
			padding: 60px 10% 60px 5%;
		}
	}
}

.max-width-10,
.max-width-20,
.max-width-30,
.max-width-40,
.max-width-50,
.max-width-60,
.max-width-70,
.max-width-80,
.max-width-90,
.max-width-100 {
	max-width: 1480px;
	padding: 0 20px;
	margin: 0 auto;
}

.max-width-10 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 10%;
		}
	}
}
.max-width-20 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 20%;
		}
	}
}
.max-width-30 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 30%;
		}
	}
}
.max-width-40 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 40%;
		}
	}
}
.max-width-50 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 50%;
		}
	}
}
.max-width-60 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 60%;
		}
	}
}
.max-width-70 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 70%;
		}
	}
}
.max-width-80 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 80%;
		}
	}
}
.max-width-90 {
	> * {
		margin: 0 auto;

		@include respond-to(medium) {
			max-width: 90%;
		}
	}
}
.max-width-100 {
	> * {
		max-width: 100%;
		margin: 0 auto;
	}
}

.has-product-features-grid {
	padding-top: 30px;
	padding-bottom: 30px;

	@include respond-to(large) {
        padding-top: 50px;
		padding-bottom: 0;
	}

	@include respond-to(medium) {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	@include respond-to(small) {
		padding-top: 50px;
		padding-bottom: 50px;
	}


	h2 {
		margin-top: 0;
		margin-bottom: 40px;
		color: #000;
		text-align: center;;
		font-size: 30px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
        @include respond-to(small, max) {
            padding: 5px;
        }
	}

	.pagebuilder-column-group {
		max-width: 1220px;
		margin: 0 auto;
		padding: 0 20px;
        @include respond-to(large) {
            padding: 0 35px;
        }

		.pagebuilder-column-line {
			gap: 22px;
			margin-bottom: 30px;

			@include respond-to(medium, 'max') {
				flex-wrap: wrap;
				gap: 20px;
				margin-bottom: 20px;
			}

			.pagebuilder-column {
				position: relative;
				background-color: #F6F6F6;

				@include respond-between(nav_xs, medium) {
					width: 100% !important;
					flex-direction: row !important;
					gap: 30px;
				}

				figure[data-content-type="image"] {
					@include respond-between(nav_xs, medium) {
						width: 200px;
						display: flex;
						align-items: center;
					}
				}

				div[data-content-type="text"] {
					padding: 24px;
				}

				img {
					width: 100%;
				}

				h3 {
					margin: 0 0 10px;
				}
			}
		}
	}
}
