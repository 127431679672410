.alert-bar {
	padding: 10px;
	background-color: color(black-regular);
	text-align: center;

	.alert-content {
		color: color(white-regular);
		font-size: 14px;
		font-weight: 700;
	}

	.alert-link {
		color: color(snow-red);
		text-decoration: underline;

		&:hover {
			color: color(snow-red-hover);
		}
	}
}
