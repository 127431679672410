// sass-lint:disable-all
.category-shooting-school {
	.page-main {
		padding: 0 20px;

		.columns {
			padding-bottom: 0;
		}
	}

	.category-image {
		display: none;
	}

	.category-view {
		> .breadcrumbs-toolbar-wrapper {
			display: none;
		}
	}

	.products-grid.grid {
		.product-items {
			display: flex;
			justify-content: center;

			.product-item {
				text-align: center;

				.product-item-details {
					.product-item-inner {
						display: none;
					}
				}
			}
		}
	}
}

.category-custom-ammo,
.category-custom-cerakoting {
	.page-main {
		width: 100%;
		max-width: 100%;

		.columns {
			padding-bottom: 0;
		}
	}

	.category-view {
		.category-image {
			display: none;
		}

		.breadcrumbs-toolbar-wrapper .breadcrumbs {
			max-width: 1440px;
			margin: 0 auto;
		}

		.banner-and-intro-block {
			background: #0a0a0a;

			.global-page-banner > .row-full-width-inner {
				max-width: 1440px;
			}

			.pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
				align-items: center;
				display: flex;
				justify-content: center;
			}

			.pagebuilder-poster-overlay .pagebuilder-poster-content {
				position: absolute;
				right: 7%;
				top: 44%;
				width: auto;
			}

			.intro-block {
				.intro-block-text {
					justify-content: flex-start;
					display: flex;
					flex-direction: column;
					background-position: left top;
					background-size: cover;
					background-repeat: no-repeat;
					width: 100%;
					margin: 0px;
					align-self: stretch;
					flex: 1 1 auto;
					z-index: 2;
					padding: 125px 30px 125px;

					@include respond-to(large) {
						width: 50%;
						padding: 125px 100px 125px;
					}

					@include respond-to(full) {
						padding: 125px 245px 125px 196px;
					}

					h2 {
						margin-top: 0;
						padding-right: 80px;
						font-size: 26px;
						font-weight: 700;
						text-transform: uppercase;
						margin-bottom: 21.5px;
						color: #fff;
						line-height: 1.5;
						letter-spacing: 2.5px;
					}

					p {
						padding-right: 0;
						opacity: 1;

						span {
							font-size: 18px;
							color: #888f9a !important;
						}
					}

					p:last-child {
						margin-bottom: 1rem;
						margin-top: 0;
					}
				}

				.intro-block-image {
					justify-content: flex-start;
					display: flex;
					flex-direction: column;
					background-position: left top;
					background-size: cover;
					background-repeat: no-repeat;
					background-attachment: scroll;
					width: 50%;
					margin: 0px;
					padding: 0px;
					align-self: stretch;
					width: auto !important;
					z-index: 1;

					figure {
						font-size: 0rem;
						position: relative;
						min-height: 1px;
						text-align: center;

						&::before {
							background: linear-gradient(to left,transparent 20%,#0a0a0a 100%);
							height: 100%;
							width: 100%;
							position: absolute;
							content: '';
						}

						img {
							width: 100% !important;
							max-width: 520px !important;
						}
					}
				}
			}
		}

		.cms-general-information-block {
			.row-full-width-inner {
				margin-left: auto;
				margin-right: auto;
				max-width: 1440px;
				width: 100%;
				padding: 96px 30px 111px;

				@include respond-to(large) {
					padding: 96px 196px 111px;
				}
			}

			.pagebuilder-column-line {
				@include respond-to(large, max) {
					flex-direction: column;
				}
			}

			h2 {
				font-size: 25px;
				text-transform: uppercase;
				margin-bottom: 35px;
				font-weight: 700;
				font-family: 'Muli',sans-serif;
			}

			.general-info-first-col,
			.general-info-second-col {
				margin-right: 74px;
			}

			.general-info-first-col,
			.general-info-second-col,
			.general-info-third-col {
				margin-bottom: 42px;

				@include respond-to(large) {
					width: 33.333%;
				}
			}
		}

		.colors-and-pricing-block + .pagebuilder-column-group .cerakoting-colors {
			margin-top: 16px;
		}

		.cms-general-information-block {
			.cerakoting-colors {
				justify-content: flex-start;
				display: flex;
				margin-top: 16px;
				align-items: center;
				width: auto !important;
				margin-right: 61px;
				flex-direction: row !important;

				figure[data-content-type="image"] {
					display: flex;

					img {
						width: 100%;
						max-width: 19px !important;
						margin-right: 11px;
					}
				}

				h4 {
					margin: 0;
				}
			}
		}

		.cms-slider-block {
			.cms-slider-carousel {
				background-color: #000;
			}

			.cms-slider-inner-container {
				max-width: 1440px;
				margin: 0 auto;
				padding: 0;

				.slick-slide {
					height: 220px;

					> div {
						height: 100%;

						figure {
							position: relative;
							height: 100%;
							cursor: pointer;

							img {
								width: 100%;
								height: 100% !important;
								object-fit: cover;
								opacity: 0.5;
							}

							&:after {
								position: absolute;
								top: 45%;
                       			right: 47%;
								background-image: url('../images/zoom-in.svg');
								width: 30px;
								height: 30px;
								display: none;
								content: '';
								background-size: cover;
							}

							&:hover {
								&:after {
									display: block;
								}
							}
						}
					}
				}

				.slick-next,
				.slick-prev {
					width: 57px;
					height: 90px;
					background: #000;

					&::before {
						border: solid #fff;
						border-width: 0 2px 2px 0;
						display: inline-block;
						padding: 7px;
						content: '';
					}
				}

				.slick-prev {
					left: 0;

					&::before {
						transform: rotate(135deg);
					}
				}

				.slick-next {
					right: 0;

					&::before {
						transform: rotate(-45deg);
					}
				}
			}

			.cms-page-slider-main-image {
				display: none !important;
			}
		}

		.cms-page-accordion-block {
			.row-full-width-inner {
				box-sizing: border-box;
				margin-left: auto;
				margin-right: auto;
				max-width: 1440px;
				width: 100%;
				padding: 96px 0 136px;
			}

			#cms-accordion {
				width: 100%;
				max-width: 1057px;
				margin: 0 auto;
				padding: 0 20px;
			}

			.cms-accordion-main-title {
				font-size: 25px;
				display: inline-block;
				margin-bottom: 76px;
				text-transform: uppercase;
				letter-spacing: 2.5px;
			}

			.title-container {
				font-size: 14px;
				text-transform: uppercase;
				border-bottom: 1px solid #979797;
				font-weight: 700;
				letter-spacing: 0.5px;

				@include respond-to(medium) {
					font-size: 23px;
				}

				&[aria-expanded="true"] > div {
					&::after {
						display: block;
						content: '';
						background: #000;
					}

					&::before {
						display: none;
					}
				}

				&[aria-expanded="false"] > div::after {
					display: block;
				}

				&:nth-of-type(1) {
					border-top: 1px solid #979797;
				}

				> div {
					padding: 46px 20px 46px;
					position: relative;
					cursor: pointer;

					@include respond-to(large) {
						padding: 46px 110px 46px 72px;
					}

					span {
						@include respond-to(large, max) {
							display: block;
						}
					}

					&::before,
					&::after {
						position: absolute;
						content: "";
						position:absolute;
						background:#d32700;
						width: 20px;
						height: 20px;

						@include respond-to(large) {
							width: 26px;
							height: 26px;
						}
					}

					&::before {
						top: calc(50% - 11px);
						right: 24px;
						width: 6px;
						transform: translateX(-50%);

						@include respond-to(large) {
							width: 6px;
							top: calc(50% - 13px);
							right: 57px;
						}
					}

					&::after {
						top: 50%;
						right: 20px;
						height: 6px;
						transform: translateY(-50%);

						@include respond-to(large) {
							height: 6px;
							right: 50px;
						}
					}

					.sub-title {
						font-size: 16px;
						font-weight: 700;
						display: block;
						text-transform: none;

						@include respond-to(large) {
							display: inline-block;
						}
					}
				}
			}

			.content-container {
				padding: 55px 30px 84px;
				border-bottom: 1px solid #979797;
				font-family: 'LatoWeb','Muli',sans-serif;

				@include respond-to(large) {
					padding: 55px 81px 84px;
				}

				.content-description {
					font-size: 20px;
					width: 100%;
					max-width: 611px;
					color: #838a94;
					line-height: 1.5;
					margin-bottom: 60px;
				}

				.content-main {
					margin-bottom: 70px;
				}

				.content-title {
					font-size: 18px;
					display: inline-block;
					font-weight: 700;
					margin-bottom: 14px;
				}

				.content-item {
					padding: 30px 20px;
					font-size: 18px;
					display: flex;
					border-top: 1px solid #979797;

					@include respond-to(large) {
						padding: 30px 40px;
					}
	

					span.content-left {
						flex: 1 1 auto;
						padding-right: 20px;
					}

					.content-right {
						text-align: right;
					}

					strong {
						font-weight: 700;
					}
				}
			}

			.contacts-block {
				margin-top: 25px;
				text-align: center;
				font-family: 'Muli',sans-serif;

				h2 {
					font-size: 18px;
					font-weight: 700;
					margin-bottom: 35px;
				}

				a {
					font-size: 17px;
					display: inline-block;
					position: relative;
					background-color: #d32700;
					margin: 0;
					padding: 11.5px 37px 11.5px 18px;
					width: 100%;
					max-width: 176px;
					letter-spacing: 1px;
					font-weight: 700;
					color: #fff;
					text-transform: uppercase;

					&::after {
						border: solid #fff;
						border-width: 0 2px 2px 0;
						display: inline-block;
						padding: 4px;
						content: '';
						transform: rotate(-45deg);
						position: absolute;
						right: 16px;
						top: 20px;
					}
				}
			}
		}
	}

	.slick-lightbox {
		.slick-next,
		.slick-prev {
			width: 57px;
			height: 90px;
			background: #000;

			&::before {
				border: solid #fff;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 7px;
				content: '';
			}
		}

		.slick-prev {
			left: 0;

			&::before {
				transform: rotate(135deg);
			}
		}

		.slick-next {
			right: 0;

			&::before {
				transform: rotate(-45deg);
			}
		}
	}
}

.category-gunsmithing {
	.page-main {
		width: 100%;
		max-width: 100%;

		.columns {
			padding-bottom: 0;
		}
	}

	.breadcrumbs-toolbar-wrapper .breadcrumbs {
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}

	.category-cms {
		.first-container {
			position: relative;

			.pagebuilder-mobile-only {
				@include respond-to(large, max) {
					height: 300px !important;
				}
			}

			h2 {
				font-size: 28px;
				font-family: 'LatoWebBold','Muli',sans-serif;
				color: #fff;
				position: absolute;
				padding: 18px;
				top: 0;
				bottom: 0;
				margin-top: auto;
				margin-bottom: auto;
				height: 85px;
				letter-spacing: 7px;
				text-align: right;
				border-radius: 0px;
				background: rgba(128, 128, 128, 0.7);
				height: 70px;
				left: 2%;


				@include respond-to(large) {
					left: unset;
					height: 85px;
					right: 130px;
					font-size: 40px;
					letter-spacing: 10px;
				}
			}
		}

		.second-container {
			position: relative;

			@include respond-to(medium, max) {
				background-color: #000;
			}

			[data-content-type="html"][data-appearance="default"] {
				position: relative;
				width: 100%;
				padding: 30px;
				margin-top: auto !important;
				margin-bottom: auto !important;

				@include respond-to(medium) {
					position: absolute;
					width: 460px;
					left: 0;
					top: 0;
					bottom: 0;
					padding: 30px;
				}

				@include respond-to(xlarge) {
					left: 200px;
					padding: 0;
					height: 230px;
				}

				h3 {
					margin-top: 1em;
					font-size: 26px;
					line-height: 40px;
					color: #fff;
					text-transform: uppercase;
					letter-spacing: 5px;
					margin-bottom: 20px;

					@include respond-to(medium) {
						font-size: 20px;
						line-height: 28px;
					}

					@include respond-to(eleven) {
						font-size: 26px;
						line-height: 40px;
					}
				}

				p {
					font-size: 18px;
					color: #838a94;
					line-height: 25px;

					@include respond-to(medium) {
						font-size: 14px;
						line-height: 22px;
					}

					@include respond-to(eleven) {
						font-size: 18px;
						line-height: 25px;
					}
				}
			}
		}

		[data-content-type="row"][data-appearance="contained"] {
			max-width: 1440px;
		}

		.third-container {
			justify-content: flex-start;
			display: flex;
			flex-direction: column;
			background-position: left top;
			background-size: cover;
			background-repeat: no-repeat;
			background-attachment: scroll;
			border-radius: 0px;
			margin: 80px 0px 0px;
			padding: 0 20px !important;

			h3 {
				position: relative;
				font-family: 'LatoWebBold','Muli',sans-serif;
				padding: 24px 20px;
				border-top: 1px solid #161616 !important;
				border-bottom: 1px solid #161616 !important;
				margin: 0;
				margin-top: -1px;
				font-size: 14px;
				line-height: 24px;
				cursor: pointer;

				@include respond-to(medium) {
					padding: 45px 3em;
					font-size: 23px;
					line-height: 32px;
				}

				&.expanded + [data-content-type="block"][data-appearance="default"] {
					display: flex !important;
				}

				&::before,
				&::after {
					position: absolute;
					content: "";
					position:absolute;
					background:#d32700;
					width: 20px;
					height: 20px;

					@include respond-to(medium) {
						width: 26px;
						height: 26px;
					}
				}

				&::before {
					top: calc(50% - 11px);
					right: 24px;
					width: 6px;
					transform: translateX(-50%);

					@include respond-to(medium) {
						width: 6px;
						top: calc(50% - 13px);
						right: 57px;
					}
				}

				&::after {
					top: 50%;
					right: 20px;
					height: 6px;
					transform: translateY(-50%);

					@include respond-to(medium) {
						height: 6px;
						right: 50px;
					}
				}

				&.expanded {
					&::before {
						display: none;
					}
				}
			}

			[data-content-type="block"][data-appearance="default"] {
				display: none;

				.block-static-block {
					width: 100%;
				}
			}

			.wrapper-sub-content {
				justify-content: flex-start;
				display: flex;
				flex-direction: column;
				background-position: left top;
				background-size: cover;
				background-repeat: no-repeat;
				margin: 40px 0px 10px;
				padding: 0 20px !important;

				> [data-content-type="text"][data-appearance="default"] p {
					font-size: 19px;
					margin-bottom: 10px;
				}

				h4 {
					font-size: 18px;
					margin-bottom: 15px;
					margin-top: 60px;
				}

				.pagebuilder-column-group {
					padding: 20px;
					border-top: 1px solid #838a94 !important;
					border-bottom: 1px solid #838a94 !important;
					margin-top: -1px;
					flex-wrap: nowrap;

					@include respond-to(medium) {
						padding: 20px 40px;
					}
				}
			}
		}
	}
}

.category-custom-ammo {
	.category-view {
		.category-cms {
			
			.pagebuilder-column-line {
				@include respond-to(large, max) {
					flex-direction: column;
				}
			}
			
			.banner-and-intro-block {
				.intro-block {
					.row-full-width-inner {
						box-sizing: border-box;
						margin-left: auto;
						margin-right: auto;
						max-width: 1440px;
						width: 100%;
						padding: 68px 50px 68px;

						@include respond-to(large) {
							padding: 134px 118px 133.5px 196px;
						}
					}

					.intro-block-text {
						justify-content: flex-start;
						display: flex;
						flex-direction: column;
						background-position: left top;
						background-size: cover;
						background-repeat: no-repeat;
						width: 100%;
						margin: 0px;
						padding: 0px;
						align-self: stretch;

						@include respond-to(large) {
							width: 50%;
						}
					}

					.intro-block-image {
						justify-content: flex-start;
						display: flex;
						flex-direction: column;
						background-position: left top;
						background-size: cover;
						background-repeat: no-repeat;
						width: 100% !important;
						margin: 0px;
						padding: 0px;
						align-self: stretch;
						padding-left: 120px;
						position: relative;

						@include respond-to(large) {
							width: 50% !important;
						}
					}
				}
			}

			.how-it-works-block {
				.row-full-width-inner {
					box-sizing: border-box;
					margin-left: auto;
					margin-right: auto;
					max-width: 1440px;
					width: 100%;
					padding: 64px 50px 64px 50px;

					@include respond-to(large) {
						padding: 128.5px 160px 128.5px 241px;
					}
				}

				.how-it-works-block-image {
					padding-top: 0;
					display: flex;
					flex-direction: column;
					background-position: left top;
					background-size: cover;
					background-repeat: no-repeat;
					background-attachment: scroll;
					border-style: none;
					border-width: 1px;
					border-radius: 0px;
					width: 100%;
					margin: 0px;
					padding-left: 0px;
					padding-right: 0px;
					padding-bottom: 0px;
					align-self: stretch;

					@include respond-to(large) {
						width: 40%;
						padding-top: 61px;
					}
				}

				.how-it-works-block-text {
					justify-content: flex-start;
					display: flex;
					flex-direction: column;
					background-position: left top;
					background-size: cover;
					background-repeat: no-repeat;
					background-attachment: scroll;
					border-style: none;
					border-width: 1px;
					border-radius: 0px;
					width: 100%;
					margin: 0px;
					padding-top: 0px;
					padding-right: 0px;
					padding-bottom: 0px;
					align-self: stretch;

					@include respond-to(large) {
						width: 60%;
						padding-left: 73px;
					}

					a {
						font-size: 17px;
						position: relative;
						display: inline-block;
						color: #fff;
						padding: 11.5px 39px 11.5px 19px;
						background-color: #d32700;
						font-weight: 700;
						width: 100%;
						max-width: 176px;
						margin-top: 20px;

						&::after {
							border: solid #fff;
							border-width: 0 2px 2px 0;
							display: inline-block;
							padding: 4px;
							content: '';
							transform: rotate(-45deg);
							position: absolute;
							right: 16px;
							top: 20px;
						}
					}
				}
			}
		}
	}
}

.services-product-shooting {
    .products-grid .product-item .product-item-details {
		.product-item-inner {
			display: none;
		}
    }
}
