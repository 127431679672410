// Checkout
// sass-lint:disable-all

.checkout-index-index {

	.page-header {
		height: auto;
		background-color: #000;
		position: relative;
		margin-bottom: 40px;

		.header.content {
			margin: 0;
			width: 100%;
			max-width: 100%;
			display: block;
			height: auto;

			.alert-bar {
				width: 100%;
			}

			.logo-wrapper {
				margin: 0 auto;
			}

			.logo {
				text-align: center;
				margin: 0 auto;
				float: none;
				width: 100%;
				display: block;

				img {
					margin: 12px auto;
					width: 160px;
				}
			}
		}
	}

	.page-main {
		max-width: 1280px;

		@include respond-to('nav_md', 'max') {
			padding-top: 0 !important;
		}
	}

	.columns {
		.column.main {
			.opc-wrapper {
				width: 100%;
				max-width: none;

				@include respond-to('nav_md') {
					width: 60%;
				}
			}

			.opc-summary-wrapper {
				width: 100%;
				max-width: none;

				@include respond-to('nav_md') {
					width: 40%;
				}

				.opc-block-shipping-information {
					.shipping-information-title {
						text-transform: uppercase;
						font-size: 25px;
					}

					.shipping-information-content {
						font-size: 14px;
						line-height: 27px;

						a {
							color: #000;

							&:hover {
								text-decoration: underline;
							}
						}
					}

					.action.action-edit {
						&::before {
							display: none;
						}

						&::after {
							background-image: url('../images/cart-edit-black.svg');
							background-position: center;
							background-repeat: no-repeat;
							background-size: 20px;
							content: '';
							width: 20px;
							height: 20px;
							display: block;
						}
					}
				}
			}

			.opc-sidebar {
				margin: 44px 0 20px;
			}

			.opc-progress-bar {
				margin: 0 0 46px;

				@include respond-to('nav_md', 'max') {
					display: none;
				}
			}

			.opc-estimated-wrapper {
				background: #f4f4f4;
				border-bottom: 1px solid #ccc;
				border-top: 1px solid #ccc;
				margin: -21px -15px 15px;
				padding: 18px 15px;

				.minicart-wrapper {
					.action.showcart {
						&::before {
							color: #fff;
						}

						.counter.qty {
							background-color: transparent;
						}
					}
				}
			}

			.opc-progress-bar-item {
				max-width: 218px;
				width: 100%;

				> span {
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					letter-spacing: .8px;
					text-transform: uppercase;
					color: #000;
				}

				&:first-child::before {
					border-radius: 6px 0 0 6px;
				}

				&._active {
					&::before {
						background: #d32700;
						border-color: #d32700;
					}

					span {
						color: #d32700;
						font-size: 16px;
						line-height: 20px;
						text-align: center;
						letter-spacing: .8px;
						text-transform: uppercase;

						&::before {
							background-color: #d32700;
						}
					}
				}

				&._complete {
					&::before {
						background: #000;
						border-color: #000;
					}

					span {
						color: #000;

						&::before {
							background-color: #000;
						}
					}
				}
			}

			.step-title {
				font-size: 25px;
				border-bottom: 1px solid #d9d9d9;
				font-weight: 400;
				letter-spacing: 2.5px;
				text-transform: uppercase;
				color: #000;
				padding: 0 20px 18px;
				line-height: 1;
				margin-bottom: 32px;
				display: block;

				@include respond-to('nav_md') {
					padding: 0 0 18px;
				}
			}

			.checkout-payment-method {
				.fieldset > .legend + br {
					clear: both;
					display: block;
					height: 0;
					overflow: hidden;
					visibility: hidden;
				}

				hr {
					display: none;
				}

				input[type="checkbox"] + label,
				input[type="radio"] + label {
					padding-top: 0;
				}

				input[type="radio"]:checked + label::after {
					background-color: #d32700;
				}

				.checkout-billing-address .billing-address-details {
					line-height: 27px;
					padding: 0 0 0 23px;
					font-size: 14px;

					a {
						color: #000;

						&:hover {
							text-decoration: underline;
						}
					}
				}

				.checkout-billing-address {
					.primary .action-update {
						color: #fff;
					}
				}

				.payment-option-title {
					.action-toggle {
						color: #000;
						font-size: 14px;
					}
				}

				.payment-option-content {
					.form-discount {
						display: flex;
						width: 100%;
						max-width: 500px;

						@include respond-to(500px, max) {
							flex-wrap: wrap;
						}

						input {
							min-height: 48px;
							max-width: 320px;
							border: 1px solid #000;
							color: #000;
							background-color: #fff;

							@include respond-to(500px, max) {
								max-width: 100%;
								width: 100%;
							}
						}

						.actions-toolbar {
							width: 100%;

							.primary {
								float: left;

								@include respond-to(500px, max) {
									width: 100%;
								}

								.action {
									font-weight: 800;
									line-height: 16px;
									text-align: center;
									letter-spacing: 2px;
									text-transform: uppercase;
									color: #000;
									border: 1px solid #000;
									background: #fff;
									border-radius: 0;
									font-size: 14px;
									padding: 15px 30px;

									&:not(:focus) {
										box-shadow: inset 0 1px 0 0 #fff,inset 0 -1px 0 0 rgba(204,204,204,.3);
									}
								}
							}
						}
					}

					.payment-option-inner {
						margin: 0 0 20px;
						width: 100%;
					}
				}

				.payment-method {
					.fieldset {
						.label {
							margin-bottom: 10px;
							display: block;
						}

						.field {
							margin-bottom: 10px;
						}

						&.ccard > .field .fields.group.group-2 .field {
							width: 50% !important;
						}

						input[type="text"],
						select {
							width: 100%;
							height: 45px;
							border: 1px solid #000;
							background-color: #fff;
							background-position: right 5px top 10px;
							color: #000000;
							font-family: "Muli",Helvetica,Arial,sans-serif;
							font-size: 0.9375rem;
							letter-spacing: 0;
							line-height: 1.1875rem;
						}

						.field.cvv input[type="text"] {
							width: 55px;
						}

						> .field.required > label {
							width: 100%;
							text-align: left;
							margin-bottom: 10px;
						}

						> .field.type .control {
							margin-left: 0;
						}
					}
				}
			}

			.checkout-shipping-method {
				.step-title {
					margin-bottom: 0;
				}
			}

			.shipping-address-item {
				font-size: 14px;
				line-height: 30px;

				&.selected-item {
					border-color: #d32700;

					&::after {
						background: #d32700;
					}
				}

				a {
					color: #000;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			.action-select-shipping-item,
			.action-show-popup {
				box-shadow: inset 0 1px 0 0 #fff,inset 0 -1px 0 0 rgba(204,204,204,.3);
				border-radius: 3px;
				background: #eee;
				border: 1px solid #ccc;
				color: #333;

				&:hover {
					background: #e1e1e1;
				}
			}

			.edit-address-link {
				color: #333;

				&:hover {
					text-decoration: underline;
				}
			}

			.custom-form-body {
				margin-bottom: 90px;

				.label {
					width: 100%;
					text-align: left;
					font-size: 25px;
					border-bottom: 1px solid #d9d9d9;
					font-weight: 400;
					letter-spacing: 2.5px;
					text-transform: uppercase;
					color: #000;
					padding-bottom: 18px;
					line-height: 1;
					margin-bottom: 32px;
					display: block;
				}

				input[type="file"] {
					cursor: pointer;
					opacity: 0;
					overflow: hidden;
					position: absolute;
					visibility: hidden;
					width: 0;
					max-width: 320px;
					border: 1px solid #000;
					color: #000;
				}

				.file-uploader-button {
					background: #eee;
					border: 1px solid #ccc;
					box-sizing: border-box;
					color: #333;
					cursor: pointer;
					display: inline-block;
					font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
					font-size: 14px;
					font-weight: 600;
					line-height: 16px;
					margin: 0;
					padding: 7px 15px;
					vertical-align: middle;
				}

				.file-uploader-summary {
					display: inline-block;
					vertical-align: top;
				}

				.file-uploader-preview,
				.file-uploader-placeholder {
					background: #fff;
					border: 1px solid #cacaca;
					box-sizing: border-box;
					cursor: pointer;
					display: block;
					height: 150px;
					line-height: 1;
					margin: 10px 25px 10px 0;
					overflow: hidden;
					position: relative;
					width: 150px;

					.preview-link {
						display: block;
						height: 100%;

						img {
							bottom: 0;
							left: 0;
							margin: auto;
							max-height: 100%;
							max-width: 100%;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 1;
						}
					}

					.action-remove {
						bottom: 4px;
						cursor: pointer;
						display: block;
						height: 27px;
						left: 6px;
						padding: 2px;
						position: absolute;
						text-decoration: none;
						width: 25px;
						z-index: 2;
						background-color: transparent;
						background-position: center;
						background-repeat: no-repeat;
						background-size: 20px;
						background-image: url('../images/cart-delete-black.svg');

						span {
							display: none;
						}
					}
				}

				.file-uploader {
					.file-uploader-filename {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						max-width: 150px;
						word-break: break-all;
						font-size: 14px;
					}

					.file-uploader-meta {
						color: #9e9e9e;
						font-size: 14px;
					}
				}
			}

			.table-checkout-shipping-method {
				width: 100%;

				input[type="checkbox"],
				input[type="radio"] {
					position: relative;
					width: auto;
					height: auto;
					overflow: auto;
					clip: auto;
				}
			}

			.actions-toolbar {
				margin: 0;

				.action.primary {
					min-width: 292px;
					height: 48px;
					padding: 0 10px;
				}
			}

			.opc-block-summary {
				> .title {
					font-size: 25px;
					color: #000;
					font-weight: 400;
					line-height: 31px;
					letter-spacing: 2.5px;
					text-transform: uppercase;
					padding-bottom: 20px;
				}

				.items-in-cart {
					> .title strong span {
						font-size: 18px;
						font-weight: 400;
						line-height: 23px;
						letter-spacing: 1.8px;
						text-transform: uppercase;
						color: #000;
					}
				}

				.product-item {
					margin: 0;
					border-bottom: 1px solid #d9d9d9;
					padding: 24px 0;
					padding-left: 0;

					.product-item-name {
						font-family: 'LatoWebBold','Muli',sans-serif;
						font-size: 14px;
						line-height: 17px;
						color: #000;
						font-weight: 700;
					}

					.price,
					.details-qty {
						font-size: 14px;
						margin-top: 0;
					}
				}
			}

			.minicart-items {
				.product {
					> .product-image-container {
						width: 142px !important;
						height: auto !important;
					}

					.product-item-details {
						padding-left: 172px;
					}
				}

				.toggle > span {
					font-size: 12px;
					text-decoration: underline;
				}

				.options.active .toggle {
					margin-bottom: 5px;
				}

				.content .item-options {
					font-size: 12px;

					dt {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.modal-popup {
		.ffl-address-title.title {
			font-size: 20px;
			text-transform: uppercase;
			border-bottom: 1px solid #ccc;
			padding-bottom: 10px;
			margin-bottom: 20px;
			width: 100%;
		}

		.modal-footer {
			padding: 30px;

			.action.primary {
				font-size: 16px;
				font-weight: 700;
			}

			.action.secondary {
				border: 0;
				background: transparent;
			}
		}

		&.modal-slide {
			@include respond-to('nav_md', 'max') {
				width: 100%;
				left: 0;
			}
		}

		.fieldset {
			.field {
				margin-bottom: 20px;

				.password-strength-meter {
					font-size: 12px;
				}

				&.street {
					margin-bottom: 0;

					.control {
						.label {
							display: none;
						}
					}
				}

				.field-tooltip {
					.label {
						display: none;
					}

					.field-tooltip-action {
						margin: 7px;
					}
				}
			}

			&.additional_info {
				display: none;
			}

			.field.choice::before {
				display: none;
			}

			.legend {
				margin: 0 0 30px;
				text-align: left;
				width: 100%;
			}

			> .field:not(.choice) {
				> .label {
					width: 100%;
					text-align: left;
					padding: 0 0 10px;
					font-size: 14px;
				}

				> .control {
					width: 100%;
				}
			}

			input[type="email"],
			input[type="password"],
			input[type="tel"],
			input[type="text"],
			select {
				width: 100%;
				height: 45px;
				border: 1px solid #000;
				background-color: #fff;
				background-position: right 5px top 10px;
				color: #000000;
				font-family: "Muli",Helvetica,Arial,sans-serif;
				font-size: 0.9375rem;
				letter-spacing: 0;
				line-height: 1.1875rem;
			}

			.field.choice {
				.label {
					padding-top: 0;
					font-size: 14px;
					font-weight: 500;
				}

				.field-tooltip .label {
					display: none;
				}
			}

			input[type="checkbox"] + label::before {
				background-color: #fff;
			}
		}
	}

	.opc-wrapper {

		.form-login {
			margin-bottom: 30px;
			border-bottom: 1px solid #ccc;
			max-width: 100%;

			.fieldset .note {
				font-size: 13px;

				&:before {
					display: none;
				}
			}
		}

		.field-tooltip {
			display: none;
		}

		.form-shipping-address,
		.form-login {
			.fieldset > .field .label {
				width: 100%;
				text-align: left;
				margin-bottom: 6px;
				font-size: 13px;
				color: #000;
			}

			select,
			input {
				max-width: 320px;
				background: #fff;
			}
		}

		.ffl-address-title {
			font-size: 30px;
			border-bottom: 1px solid #d9d9d9;
			font-weight: 400;
			letter-spacing: 2.5px;
			text-transform: uppercase;
			color: #000;
			padding-bottom: 18px;
			line-height: 1;
			margin-bottom: 32px;
			display: block;
		}
	}

	.opc-payment-additional {
		&.discount-code {
			.form-discount {
				.action {
					background-color: #d32700 !important;
					color: #fff !important;
					box-shadow: none !important;

					&:hover {
						background: #ba2200 !important;
					}
				}
			}
		}

		&.gift-card {
			.amcard-field-block {
				display: flex;

				@include respond-to(500px, max) {
					flex-wrap: wrap;

					.amgcard-datalist-container {
						width: 100%;
						margin-bottom: 10px;

						.amcard-field {
							width: 100%;
						}
					}
				}
			}
			.amcard-field {
				background-color: #fff !important;
				height: 48px;
				width: 250px;
			}

			.amcard-button {
				border-radius: 0 !important;
				background-color: #d32700 !important;
				color: #fff !important;
				box-shadow: none !important;
				padding: 10px 20px;
				border: 1px solid #000;

				@include respond-to(500px, max) {
					width: 100%;
				}

				&:hover {
					background: #ba2200 !important;
				}
			}

			.amcard-check {
				color: #fff !important;
				margin-top: 25px;
			}
		}
	}
}

.checkout-onepage-success {
	.page-wrapper {
		background-color: #0a0a0a;
	}

	.page-main {
		max-width: 1280px;
		padding-bottom: 80px;

		.page-title-wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 68px;
			padding-top: 82px;

			.page-title {
				margin: 0;
				font-size: 30px;
				color: #fff;
				font-weight: 700;
				letter-spacing: 4px;
				text-transform: uppercase;

				@include respond-to('small') {
					font-size: 40px;
					letter-spacing: 12px;
				}
			}

			a {
				color: #d32700;

				&:hover {
					color: #fff;
				}
			}
		}

        #registration {
            color: #fff;
        }
	}

	.checkout-success {
		color: #fff;

		a {
			color: #d32700;

			&:hover {
				color: #fff;
			}
		}

		.action.primary.continue {
			color: #fff;
		}
	}
}

.copyright {
	background: #000;
	border-top: 1px solid #262626;
	padding: 14px;
	color: #fff;
	box-sizing: border-box;
	display: block;
	text-align: center;

	span {
		font-size: 10px;
		line-height: 13px;
		letter-spacing: .5px;
	}
}
