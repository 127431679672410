// My Account
// sass-lint:disable-all

// Craete Account Page
.customer-account-createpassword,
.customer-account-forgotpassword,
.customer-account-login,
.customer-account-create {
	.page-main {
		position: relative;

		&::before {
			display: block;
			width: 100%;
			height: 177px;
			margin-bottom: 75px;
			background-image: url('../images/account_form.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			content: '';
		}

		.page-title-wrapper {
			max-width: 430px;
			margin: 0 auto;
			padding: 0 35px;
			text-align: center;

			.page-title {
				font-size: 26px;
				font-weight: 700;
				line-height: 1.3;
				text-align: center;
				letter-spacing: 2.5px;
				text-transform: uppercase;
				color: #000;
			}
		}
	}

	.columns .column.main {
		max-width: 390px;
		margin: 0 auto;
		padding: 0 35px;

		.form.create.account {
			min-width: inherit;
			width: 100%;

			.actions-toolbar {
				display: flex;
				justify-content: space-between;
				margin: 0 0 60px;

				&::before,
				&::after {
					display: none;
				}

				.secondary a.action {
					border: none;
					padding: 0;
					display: flex;
					align-items: center;

					&:hover {
						background-color: transparent;
					}

					span {
						&::before,
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}

	.fieldset {
		.field {
			margin-bottom: 20px;

			.password-strength-meter {
				font-size: 12px;
			}
		}

		&.additional_info {
			display: none;
		}

		.field.choice::before {
			display: none;
		}

		.legend {
			margin: 0 0 30px;
			text-align: left;
			width: 100%;
		}

		> .field:not(.choice) {
			> .label {
				width: 100%;
				text-align: left;
				padding: 0 0 10px;
				font-size: 14px;
			}

			> .control {
				width: 100%;
			}
		}

		input[type="email"],
		input[type="password"],
		input[type="text"] {
			width: 100%;
			height: 45px;
			border: 1px solid #000;
			background: #fff;
			color: #000000;
			font-family: "Muli",Helvetica,Arial,sans-serif;
			font-size: 0.9375rem;
			letter-spacing: 0;
			line-height: 1.1875rem;
		}

		.field.choice {
			.label {
				padding-top: 0;
				font-size: 14px;
				font-weight: 700;
			}

			.field-tooltip .label {
				display: none;
			}
		}

		input[type="checkbox"] + label::before {
			background-color: #fff;
		}
	}
}

.customer-account-createpassword {
	.column.main {
		margin-bottom: 162px !important;
	}

	.form.password.reset {
		min-width: initial;
		width: auto;

		.actions-toolbar {
			margin-left:0;
		}

		.primary {
			width: 100%;
		}
	}
}

.customer-account-forgotpassword {
	.column.main {
		margin-bottom: 162px !important;
	}

	.fieldset .field.note {
		font-size: 16px;
		font-weight: 400;
		line-height: 27px;
		text-align: center;
		margin-bottom: 30px;
	}

	.form.password.forget {
		min-width: initial;
		width: auto;

		.actions-toolbar {
			margin-left:0;
		}

		.secondary,
		.primary {
			width: 100%;
		}

		.secondary {
			margin-top: 30px;

			.action {
				border: none;

				span {
					padding: 0;
					text-transform: capitalize;

					&:after,
					&::before {
						display: none;;
					}
				}
			}
		}
	}
}

.customer-account-login {
	.login-container {
		.block {
			width: 100%;

			&.block-customer-login {
				margin: 0;
			}

			&.block-new-customer {
				.actions-toolbar {
					margin: 30px 0 60px;

					.primary {
						width: 100%;

						.action.create.primary {
							margin: 0;
							width: 100%;
						}
					}
				}
			}

			.block-title {
				border: none;
			}

			.fieldset.login {
				&::after {
					display: none;
				}
			}

			.actions-toolbar {
				display: flex;
				justify-content: space-between;
				margin: 0 0 60px;

				&::before,
				&::after {
					display: none;
				}

				.secondary a.action {
					border: none;
					padding: 0;
					display: flex;
					align-items: center;
					color: #333;

					&:hover {
						background-color: transparent;
					}

					span {
						&::before,
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}
}

.account {
	&.page-layout-2columns-left {
		.column.main {
			@include respond-to('large') {
				width: 75% !important;
			}
			@include respond-to('nav_md') {
				width: 70% !important;
			}
		}

		.sidebar-main {
			@include respond-to('large') {
				width: 22.3% !important;
			}
			@include respond-to('nav_md') {
				width: 27.3% !important;
			}
		}
	}

	.page-main {
		padding-left: 0;
		padding-right: 0;
	}

	.page-title-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px 60px;
		color: #fff;
		text-transform: uppercase;
		letter-spacing: 8px;
		font-weight: 800;
		width: 100%;
		height: 177px;
		background-image: url('../images/account-dashboard-banner.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;

		@include respond-to('nav_md') {
			justify-content: end;
			text-align: right;
			margin-bottom: 75px;
		}

		.page-title {
			margin: 0;
		}
	}

	.columns {
		max-width: 1280px;
		margin: 0 auto 80px;
		padding: 0 20px;

		.account-nav {
			.account-nav-title {
				position: absolute;
				background: #fff;
				width: 100%;
				height: 50px;
				clip: auto;
				left: 0;
				margin: -45px 0 0;
				padding: 10px 20px;
				line-height: 30px;
				font-size: 16px;
				font-weight: 700;
				border-bottom: 1px solid #ccc;
				cursor: pointer;

				@include respond-to('nav_md') {
					display: none;
				}

				&::before {
					content: 'My Account';
				}

				&::after {
					position: absolute;
					right: 20px;
					top: 15px;
					content: '';
					display: inline-block;
					width: 24px;
					height: 24px;
					background-image: url("data:image/svg+xml,%3csvg height='512' width='512' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M396.6 160l19.4 20.7L256 352 96 180.7l19.3-20.7L256 310.5z'/%3e%3c/svg%3e");
					background-repeat: no-repeat;
					background-position: right top;
					background-size: 24px;
				}
			}

			.account-nav-content {
				display: none;

				&.active {
					display: block;
					position: absolute;
					width: 100%;
					left: 0;
					margin-top: 5px;
				}

				@include respond-to('nav_md') {
					display: block;
				}
			}

			.content {
				background-color: #f3f3f3;
			}

			.item {
				a {
					color: #000;
				}

				&.current strong {
					border-color: #d32700;
				}
			}
		}

		.sidebar-main {
			order: 0;

			@include respond-to('nav_md') {
				order: 1;
			}
		}

		.column.main {
			a {
				color: #000;

				&:hover {
					text-decoration: underline;
				}
			}

			.block:not(.widget) {
				.block-title {
					border-bottom: 1px solid #d9d9d9;
					margin-bottom: 12px;
					margin-top: 3px;
					padding-bottom: 11px;
					letter-spacing: 1px;

					strong {
						text-transform: uppercase;
						font-size: 22px;
					}

					.action {
						letter-spacing: 0;
						font-size: 14px;
						margin-left: 14px;
						font-weight: 700;
						letter-spacing: normal;
						text-decoration: underline;
						text-transform: capitalize;
					}
				}

				.box-title {
					> span {
						font-size: 18px;
					}
				}

				.box-content {
					font-size: 14px;
					line-height: 1.5;
				}

				.block-content {
					@include respond-to('nav_md') {
						display: inherit;
					}

					@include respond-to('nav_xs') {
						display: flex;
					}

					.box {
						@include respond-to('nav_md') {
							width: 48%;
						}

						@include respond-to('nav_xs') {
							width: 50%;
							padding-right: 15px;
						}
					}

					.table-wrapper {
						width: 100%;
					}
				}
			}

			.block {
				margin-bottom: 30px;

				.box-actions {
					.action + .action {
						display: block;

						@include respond-to('large') {
							display: inline-block;
						}

						&::before {
							@include respond-to('large') {
								border-left: 2px solid #4d4d4d;
								content: '';
								display: inline-block;
								height: 12px;
								margin: 0 10px;
								vertical-align: -1px;
							}
						}
					}

					.action {
						font-size: 14px;
						font-weight: 700;
						color: #000;
						text-decoration: underline;
					}
				}
			}

			.form-address-edit,
			.form {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&.form-newsletter-manage {
					.fieldset {
						width: 100%;
					}
				}

				.fieldset {
					width: 100%;

					@include respond-to('nav_md') {
						width: 48%;
					}

					.legend {
						margin-left: 0;
						width: 100%;
						border-bottom: 1px solid #d9d9d9;
						margin-bottom: 20px;
						margin-top: 3px;
						padding-bottom: 11px;
						letter-spacing: 1px;
						text-transform: uppercase;
						font-size: 22px;
					}
				}
			}

			.fieldset {
				.field {
					margin-bottom: 20px;

					.password-strength-meter {
						font-size: 12px;
					}

					&.street {
						margin-bottom: 0;

						.control {
							> .field.primary {
								display: none;
							}
						}
					}
				}

				&.additional_info {
					display: none;
				}

				.field.choice::before {
					display: none;
				}

				.legend {
					margin: 0 0 30px;
					text-align: left;
					width: 100%;
				}

				> .field:not(.choice) {
					> .label {
						width: 100%;
						text-align: left;
						padding: 0 0 10px;
						font-size: 14px;
					}

					> .control {
						width: 100%;
					}
				}

				input[type="email"],
				input[type="password"],
				input[type="tel"],
				input[type="text"],
				select {
					width: 100%;
					height: 45px;
					border: 1px solid #000;
					background-color: #fff;
					background-position: right 5px top 10px;
					color: #000000;
					font-family: "Muli",Helvetica,Arial,sans-serif;
					font-size: 0.9375rem;
					letter-spacing: 0;
					line-height: 1.1875rem;
				}

				.field.choice {
					.label {
						padding-top: 0;
						font-size: 14px;
						font-weight: 500;
					}

					.field-tooltip .label {
						display: none;
					}
				}

				input[type="checkbox"] + label::before {
					background-color: #fff;
				}
			}

			table {
				font-size: 14px;

				> tbody > tr > td {
					border-bottom: 1px solid #d1d1d1;

					@media only screen and (max-width: 639px) {
						&:last-child {
							border-bottom: 1px solid #d1d1d1;
						}
					}
				}


				&.data .col.actions {
					.action {
						margin: 0;

						+ .action {
							display: block;

							@include respond-to('large') {
								display: inline-block;
							}

							&::before {
								@include respond-to('large') {
									border-left: 2px solid #4d4d4d;
									content: '';
									display: inline-block;
									height: 12px;
									margin: 0 10px;
									vertical-align: -1px;
								}
							}
						}
					}
				}
			}

			.order-products-toolbar.toolbar {
				.pager {
					display: flex;
					flex-direction: row-reverse;
				}

				.toolbar-amount {
					font-size: 12px;
					text-transform: uppercase;
					font-weight: 700;
				}

				.limiter {
					margin-right: 20px;

					.limiter-text {
						display: none;
					}

					.limiter-label {
						font-size: 14px;
					}

					.limiter-options {
						border: 1px solid #000;
						background-color: #fff;
						background-position: right 2px top 7px;
						color: #000000;
						font-family: "Muli",Helvetica,Arial,sans-serif;
						font-size: 0.9375rem;
						letter-spacing: 0;
						line-height: 1.1875rem;
					}
				}
			}

			.actions-toolbar {
				display: flex;
				margin: 0;
				width: 100%;

				&::before,
				&::after {
					display: none;
				}

				.action.primary {
					padding: 0 20px;
					font-size: 18px;
					font-weight: 700;

					&:focus {
						background-color: #d32700;
					}
				}

				.secondary a.action {
					border: none;
					padding: 0;
					display: none;
					align-items: center;
					color: #333;
					text-transform: none;
					text-decoration: none;

					&:hover {
						background-color: transparent;
					}

					span {
						&::before,
						&::after {
							display: none;
						}
					}
				}
			}

			.items.order-links {
				display: block;
				margin: 0;
				border: 0;
			}

			.order-links {
				.item {
					line-height: 1;
					min-width: 1px;
					margin: 0 -1px 0 0;
					width: 100%;

					@include respond-to('nav_md') {
						width: auto;
					}

					a {
						display: block;
						padding: 14px 20px;
						font-weight: 400;
						background: #f6f6f6;
						color: #6d6d6d;
						border: 1px solid #ccc;

						&:hover {
							text-decoration: none;
							background-color: #fff;
						}
					}

					strong {
						border: 1px solid #ccc;
						display: block;
						border-bottom: 0;
						margin-bottom: 0;
						padding: 14px 20px;
						font-weight: 400;
					}

					&:nth-last-of-type(1) {
						margin: 0;
					}

					strong,
					a {
						display: flex;
						align-items: center;
						height: 100%;
						text-align: center;
					}
				}
			}

			.order-details-items {
				margin-top: -1px;
				padding: 10px;
				border: 1px solid #ccc;
				margin-bottom: 40px;

				@include respond-to('nav_md') {
					padding: 25px;
				}

				.order-title {
					border-bottom: 1px solid #ccc;
					padding: 0 0 20px;
					text-transform: uppercase;

					> strong {
						font-size: 24px;
						font-weight: 700;
						margin-right: 30px;
					}

					.action.print {
						text-transform: uppercase;
						font-weight: 700;
						display: inline-block;
						font-size: 14px;
					}
				}

				.table-order-items {
					> thead > tr > th {
						text-transform: uppercase;
					}

					> tbody > tr:last-child > td {
						border-bottom: 0;
					}

					> tfoot {
						background: #f3f3f3;

						> tr:first-child td,
						> tr:first-child th {
							border: none;
						}

						> tr:last-child td,
						> tr:last-child th {
							padding-bottom: 20px;
						}

						> tr {
							> th,
							> td {
								padding: 11px 10px;
							}

						}
					}
				}

				.actions-toolbar {
					.action.print {
						text-transform: uppercase;
						font-weight: 700;
						display: inline-block;
						font-size: 14px;
					}
				}
			}

			.block.block-order-details-view {
				.block-content:not(.widget) {
					flex-wrap: wrap;

					@include respond-to('xlarge') {
						display: flex;
					}

					&::before,
					&::after {
						display: none;
					}

					.box {
						@include respond-to('xlarge') {
							width: 25%;
							padding-right: 15px;
						}

						.box-title {
							margin: 0 0 12px;
						}
					}

					.payment-method {
						.table {
							> tbody {
								> tr {
									th {
										padding: 0 8px 8px 0;
									}

									td {
										padding: 0 0 8px;
										border: 0;
									}
								}
							}
						}
					}
				}
			}

			.order-information {
				.order-title {
					font-size: 25px;
					display: block;
					text-transform: uppercase;
					font-weight: 700;
					letter-spacing: 1px;
					margin: 0 0 20px;
				}

				.order-status {
					padding: 5px 10px;
					border: 2px solid #ccc;
					border-radius: 3px;
					display: inline-block;
					margin: 10px 0 0;
					font-size: 14px;
					text-transform: uppercase;
					vertical-align: top;
				}

				.order-date {
					margin: 20px 0 15px;
					font-size: 16px;
				}

				.order-actions-toolbar {
					margin: 0 0 25px;
					width: 100%;

					.actions {
						text-transform: uppercase;
						font-weight: 700;
						font-size: 14px;
						width: 100%;

						.action {
							margin-top: 10px;
							margin-right: 30px;
							display: inline-block;
						}

						.action.print {
							margin-right: 0;

							@include respond-to('nav_md') {
								display: block;
								float: right;
							}
						}
					}
				}
			}
		}
	}
}


//  Magento/blank
.account.page-print {

	.page-main {
		max-width: 1270px;
	}

	.logo-wrapper {
		display: contents;
		max-width: 210px;
		width: 100%;

		.logo {
			display: block;
			float: none;
			text-align: left;
			margin: 0;
			max-width: unset;
			max-height: unset;
		}

		img {
			width: 198px;
			max-height: unset;
			min-width: unset;
			height: auto;
			position: relative;
			top: 5px;
			margin: 0 0 0 20px;
		}
	}

	.mobile-nav-close-toggle {
		display: none;
	}

	.page-title-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		background: none !important;
		color: #000;
		padding: 0 1.25rem;
		margin: 30px 0;
		height: auto;
		letter-spacing: 2px;

		.page-title {
			font-size: 20px;
			display: block;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1px;
			margin: 0 0 20px;
		}

		.order-status {
			border: 2px solid #ccc;
			border-radius: 3px;
			display: inline-block;
			margin: 3px 0 0;
			margin-top: 10px;
			margin-bottom: 20px;
			padding: 5px 10px;
			text-transform: uppercase;
			vertical-align: top;
			font-size: 16px;
		}

		.order-date {
			display: block;
			margin: 0;
		}
	}
}

