
// sass-lint:disable no-important, no-ids
.cms-home {
	.page-main {
		max-width: 100%;
		padding: 0;
	}

	.pagebuilder-slider {
		.pagebuilder-overlay {
			display: flex;
			height: 350px;

			@include respond-to(medium) {
				height: 700px;
			}
		}

		h2 {
			color: color(white-regular);
			font-size: 36px;
		}

		.pagebuilder-poster-content {
			display: flex;
			justify-content: center;

			@include respond-to(medium) {
				justify-content: flex-end;
			}
		}

		#slick-slide01,
		#slick-slide02,
		#slick-slide03 {
			.pagebuilder-overlay {
				align-items: center;

				@include respond-to(xlarge) {
					align-items: flex-start;
				}

				h2 {
					font-weight: 300;
				}
			}
		}
	}

	.three-column-banner {
		h3 {
			position: relative;
			top: -30px;
			margin-top: -37px !important;
			margin-left: 40px !important;
			color: color(white-regular);
			font-size: 28px;
		}

		.image-grid-instock {
			margin-top: -5px;
		}

		.pagebuilder-column-line {
			flex-wrap: wrap;

			.pagebuilder-column {

				@include respond-to(medium,'max') {
					width: 100% !important;
				}

				&:nth-child(1),
				&:nth-child(2) {
					figure {
						width: 100%;
						height: 100%;

						img {
							width: 100%;
							height: 100% !important;
							object-fit: cover;

							@include respond-to(medium,'max') {
								height: 300px !important;
							}
						}
					}
				}

				&:nth-child(3) {
					figure {
						width: 100%;
						height: 100%;

						@include respond-to(medium) {
							height: 50%;
						}

						img {
							width: 100%;
							height: 100% !important;
							object-fit: cover;

							@include respond-to(medium,'max') {
								height: 300px !important;
							}
						}
					}
				}
			}
		}
	}

	.two-column-banner {
		padding: 20px 30px !important;

		@include respond-to(large) {
			padding: 50px 100px !important;
		}

		h2 {
			margin-bottom: 5px;
			color: color(white-regular);
		}

		p {
			margin-bottom: 15px;
			color: #838a94;
		}

		@include respond-to(small,'max') {
			.pagebuilder-column-line {
				flex-wrap: wrap;
			}

			.pagebuilder-column {
				flex-basis: initial !important;
				width: 100% !important;
			}
		}

		.pagebuilder-column {
			padding: 20px 30px !important;

			@include respond-to(medium) {
				padding: 50px 70px !important;
			}
		}
	}
}
