// sass-lint:disable-all

.cms-page-view .columns .column.main {
	.pre-fit-top-content-block {
		@include respond-to($screen__m, 'max') {
			margin-top: 30px !important;
			margin-bottom: 30px !important;
		}

		.row-full-width-inner {
			max-width: 1260px;
			@include respond-to($screen__m) {
				padding: 50px 20px;
			}
			@include respond-to($screen__m, 'max') {
				padding: 0 20px !important;
			}

			.pagebuilder-column-line {
				flex-wrap: wrap;

				.pagebuilder-column {
					@include respond-to($screen__m) {
						width: 50%;
					}

					@include respond-to($screen__m, 'max') {
						width: 100% !important;
						margin: 0 !important;
					}
				}

				.pre-fit-content {
					@include respond-to($screen__m, 'max') {
						margin: 0 0 20px !important;
					}
				}
			}
		}
	}

	.pre-fit-performance {
		@include respond-to($screen__m, 'max') {
			padding-top: 30px !important;
			padding-bottom: 30px !important;
		}
	}

	.pre-fit-customizations {
		@include respond-to($screen__m, 'max') {
			padding-top: 30px !important;
			padding-bottom: 30px !important;
		}

		.row-full-width-inner {
			max-width: 1260px;
			@include respond-to($screen__m, 'max') {
				padding: 0 20px !important;
			}

			.pagebuilder-column-line {
				flex-wrap: wrap;
				.pagebuilder-column {
					@include respond-to($screen__m, 'max') {
						width: 100% !important;
						margin: 0 !important;
					}
				}

				.pre-fit-content {
					@include respond-to($screen__m, 'max') {
						margin: 0 0 20px !important;

						ul {
							padding-left: 15px;
							margin-bottom: 0;
						}
					}

					li {
						color: #ffffff;
					}
				}
			}
		}
	}

	.pre-fit-barrels-class-slider {
		.slick-next,
		.slick-prev {
			width: 57px;
			height: 90px;
			background: #000;

			&::before {
				border: solid #fff;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 7px;
				content: '';
			}
		}

		.slick-prev {
			left: 0;

			&::before {
				transform: rotate(135deg);
			}
		}

		.slick-next {
			right: 0;

			&::before {
				transform: rotate(-45deg);
			}
		}
	}
}

.cms-book-your-consultation-pre-fitbarrels {
	&.page-layout-page-landing {
		.page-header {
			background: #ffffff;
		}
	}
}

.cms-page-view {
	&.page-layout-page-landing {
		.page-wrapper {
			position: relative;
		}
		.page-footer {
			max-width: 1168px;
			margin: auto;
			width: 100%;
			align-items: center;

			> * {
				position: relative;
				z-index: 1;
			}

			&::after {
				background: #000;
				content: "";
				width: 100%;
				position: absolute;
				left: 0;
				height: 72px;
				bottom: 0;
				z-index: 0;
			}

			&::before {
				background: #262626;
				content: "";
				width: 100%;
				position: absolute;
				left: 0;
				height: 1px;
				bottom: 72px;
				z-index: 0;
			}

			img {
				display: block;
			}
		}
	}
}