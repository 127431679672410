// sass-lint:disable-all
/**
 * Copyright © Above The Fray Design, Inc. All rights reserved.
 * See ATF_COPYING.txt for license details.
 */

.catalog-category-view .page-title-wrapper,
.catalogsearch-result-index .page-title-wrapper,
.catalog-category-view .category-description,
.catalogsearch-result-index .category-description {
	display: none;
}

.category-smith-shop {
	.rifles-form-success-confirmation {
		text-align: center;
		color: #000;

		&:before {
			font-size: 50px;
			content: '\e905';
			//font-family: @smr-icons__font-name;
			display: block;
			margin-bottom: 28px;
		}

		.form-success-button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 40px;

			&:hover {
				background: #ba2200;
			}
		}

		h3 {
			font-size: 25px;
			margin-bottom: 24px;
			font-weight: bold;
			letter-spacing: 1px;
		}

		.success-verbiage {
			font-size: 20px;
			display: block;
			padding: 0 30px;
			line-height: 1.5;
			letter-spacing: 1px;
		}
	}

	.custom-rifle-form-testimonial-block {
		background-size: auto !important;
	}

	.form-testimonial-verbiage {
		position: relative;
		line-height: 2;
		letter-spacing: 1px;
		margin: 0 20px;

		p {
			font-family: $font-family__secondary;
		}

		&:before {
			top: 0;
			left: -76px;
			background: url(../images/quote.svg) no-repeat;
			background-size: 50px 32px;
			content: '';
			width: 50px;
			height: 32px;
			display: block;
			margin-bottom: 10px;
		}
	}

	.message {
		margin-bottom: 0 !important;
	}

	.breadcrumbs-toolbar-wrapper {
		display: none;
	}

	.custom-rifles-form-middle-content {
		position: relative;
		background: #0a0a0a !important;
	}

	.custom-rifles-form-main-verbiage {
		padding: 100px 20px;

		h2 {
			font-size: 26px;
			margin-bottom: 30px;
			color: #fff;
			font-weight: bold;
			letter-spacing: 1px;
		}

		p {
			line-height: 1.7;
			opacity: 0.7;
		}
	}

	&.page-products .columns {
		padding: 0;
	}

	.rifles-form-main-container {
		background: #fff;
		display: none;

		&.form-active {
			display: block;
		}

		p {
			display: none;
		}

		.amform-form {
			padding: 80px 95px;
			max-width: 550px;
			width: 100%;
			margin: 0 auto;
			background: #fff;
			z-index: 10;

			.field {
				.radio-group label {
					margin-bottom: 5px;
				}

				.radio-box-design-main-container {
					font-size: 0px;
					margin-top: 20px;

					[type="radio"] {
						+ label {
							display: inline-block;
							padding: 0;
							margin: 0 0 19px 0;
							height: 170px;
							width: calc(100% - 20px / 2);
							background: #fff;

							&.odd {
								margin-right: 20px;
							}

							&:before {
								transform: translate(10px, 10px);
								position: static;
								border: 1px solid #ccc;
								background: #fff;
							}

							.radio-label {
								font-size: 14px;
								position: absolute;
								bottom: 0;
								width: 100%;
								background: #fff;
								padding: 8px 3px;
								text-align: center;
							}

							&:after {
								transform: none;
							}
						}

						&:checked + label:after {
							transform: translate(14px, 14px);
							transform: none;
							top: 0;
							left: 0;
						}
					}
				}

				h2 {
					font-size: 18px;
					font-weight: bold;
					color: #000;
				}

				.level-two-margin-bottom {
					margin-bottom: 24px;
				}

				.amform-text {
					font-size: 18px;
					color: #000;
					line-height: 1.5;
				}

				&.fb-h2 {
					margin-bottom: 22px;
				}

				input, textarea, select {
					color: #000;

					&:focus {
						box-shadow: none;
						border: 1px solid #000;
					}

					&.mage-error {
						border: 1px solid #ed8380 !important;
					}
				}

				select {
					color: #000;
					background: url(../images/icons/arrow-down-black.svg) no-repeat 96% 50%;

					&:hover {
						cursor: pointer;
					}
				}

				.bold-label + label {
					font-weight: bold;
				}

				[type="checkbox"] {
					top: auto;

					+ label {
						margin-bottom: 11px;
						display: inline-block;
					}
				}
			}

			.action.primary {
				font-size: 17px;
				border: none;
				background: #d32700;
				width: 100%;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: bold;
				height: 40px;

				&.amcform-next,
				&.amcform-prev,
				&.amcform-submit {
					margin: 15px 0 0 0;
				}

				&:hover {
					background: #ba2200;
				}
			}

			.amform-date {
				border-radius: 0;
				min-height: 45px;
				border: 1px solid #000;
				padding: 0 9px;
				text-align: center;
				color: #000;
				text-transform: uppercase;

				& ~ .ui-datepicker-trigger:before {
					color: #000;
				}
			}

			.form-control {
				height: 45px;
				background: #fff;
			}

			textarea.form-control {
				resize: none;
				min-height: 116px;
			}

			.fieldset {
				&:last-child {
					margin-bottom: 0;
				}

				& > .fields > .field > .label {
					color: #000;
					margin-bottom: 3px;
					font-weight: bold;

					&.fb-checkbox-group-label {
						margin-bottom: 15px;
					}

					&.fb-radio-group-label {
						margin-bottom: 8px;
					}
				}
			}

			.amcform-page-titles {
				padding: 0;
			}

			.amform-form .fb-number {
				max-width: 200px;
			}

			.amform-date {
				max-width: 155px;
				background: #fff;
			}

			.amcform-title-wrap {
				display: none;
				max-width: 100%;
				min-width: 0;
				width: 100%;
				margin-bottom: 45px;

				&.-done + .amcform-title-wrap:before {
					content: none;
				}

				.amcform-title {
					margin: 0;
					max-width: 100%;
					width: 100%;
				}

				&.ui-state-active {
					display: block;

					& ~ .amcform-title-wrap:before {
						content: none;
					}

					.amcform-label {
						font-size: 25px;
						color: #000;
						letter-spacing: 3px;
						font-weight: bold;
					}
				}
			}
		}
	}
}

.call-to-action-block.custom-rifles-form-call-to-action {
	padding: 100px 20px;
}

@include respond-to($screen__m, 'max') {
    .category-smith-shop .rifles-form-main-container .amform-form .amcform-title-wrap {
        display: none !important;

        &.ui-state-active {
            display: block !important;

            &:after {
                content: none;
            }
        }
    }
}

@include respond-to($screen__s, 'max') {
    .category-smith-shop .rifles-form-main-container form.amform-form {
        padding: 80px 30px;
    }
}


@include respond-to($screen__xs, 'max') {
    .category-smith-shop .rifles-form-main-container form.amform-form {
        .field .radio-box-design-main-container [type="radio"] + label {
            display: block;
            width: 100%;

            &.odd {
                margin-right: 0;
            }
        }
    }
}

@include respond-to($screen__m) {
    .call-to-action-block.custom-rifles-form-call-to-action {
        padding: 120px 20px;
    }

    .category-smith-shop {
        .custom-rifles-form-main-verbiage {
            padding: 100px 50px;
        }

        .form-testimonial-verbiage {
            margin: 0 50px;
        }
    }
}

@include respond-to(900px) {
    .category-smith-shop {
        .custom-rifles-form-main-verbiage {
            padding: 135px 0 135px 50px;
            width: calc(100% - 650px) !important;
        }

        .rifles-form-main-container {
            background: transparent;

            .amform-form {
				box-shadow: 0 0 9px 10px rgba(0, 0, 0, 0.3);
                position: absolute;
                right: 50px;
                top: 141px;
            }
        }

        .form-testimonial-verbiage {
            width: calc(100% - 700px);
            margin-left: 50px;
            margin-right: 0;
        }
    }
}

@include respond-to($screen__l) {
    .call-to-action-block.custom-rifles-form-call-to-action {
        padding: 151px 20px;
    }

    .category-smith-shop {
        .custom-rifles-form-main-verbiage {
            padding: 135px 0 135px 100px;
            width: calc(100% - 760px) !important;
        }

        .rifles-form-main-container .amform-form {
            right: 100px;
        }

        .form-testimonial-verbiage {
            width: calc(100% - 1000px);
            margin-left: 177px;

            &:before {
                position: absolute;
            }
        }
    }
}

@include respond-to($screen__xl) {
    .category-smith-shop {
        .custom-rifles-form-main-verbiage {
            padding: 135px 0 135px 160px;
        }

        .form-testimonial-verbiage {
            margin-left: 273px;
        }
    }

    .form-testimonial-verbiage {
        margin-left: 273px;
    }
}
