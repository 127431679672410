// sass-lint:disable-all

.faq-index-index {
	.page-title-wrapper {
        display: none;
		.page-title {
			margin: 0;
            display: none;
            span {
                display: none;
            }
		}
	}
    .faq-top-banner-container {
        h1 {
            max-width: 1280px;
            letter-spacing: 0.625rem;
            margin: auto;
            text-align: center !important;
            @include respond-to(large) {
                text-align: right !important;
            }
        }
    }

	.page-main {
		padding-left: 0;
		padding-right: 0;
        margin-top: 0;
        @include respond-to(large) {
            margin-top: 75px;
        }
	}

	.columns {
		max-width: 1280px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		flex-direction: column-reverse;
		margin: 0 auto;
		margin-bottom: 78px;

		@include respond-to('nav_md') {
			flex-direction: row-reverse;
		}

		.column.main {
			flex: 1;
			float: none;
			width: auto;
			order: 2;

			@include respond-to('nav_md', 'max') {
				padding-top: 80px;
			}

			.faq-groups {
				display: none;
			}

			.faq-collection {
				margin-bottom: 65px;
			}

			.faq-contact-us-container {
				border-top: 1px solid #d9d9d9;
				padding-top: 74px;

				> span {
					font-size: 18px;
					display: block;
					margin-bottom: 35px;
					padding: 0 8px;
					color: #000;
					font-weight: 700;
				}

				.action.primary {
					position: relative;
					height: auto;
					padding: 6px 30px 12px;
					color: #fff;

					span {
						font-size: 17px;
						letter-spacing: 1px;
						font-weight: 800;
						line-height: 16px;
						text-align: center;
						text-transform: uppercase;
						vertical-align: middle;
					}

					&::after {
						position: relative;
						top: 3px;
						border: solid #fff;
						border-width: 0 2px 2px 0;
						display: inline-block;
						padding: 5px;
						margin-left: 5px;
						transform: rotate(-45deg);
						content: '';
					}
				}
			}

			.faq-content {
				.group-title {
					font-size: 23px;
					border-bottom: 1px solid #d9d9d9;
					margin: 0 0 45px;
					padding-bottom: 15px;
					color: #000;
					text-transform: uppercase;
					line-height: 1.5;
					width: 100%;
					font-weight: 700;
				}

				.faq-accordion {
					.ui-accordion-content,
					.ui-accordion-header {
						display: block !important;
					}

					.faq-accordion-header {
						font-size: 18px;
						border: none;
						margin: 0;
						padding: 0 0 23px;
						background-color: transparent;
						color: #000;
						cursor: pointer;
					}

					.faq-accordion-content {
						border: 0;
						margin-left: 40px;
						margin-bottom: 26px;
						padding: 0;
						line-height: 1.5;

						p {
							font-size: 18px;
							margin: 0;
							color: #000;
						}

						[data-content-type="row"][data-appearance="contained"] [data-element="inner"] {
							padding: 0 !important; //stylelint-disable-line
							margin: 0 !important; //stylelint-disable-line
						}
					}
				}
			}
		}
	}

	&.page-layout-2columns-left .sidebar-additional {
		width: 100%;
		float: none;
		padding-right: 0;

		@include respond-to('nav_md') {
			margin-right: 54px;
			max-width: 250px;
		}

		.faq-menu-list-container {
			@include respond-to('nav_md') {
				display: block !important; //stylelint-disable-line
			}

			@include respond-to('nav_md', 'max') {
				position: absolute;
				left: 0;
				width: 100%;
				margin-top: 50px;
				display: none;
			}

			&.faq-menu-list-container-active {
				display: block;
			}
		}

		.faq-title {
			position: absolute;
			background: #fff;
			width: 100%;
			height: 50px;
			clip: auto;
			left: 0;
			margin: 0;
			padding: 10px 20px;
			line-height: 30px;
			font-size: 16px;
			font-weight: 700;
			border-bottom: 1px solid #ccc;
			cursor: pointer;

			@include respond-to('nav_md') {
				display: none;
			}

			&::after {
				position: absolute;
				right: 20px;
				top: 15px;
				content: '';
				display: inline-block;
				width: 24px;
				height: 24px;
				background-image: url("data:image/svg+xml,%3csvg height='512' width='512' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M396.6 160l19.4 20.7L256 352 96 180.7l19.3-20.7L256 310.5z'/%3e%3c/svg%3e");
				background-repeat: no-repeat;
				background-position: right top;
				background-size: 24px;
			}

			&.faq-title-active {
				&::after {
					transform: rotate(180deg);
				}
			}
		}

		.faq-menu-list {
			padding: 16px 0 32px;
			background-color: #f3f3f3;
			list-style-type: none;

			li {
				padding: 0;
				margin-bottom: 0;

				a {
					font-size: 16px;
					border-left: 3px solid #f3f3f3;
					display: block;
					color: #000;
					padding: 6px 16px;

					&:hover {
						text-decoration: none;
						background-color: #e8e8e8;
						border-left: 3px solid #e8e8e8;
					}
				}
			}
		}
	}
}

.cms-page-view .breadcrumbs {
	display: none;
}

.cms-terms,
.cms-privacy-policy,
.cms-smr-shipping-policy,
.cms-smr-returns-and-exchanges {
	.page-header {
		margin: 0;
	}

	.columns {
		max-width: 100%;
		padding: 0;

		.faq-banner {
			justify-content: flex-start;
			display: flex;
			flex-direction: column;
			background-size: cover;
			background-attachment: scroll;
			border-style: none;
			border-width: 1px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			padding: 0px;
			background-position: left top;
			background-repeat: no-repeat;
			position: relative;

			figure {
				width: 100%;
				height: 233px;
				margin: 0;
				padding: 0;
				border-style: none;

				img {
					height: 100% !important;
					object-fit: cover;
				}
			}
            h1 {
                max-width: 1280px;
                letter-spacing: 0.625rem;
                margin: auto;
                text-align: center !important;
                @include respond-to(large) {
                    text-align: right !important;
                }
                @include respond-to('nav_md', 'max') {
                    text-align: center;
                    height: auto;
                    right: 0;
                    display: flex;
                    align-items: center;
                    left: 0;
                    justify-content: center;
                    font-size: 22px;
                    letter-spacing: 5px;
                }
            }

			h3 {
				text-align: right;
				border: 0;
				margin: 0;
				font-size: 42px;
				letter-spacing: 10px;
				color: #fff;
				text-transform: uppercase;
				font-weight: 700;
				border: none !important;
				position: absolute;
				height: 40px;
				right: 100px;
				bottom: 0;
				top: 0;
				margin-top: auto;
				margin-bottom: auto;

				@include respond-to('nav_md', 'max') {
					text-align: center;
					height: auto;
					right: 0;
					display: flex;
					align-items: center;
					left: 0;
					justify-content: center;
					font-size: 22px;
					letter-spacing: 5px;
				}
			}
		}

        .returns-content, .shipping-content, .services-content, .privacy-content  {
            margin-top: 75px;
            @include respond-to(large) {
                margin-top: 0;
            }
            h3 {
                word-wrap: normal;
            }
        }

		[data-content-type="row"][data-appearance="contained"] {
			box-sizing: border-box;
			margin-left: auto !important;
			margin-right: auto !important;
			max-width: 1280px;
			padding: 0 20px;

			@include respond-to('nav_md') {
				margin-top: 50px;
			}
		}

		.pagebuilder-column-line {
			@include respond-to('nav_md', 'max') {
				flex-wrap: wrap;
			}

			.pagebuilder-column:first-child {
				width: 100%;
				float: none;
				padding-right: 0;

				@include respond-to('nav_md') {
					margin-right: 54px;
					max-width: 250px;
				}

				[data-content-type="row"][data-appearance="contained"] {
					margin: 0;
					padding: 0;
				}

				.faq-menu-list-container {
					@include respond-to('nav_md') {
						display: block !important; //stylelint-disable-line
					}

					@include respond-to('nav_md', 'max') {
						position: absolute;
						left: 0;
						width: 100%;
						margin-top: 50px;
						display: none;
					}

					&.faq-menu-list-container-active {
						display: block;
					}
				}

				.faq-title {
					position: absolute;
					background: #fff;
					width: 100%;
					height: 50px;
					clip: auto;
					left: 0;
					margin: 0;
					padding: 10px 20px;
					line-height: 30px;
					font-size: 16px;
					font-weight: 700;
					border-bottom: 1px solid #ccc;
					cursor: pointer;

					@include respond-to('nav_md') {
						display: none;
					}

					&::after {
						position: absolute;
						right: 20px;
						top: 15px;
						content: '';
						display: inline-block;
						width: 24px;
						height: 24px;
						background-image: url("data:image/svg+xml,%3csvg height='512' width='512' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M396.6 160l19.4 20.7L256 352 96 180.7l19.3-20.7L256 310.5z'/%3e%3c/svg%3e");
						background-repeat: no-repeat;
						background-position: right top;
						background-size: 24px;
					}

					&.faq-title-active {
						&::after {
							transform: rotate(180deg);
						}
					}
				}

				.faq-menu-list {
					padding: 16px 0 32px;
					background-color: #f3f3f3;
					list-style-type: none;

					li {
						padding: 0;
						margin-bottom: 0;

						a {
							font-size: 16px;
							border-left: 3px solid #f3f3f3;
							display: block;
							color: #000;
							padding: 6px 16px;

							&:hover {
								text-decoration: none;
								background-color: #e8e8e8;
								border-left: 3px solid #e8e8e8;
							}
						}
					}
				}
			}

			.pagebuilder-column {
				> h3 {
					font-size: 23px;
					border-bottom: 1px solid #d9d9d9;
					margin: 0 0 45px;
					padding-bottom: 15px;
					color: #000;
					text-transform: uppercase;
					line-height: 1.5;
					width: 100%;
					font-weight: 700;
				}
			}
		}
	}
}
