// sass-lint:disable no-important

.column.main {
	.cart-summary .amexfee-block-content.-cart {
		border-bottom: 1px solid #e99380;
		margin-bottom: 10px;
	}
}

.amexfee-block-content {
	> .title.amexfee-title {
		& {
			position: relative;
			padding: 10px 40px 10px 0;
			text-decoration: none;
			font-weight: 600;
			cursor: pointer;
		}

		&:after {
			font-size: 1.875rem;
			font-family: 'icons-blank-theme';
			content: '\e622' ;
			position: absolute;
			right: 0px;
			top: 0px;
			font-weight: normal;
			transition: all 0.3s ease-out;
		}
	}

	&.active > .title.amexfee-title:after {
		transform: rotate(180deg);
	}
}

.amexfee-block-content.-cart {
	> .title.amexfee-title {
		& {
			margin-bottom: 0;
			border-top: 1px solid #ccc;
		}
	}
}

.amexfee-block-content.-checkout > .title.amexfee-title {
	margin-bottom: 10px;
	border-bottom: 1px solid #ccc;
	font-weight: 300;
	font-size: 18px;
}

.amexfee-item-element._required .amexfee-title:after {
	margin: 0 0 0 5px;
	color: #e02b27;
	content: '*';
	font-size: 1.2rem;
}

.amexfee-form-container {
	> .amexfee-fieldset .amexfee-title {
		margin-bottom: 5px;
		font-weight: 700;
	}

	.amexfee-input {
		float: left;
	}

	.amexfee-input + .amexfee-label {
		display: block;
		overflow: hidden;
		margin: 0;
	}
}

.amexfee-collapsible-block {
	& {
		cursor: pointer;
	}

	// Magento styles override
	> .amount.amexfee-amount,
	.opc-block-summary & > .amount.amexfee-amount,
	.opc-block-summary & > .mark.amexfee-mark,
	> .mark.amexfee-mark {
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}

	> .amount.amexfee-amount,
	.opc-block-summary & > .amount.amexfee-amount {
		position: relative;
		padding-right: 20px;
	}

	> .amount.amexfee-amount:after,
	.opc-block-summary & > .amount.amexfee-amount:after {
		font-size: 40px;
		font-family: 'icons-blank-theme';
		content: '\e622' ;
		position: absolute;
		right: -12px;
		top: -8px;
		font-weight: normal;
		transition: all 0.3s ease-out;
	}

	&.-expanded > .amount.amexfee-amount,
	&.-expanded > .mark.amexfee-mark {
		border-bottom: 0 !important;
	}

	&.-expanded > .amount.amexfee-amount:after,
	.opc-block-summary &.-expanded > .amount.amexfee-amount:after {
		transform: rotate(180deg);
		transition: all 0.3s ease-out;
	}
}

.opc-block-summary .amexfee-block-content .fieldset .label.amexfee-title {
	width: 100%;
	text-align: left;
	float: none;
	margin: 0 0 0.5rem;
}

.amexfee-totals-details {
	& {
		display: none;
	}

	&.-show {
		display: table-row;
		border-bottom: 1px solid #ccc;
	}
}
