// sass-lint:disable-all
@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/muli/extralight/muli-extralight',
    $font-weight: 200,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/muli/light/muli-light',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/muli/regular/muli',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/muli/semibold/muli-semibold',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/muli/bold/muli-bold',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/muli/extrabold/muli-extrabold',
    $font-weight: 800,
    $font-style: normal
);

// Lato
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
