// sass-lint:disable-all

.cms-page-view .columns .column.main {
	.classes-top-content-block {
		.row-full-width-inner {
			max-width: 1120px;
			padding: 50px 20px;

			.pagebuilder-column-line {
				flex-wrap: wrap;

				.pagebuilder-column {
					width: 100%;
	
					@include respond-to($screen__s) {
						width: 50%;
					}
				}
			}
		}
	}

	.reserve-class-spot {
		max-width: 1240px;
		margin: 0 auto;
		padding: 50px 20px;

		.pagebuilder-column-line {
			flex-wrap: wrap;

			.pagebuilder-column {
				@include respond-to($screen__m) {
					width: 50%;
				}
	
				@include respond-to($screen__m, 'max') {
					width: 100% !important;
					margin: 0 0 20px !important;
				}
			}
		}
	}

	.classes-discount-cta {
		max-width: 1000px;
		width: calc(100% - 40px);
		margin: 30px 20px;

		@include respond-to($screen__l) {
			width: 100%;
			max-width: 1000px;
			margin: 50px auto;
		}

		@include respond-to($screen__m, 'max') {
			.pagebuilder-column-group {
				min-height: auto !important;
				padding: 0 20px !important;
				background-position: left center !important;
			}
		}

		.pagebuilder-column-line {
			flex-wrap: wrap;

			.pagebuilder-column {
				@include respond-to($screen__m) {
					width: 50%;
				}
	
				@include respond-to($screen__m, 'max') {
					width: 100% !important;
					margin: 0 !important;
					padding: 50px 0 !important;

					&:first-child {
						display: none !important;
					}
				}
			}
		}
	}

	.classes-table-blocks {
		.row-full-width-inner {
			max-width: 1040px;
			padding: 0 20px;

			@include respond-to($screen__s, 'max') {
				table tbody {
					tr {
						display: grid;
						width: 100%;

						td {
							width: 100% !important;
						}
					}
				}
			}
		}
	}

	.classes-meet-blocks {
		.row-full-width-inner {
			max-width: 1040px;
			padding: 20px 20px 40px;
			
			@include respond-to($screen__s) {
				padding: 50px 20px 100px;
			}

			.pagebuilder-column-line {
				@include respond-to($screen__s, 'max') {
					flex-wrap: wrap;
				}
	
				.pagebuilder-column {		
					@include respond-to($screen__s, 'max') {
						width: 100% !important;
						margin: 0 0 20px !important;
					}
				}
			}
		}
	}

	.classes-discover-blocks {
		.row-full-width-inner {
			max-width: 1120px;
			padding: 20px 20px 40px;

			@include respond-to($screen__s) {
				padding: 50px 20px 100px;
			}

			.pagebuilder-column-group {
				.pagebuilder-column-line {
					@include respond-to($screen__s, 'max') {
						flex-wrap: wrap;
						text-align: center;
					}
		
					.pagebuilder-column {		
						@include respond-to($screen__s, 'max') {
							width: 100% !important;
							margin: 0 0 20px !important;
						}
					}
				}
			}
		}
	}
}