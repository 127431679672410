$colors: (
	snow-red: rgb(211, 39, 0),
	snow-red-hover: rgb(186, 34, 0),
	grey-regular: rgb(238, 238, 238),
	grey-dark: rgb(56, 56, 56),
	white-regular: rgb(255, 255, 255),
	black-regular: rgb(0, 0, 0),
	black-light: rgb(21, 21, 21),
	black-light-transparent: rgba(238, 238, 238, 0.1),
	black-light-transparent-opaque: rgba(238, 238, 238, 0.5),
	black-dark-transparent: rgba(0, 0, 0, 0.04),
	black-dark-transparent-opaque: rgba(0, 0, 0, 0.5),
	dark-charcoal: #333,
	dark-gray: #979797,
)
