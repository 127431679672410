.page-header {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 82px;
	margin-bottom: 0;
	border-bottom: 2px solid #f3f3f3 !important; // sass-lint:disable-line no-important
	background: color(white-regular);

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 82px;
		padding: 0 20px;

		&::before {
			display: none !important; // sass-lint:disable-line no-important
		}

		&.content {
			position: static;
			padding: 0;

			@include respond-to(nav_md) {
				padding: 0 20px;
				max-width: 1480px;
			}

			&::after {
				display: none;
			}
		}

		.nav-toggle {

			@include respond-to(nav_md, max) {
				display: block;
				position: absolute;
				top: inherit;
				right: 0;
				left: inherit;
				width: 80px;
				height: 82px;
				background: #000;

				&::before {
					position: absolute;
					top: 50%;
					left: 50%;
					color: #fff;
					font-size: 32px;
					translate: -50% -50%;
				}
			}

			&.non-active {
				display: none;
			}
		}

		.mobile-nav-close-toggle {
			position: absolute;
			z-index: 99;
			left: 0;
			display: none;
			width: 80px;
			height: 82px;
			background-color: #d32700;
			cursor: pointer;

            @include respond-to(nav_xs, 'max') {
                width: 60px;
                height: 60px;
            }

			> span {
				font-size: 0;
			}

			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 25px;
				height: 25px;
				background-image: inline-svg('#{config(icons_output)}/cancel-icon.svg', (path: (fill: #fff)));
				background-repeat: no-repeat;
				background-position: center;
				background-size: 25px;
				content: '';
				translate: -50% -50%;
			}

			&.active {
				display: block;
			}
		}

		.logo-wrapper {
			display: flex;
			align-items: center;
			width: 150px;

			@include respond-to(xlarge) {
				width: 200px;
			}

			@include respond-to(xlarge, max) {
				order: -1;
				justify-content: center;
			}
		}

		.logo {
			margin-block-start: 0;
			margin-block-end: 0;

			@include respond-to(nav_md, max) {
				margin-inline-start: 0;
			}

			img {
				width: 150px;
				height: auto;
				object-fit: cover;

				@include respond-to(nav_md) {
					width: 200px;
				}
			}
		}
	}

	.main-nav {
		width: calc(100% - 150px);
		height: 100%;
		margin-block-end: 0;

		@include respond-to(nav_md) {
			width: calc(100% - 200px);
		}
        @include  respond-to(nav_xs, max) {
            width: calc(100% - 60px) !important;
        }

		@include respond-to(nav_md, max) {
			position: fixed;
			z-index: 1000;
			top: 0;
			left: 100%;
			width: calc(100% - 80px);
			height: 100vh;
			box-shadow: 0 0 5px 0 rgba(50,50,50,0.75);
			background-color: color(white-regular);
			transition: left 0.3s ease-out;
			padding-block: 20px;
			padding-inline: 32px;
		}

		@include respond-between(nav_md, xlarge) {
			width: calc(100% - 200px);
			border-bottom: 2px solid #f3f3f3;
			background: #fff;
		}

		&.active {
			left: 80px;
            @include respond-to(nav_xs, 'max') {
                left: 60px;
            }
		}

		.mobile-logo {
			display: flex;
			justify-content: center;
			padding-block-end: 15px;

			@include respond-to(nav_md) {
				display: none;
			}
		}

		.main-menu {
			display: flex;
			flex-direction: row;
			height: 100%;
			padding: 0;
            column-gap: 16px;
			margin-block-end: 0;
			justify-content: flex-end;

            @include respond-to(xlarge) {
                column-gap: 30px;
            }

			@include respond-to(nav_md, max) {
				display: block;
				height: auto;
				border-top: 1px solid #e8e8e8;
				border-bottom: 1px solid #e8e8e8;
				margin-block-end: 24px;
				padding-block: 16px;
				padding: 0;
			}

			@include respond-between(nav_md, large) {
				column-gap: 16px;
			}

			@include respond-between(xlarge, full) {
				column-gap: 26px;
			}
		}

		.menu-item-top-level {
			display: flex;
			align-items: center;
			margin: 0;
			transition: 0.3s all ease-in-out;
			white-space: nowrap;


			&.menu-red-button {
				> .menu-item-link {
					background: #d32700;
					color: #fff;
					padding: 16px;

					@include respond-to(eleven) {
						padding: 16px 30px;
					}

					@include respond-between(nav_md, eleven) {
						padding: 10px;
					}

					&:hover {
						background: #ba2200;
					}
				}
			}

			&.contact {
				@include respond-to(nav_md) {
					display: none;
				}
			}

			&.menu-item-has-children {

				> .menu-item-link {
					width: 100%;

					&::after {
						position: absolute;
						top: 50%;
						right: 0;
						display: block;
						width: 16px;
						height: 16px;
						opacity: 0.3;
						background-image: inline-svg('#{config(icons_output)}/chevron-right.svg', (path: (fill: #000)));
						background-repeat: no-repeat;
						background-position: right;
						background-size: contain;
						content: '';
						translate: 0 -50%;

						@include respond-to(nav_md) {
							display: none;
						}
					}

					@include respond-to(nav_md, max) {
						position: relative;
					}

					&.active {
						position: absolute;
						z-index: 99;
						top: 0;
						padding: 24px 0 24px 40px;
						background: #fff;

						&::before {
							position: absolute;
							top: 50%;
							left: 0;
							display: block;
							width: 16px;
							height: 16px;
							opacity: 0.3;
							background-image: inline-svg('#{config(icons_output)}/chevron-left.svg', (path: (fill: #000)));
							background-repeat: no-repeat;
							background-position: right;
							background-size: contain;
							content: '';
							translate: 0 -50%;
						}

						&::after {
							display: none;
						}
					}
				}
			}

			@include respond-to(nav_md, max) {
				padding: 13px 4px;
			}

			@include respond-to(nav_md) {
				&:hover {

					> .menu-item-link {
						border-color: color(snow-red);
					}

					.sub-menu-level-1 {
						z-index: 4;
						clip-path: inset(-4px);
					}
				}

				&.active {
					> .menu-item-link {
						border-color: color(snow-red);
					}
				}
			}

			> .menu-item-link {
				border-bottom-width: 2px;
				border-bottom-style: solid;
				border-color: transparent;
				color: color(dark-charcoal);
				font-size: 15px;
				font-weight: 800;
				letter-spacing: 0.8px;
				text-transform: uppercase;

				@include respond-to(nav_md) {
					letter-spacing: 0.5px;
				}

				@include respond-between(eleven, full) {
					font-size: 14px;
				}

				@include respond-between(nav_md, eleven) {
					font-size: 13px;
				}
			}
		}

		.block-account {
			display: block;

			.block-title {
				position: relative;
				display: block;
				padding-left: 27px;
				color: #333;
				font-size: 12px;
				font-weight: 700;
				letter-spacing: 0.7px;
				text-transform: uppercase;
				transition: 0.3s all ease-in-out;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					display: inline-block;
					width: 16px;
					height: 16px;
					background-image: inline-svg('#{config(icons_output)}/user-icon.svg', (path: (fill: #333)));
					background-repeat: no-repeat;
					background-position: left;
					background-size: 13px;
					content: '';
				}
			}

			@include respond-to(nav_md) {
				display: none;
			}
		}
	}
}

// sass-lint:disable-all

.page-layout-page-landing {
	.page-header {
		border: none !important;
		background-color: #000;

		.header {
			justify-content: center;

			.logo {
				margin: 0;
			}
		}
	}

	.page-footer {
		border-top: 1px solid #262626;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;

		.copyright {
			padding: 0;
			border-top: none;
			font-size: 14px;
			letter-spacing: 0.01875rem;
			line-height: 0.8125rem;
			text-align: left;

			span {
				font-size: 14px;
			}
		}

		div[data-content-type='row'] {
			margin: 0 !important;

			img {
				max-height: 32px;
			}
		}
	}
}
