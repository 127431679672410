// sass-lint:disable no-important

a,
button,
div {

	&.action.primary,
	&.pagebuilder-button-primary,
	&.pagebuilder-button-secondary,
	&.pagebuilder-button-link {
		position: relative;
		padding: 7px 25px;
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;
		cursor: pointer !important;
		transition: 0.2s all ease-in-out;

		span {
			vertical-align: text-top;
		}
	}

	&.action.primary,
	&.pagebuilder-button-primary,
	&.pagebuilder-button-secondary,
	&.pagebuilder-button-link {
		border: 0;
		border-radius: 0;

		&:hover {
			border: 0;
		}

	}

	&.action.primary,
	&.pagebuilder-button-primary {
		border-color: color(snow-red);
		background-color: color(snow-red);
		color: color(white-regular);

		&:active,
		&:hover {
			border-color: color(grey-dark);
			background-color: color(snow-red-hover);
			color: color(white-regular);
		}
	}

	&.action.primary,
	&.pagebuilder-button-primary,
	&.pagebuilder-button-secondary {
		border-width: 1px;
		border-style: solid;
		border-color: transparent;

		&:hover {
			border-width: 1px;
			border-style: solid;
			border-color: transparent;
		}
	}

	&.pagebuilder-button-secondary {
		padding-right: 55px;
		border-color: color(black-regular);
		background-color: color(black-regular);
		color: color(white-regular);

		&:hover {
			border-color: color(grey-regular);
			background-color: color(grey-regular);

			&::after {
				right: 20px;
				background-color: color(grey-dark);
				background-image: inline-svg('#{config(icons_output)}/triangle.svg', (polygon: (fill: #ffffff)));
			}
		}

		&::after {
			position: absolute;
			top: 50%;
			right: 25px;
			width: 16px;
			height: 16px;
			transform: translateY(-50%);
			border-radius: 8px;
			background-color: color(snow-red);
			background-image: inline-svg('#{config(icons_output)}/triangle.svg', (polygon: (fill: #000000)));
			background-repeat: no-repeat;
			background-position: 5px 4px;
			background-size: 8px 8px;
			content: '';
			transition: 0.2s all ease-in-out;
		}

		&.pagebuilder-button-white,
		&.pagebuilder-button-black {
			padding-top: 12px;
			padding-bottom: 12px;
			border-width: 1px;
			border-style: solid;
			font-weight: 600;
		}

		&.pagebuilder-button-white {
			border-color: color(black-regular);
			background-color: color(white-regular);
			color: color(black-regular);

			&:hover {
				border-color: color(snow-red);
				background-color: color(snow-red);
				color: color(white-regular);

				&::after {
					background-color: color(white-regular);
					background-image: inline-svg('#{config(icons_output)}/triangle.svg', (polygon: (fill: color(snow-red))));
				}
			}
		}

		&.pagebuilder-button-black {
			border-color: color(white-regular);
			background-color: color(black-regular);
			color: color(white-regular);

			&:hover {
				border-color: color(snow-red);
				background-color: color(snow-red);
				color: color(white-regular);

				&::after {
					background-color: color(white-regular);
					background-image: inline-svg('#{config(icons_output)}/triangle.svg', (polygon: (fill: color(snow-red))));
				}
			}
		}
	}

	&.pagebuilder-button-link {
		padding-top: 9px;
		border-color: transparent;
		color: color(black-regular) !important;

		&:hover {
			border-color: transparent;
			color: color(snow-red) !important;
			text-decoration: none;
		}
	}
}
