// sass-lint:disable-all

//
//  Common
//  _____________________________________________

.filter {
    .filter-content {
        color:#000;

        .filter-options {
            @include respond-to(767px, max) {
                display: none;
            }
        }
    }

    .filter-actions a {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &.block {
        margin-bottom: 0;
    }

    .filter-title {
        background-color: #161616;
        padding: 20px 20px 20px;
        text-align: center;
        margin-top: -1px;

        strong {
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 3px;
            line-height: 16px;
            padding: 7px 10px;
            text-align: center;
            z-index: 2;
            color: #fff;
            background-color: transparent;
            text-transform: uppercase;
            font-weight: bold;

            &[data-count]:after {
                color: #fff;
                background: #000;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 10px;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: .5;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .block-subtitle {
        padding-bottom: 45px;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    .filter-subtitle {
        display: none;
    }

    .filter-current {
        margin: 0;

        .block-subtitle {
            border-bottom: 1px solid #e8e8e8;
        }

        .items {
            padding: 24px 10px;
        }

        .item {
            font-size: 16px;
            padding-left: 17px;
            position: relative;
            z-index: 1;
        }

        .filter &-subtitle {
            border-bottom: 1px solid #000;
            display: block;
            padding-bottom: 45px;

            &:hover {
                cursor: pointer;
            }
        }

        .filter-label {
            display: inline;
        }

        .action.remove {
            left: -2px;
            position: absolute;
            top: -1px;

            &::before {
                -moz-osx-font-smoothing: grayscale;
                width: 10px;
                height: 10px;
                font-size: 12px;
                line-height: 15px;
                color: #757575;
                background-image: inline-svg('#{config(icons_output)}/close.svg', (path: (fill: #757575)));
                content: '';
                font-family: 'luma-icons';
                vertical-align: middle;
                display: inline-block;
                font-weight: 400;
                overflow: hidden;
                text-align: center;
            }

            > span {
                border: 0;
                clip: rect(0,0,0,0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
    }

    .filter-actions {
        margin-bottom: 10px;
        padding: 0 10px;

        a {
            color: #000;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .filter-label {
        font-weight: 800;

        &:after {
            content: ': ';
        }
    }

    .filter-value {
        color: #757575;
    }

    .filter-options {
        margin: 0;

        .filter-options-item {
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 24px;
        }

        .filter-options-title {
            z-index: 1;
            margin: 0;
            overflow: hidden;
            padding: 24px 60px 0 5px;
            position: relative;
            text-transform: uppercase;
            font-size: 16px;
            word-break: break-all;
            cursor: pointer;
            font-weight: bold;

            &:after {
                position: absolute;
                right: 30px;
                top: 24px;
                width: 14px;
                height: 4px;
                background: #000;
                content: '';
            }

            &::before {
                position: absolute;
                right: 35px;
                top: 19px;
                width: 4px;
                height: 14px;
                background: #000;
                content: '';
            }
        }

        .active  {
            .filter-options-title {
                &::before {
                    display: none;
                }
            }
        }

        .filter-options-content {
            margin: 0;
            padding: 18px 6px 0;

            .item {
                font-size: 16px;
                color: #000;
                line-height: 1.5em;
                margin: 10px 0;
            }

            .count {
                display: none;
            }

            a {
                font-size: 16px;
                color: #000;

                .count {
                    display: none;
                }

                &:hover {
                    text-decoration: underline;

                    & + .count {
                        background-color: #000;
                    }
                }
            }

            .count {
                color: #000;
                font-weight: 300;
                padding-left: 5px;
                padding-right: 5px;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include respond-to($screen__m, 'max') {
    body {
        &.filter-active {
            .page-header {
                display: none;
            }

            .page-main {
                z-index: 1;
            }

            .columns {
                z-index: 999;
            }
        }
    }

    .filter {

        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: 20px;
            }

            .filter-options-title {
                padding: 24px 60px 0 20px;

            }

            .filter-title {
                border-bottom: 1px solid #ccc;
                height: 48px;
                left: 0;
                right: 0;
                top: 0;
                z-index: 2;

                strong {
                    font-size: 0;
                    box-shadow: none;
                    background: none;
                    border: 0;
                    color: transparent;
                    position: fixed;
                    left: auto;
                    right: 35px;
                    top: 22px;

                    &:hover {
                        cursor: pointer;
                    }
                    &:active,
                    &:focus ,
                    &:focus-visible,
                    &:active{
                        box-shadow: none;
                        outline:none
                    }

                    &:before {
                        display: block;
                        content: '\2715';
                        font-size: 20px;
                        color: #fff;
                        position: absolute;
                    }
                    &:after {
                        display: block;
                        content: '';
                        border-radius: 50%;
                        padding: 8px;
                        width: 30px;
                        height: 30px;
                        border: 2px solid #fff;
                        color: #fff;
                        position: absolute;
                        top: 1px;
                        left: 2px;
                    }
                }
            }

            .filter-subtitle {
                display: flex;
                align-items: center;
                background: #161616;
                color: #fff;
                display: block;
                height: 75px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
                padding: 20px;
                font-weight: bold;
                border-bottom: 0;
            }

            .filter-options {
                background: #fff;
                bottom: 0;
                display: block;
                left: 0;
                overflow: scroll;
                position: fixed;
                right: 0;
                top: 75px;
                z-index: 10;

                .filter-options-content .item {
                    padding: 0 20px;
                }
            }

            .filter-content {
                display: block;
            }
        }
    }

    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: 300;
        }

        &-actions {
            padding: 0 0 20px 0;
            margin:  0 20px 25px;

            @include respond-to(medium) {
                border-bottom: 1px solid #000;
            }
        }

        &-options-content {
            padding: 18px 20px 0 26px;
        }
    }

    .filter .filter-current {
        border: none;
        margin: 0;
        padding: 20px 20px 0;

        .action.remove {
            top: -1px;
        }

        &-subtitle {
            position: relative;
            text-transform: uppercase;
            z-index: 1;
            padding-bottom: 20px;
            border-bottom: 1px solid #e8e8e8;
            padding-right: 50px;

            &:before {
                position: absolute;
                right: 10px;
                top: -2px;
            }
        }

        &.active {
            margin: 0;

            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }
    }

    .filter-no-options {
        .filter-title {
            display: none;
            &:before {
                content: '';
                display: block;
                height: 40px;
                left: 0;
                margin-top: -25px;
                position: relative;
                width: 75px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: 16px;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 1;
            }
        }
    }
}


@include respond-to($screen__m) {
    .filter {
        &.block {
            margin-bottom: 0;
        }

        &-title {
            display: none;
        }

        &-content {
            .item {
                margin: 10px 0;

                &:nth-last-child(1) {
                    margin-bottom: 6px;
                }
            }
        }

        &-actions {
            margin-bottom: 80px;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: block;
            position: static;
        }
    }

    .page-layout-1column {
        .products ~ .toolbar-products {
            position: static;
            padding: 0 34px;
        }

        &.page-with-filter .column.main {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid #ccc;
            }

            &-content {
                margin-top: 10px;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: 16px;
                    position: relative;

                    &.active {
                        z-index: 2;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &::after,
                        &::before {
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: 16px;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            border-bottom: #fff;
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 10px 0 0;

                    &:after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                   box-shadow: 0 3px 5px 0 rgba(50, 50, 50, .75);
                    background: #fff;
                    border: 1px solid #ccc;
                    padding: 10px 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: 10px;

                        a {
                            margin-left: 0;
                        }

                        &:hover {
                            background-color: #161616;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    color: #000;
                    display: inline;
                    font-size: 16px;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: 16px;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: 16px;
                }
            }
        }
    }
}

@include respond-to($screen__s, 'max') {
    .filter-title {
        padding: 0 20px 25px;
    }
}
