// Programs
// sass-lint:disable-all

body.programs-product-view {
	.page-main {
		margin: 0;
		padding: 0;
		max-width: none;

		@include respond-to(xlarge) {
			margin: 0;
		}

		@include respond-to(768px) {
			margin: 80px 0 0;
		}
	}

	.message {
		margin: 0;
	}

	.page-wrapper {
		overflow: visible !important;
	}

	.columns {
		.column.main {
			order: inherit;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row-reverse;
			justify-content: flex-end;
			background-color: #000;

			.product-info-main {
				width: 100%;
				max-width: 720px;
				padding: 30px 20px;
				color: #fff;
				margin: 0;

				@include respond-to(medium) {
					width: 50%;
					padding: 50px;
				}

				@include respond-to(small) {
					padding: 30px;
				}

				.class-type {
					color: #7D7D7D;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 1.1px;
					line-height: 20px;
					text-transform: uppercase;

					.type {
						display: none;
					}
				}

				.page-title {
					color: #FFFFFF;
					font-size: 38px;
					font-weight: bold;
					letter-spacing: 1.27px;
					line-height: 48px;
					margin-top: 5px;
				}

				.overview {
					margin-bottom: 24px;

					p {
						color: #FFFFFF;
						font-size: 16px;
						letter-spacing: 1px;
						line-height: 24px;
					}
				}

				.product-info-price {

                    .price-container {
                        display: none;
                    }
					border-bottom: 1px solid #333333;

					.price-box {
						padding-bottom: 20px;
					}

					.price {
						color: #FFFFFF;
						font-size: 24px;
						font-weight: bold;
						letter-spacing: 0.8px;
						line-height: 30px;

                        &.display-price {
                            margin-bottom: 10px;
                        }
					}

					.price-note {
						color: #7D7D7D;
						font-size: 15px;
						font-style: italic;
						font-weight: 300;
						letter-spacing: 0.5px;
						line-height: 26px;

						p {
							margin-bottom: 0;
						}
					}
				}

				.full-details-actions {
					display: none;
				}

				.product-add-form {
					padding-top: 0;

					form {
						display: flex;
						flex-wrap: wrap;
						gap: 10px;
					}

					.product-options-wrapper {
						width: 100%;

						@include respond-to(1240px) {
							width: auto;
						}

						.field {
							margin: 0;

							.label {
								color: #FFFFFF;
								font-size: 14px;
								letter-spacing: 0.47px;
								font-weight: normal;
								line-height: 18px;
								margin-bottom: 10px;

								&::after {
									display: none;
								}
							}

							select {
								height: 46px;
								width: 100%;
								border: 1px solid rgba(255,255,255,0.18);
								background-color: #333333;
								color: #FFFFFF;
								font-size: 14px;
								letter-spacing: 0.47px;
								line-height: 18px;
								padding: 5px 30px 10px 10px;
								background-image: inline-svg('#{config(icons_output)}/chevron-down.svg', (path: (fill: #D32700)));
								background-position: right 5px top 10px;
							}
						}
					}

					.product-options-bottom {
						padding-top: 3px;
						width: 100%;

						@include respond-to(1240px) {
							width: auto;
						}

						.box-tocart {
							margin: 0;
						}

						.fieldset {
							flex-direction: row;

							.reset-btn {
								display: none;
							}

							.actions {
								padding: 0;

								.action.primary.tocart {
									margin: 28px 0 0 10px;
									height: 46px;
									padding: 10px 10px;
									line-height: 21px;
									color: #FFFFFF;
									font-size: 16px;
									font-weight: 600;
									letter-spacing: 0;
									line-height: 20px;
									text-align: center;
								}
							}
						}

						.field {
							margin: 0;

							.label {
								color: #FFFFFF;
								font-size: 14px;
								letter-spacing: 0.47px;
								font-weight: normal;
								line-height: 18px;
								margin: 0 0 10px;
								text-transform: capitalize;

								&::after {
									display: none;
								}
							}

							&.qty {
								width: 51px;
								flex-wrap: wrap;
							}

							.input-text.qty {
								height: 46px;
								width: 51px;
								border: 1px solid rgba(255,255,255,0.18);
								background-color: #333333;
								color: #FFFFFF;
								font-size: 14px;
								letter-spacing: 0.47px;
								line-height: 18px;
								text-align: center;
							}
						}
					}
				}

				.availability {
					color: #FFFFFF;
					font-size: 15px;
					font-style: italic;
					font-weight: 300;
					letter-spacing: 0.5px;
					line-height: 26px;
					margin-top: 20px;
				}

				#bss-custom-option {
					display: none;
				}
			}

			.product.media {
				width: 100%;
				min-height: 350px;
				margin: 0;
				overflow: hidden;

				@include respond-to(medium) {
					width: 50%;
					min-height: inherit;
				}

				@include respond-to(small) {
					min-height: 450px;
				}

				.fotorama,
				.fotorama__wrap,
				.gallery-placeholder {
					height: 100%;
				}

				.fotorama__stage__frame .fotorama__img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.fotorama__stage {
					height: 100% !important;
				}

				.fotorama-video-container {
					&::after {
						background: linear-gradient(180deg, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0.4) 100%);
						width: 100%;
						height: 100%;
						cursor: pointer;
					}

					&.video-unplayed:hover img {
						opacity: 1;
					}
				}

				.gallery-placeholder {
					position: relative;

					.play-icon-with-sku {
						position: absolute;
						left: 20px;
						bottom: 30px;
						z-index: 99;
						color: #fff;
						display: flex;
						flex-direction: column;
						background-image: inline-svg('#{config(icons_output)}/play-button.svg', (path: (fill: #ffffff)));
						background-repeat: no-repeat;
						padding-left: 35px;
						cursor: pointer;

						@include respond-to(medium) {
							left: 50px;
						}

						@include respond-to(small) {
							left: 30px;
						}

						&.no-display {
							display: none;
						}

						.video-icon {
							font-size: 16px;
							font-weight: bold;
							letter-spacing: 0.53px;
							line-height: 20px;
							text-transform: uppercase;
							padding-bottom: 3px;
						}

						.video-product-sku {
							font-size: 14px;
							letter-spacing: 0.47px;
							line-height: 18px;
						}
					}
				}
			}
		}
	}

	.call-to-action-block {
		.row-full-width-inner  {
			padding: 80px 20px;
			text-align: center;
		}
	}

	.product-reviews-wrapper {
		max-width: 1240px;
		margin: 0 auto;
		padding: 80px 20px;
	}
}
