// sass-lint:disable-all

//
//  Common
//  _____________________________________________


//  Product Lists
.products {
	margin: 0;

    @include respond-to(full, max) {
        padding: 0 10px;
    }
}

.products-grid .product-item .product-item-details {
	border-top: 1px solid #e8e8e8;
	margin: 0 34px 36px 34px;
	padding-top: 22px;
	color: #000;

	.product-reviews-summary {
		display: none;
	}
}

.product {

	&-item {
		vertical-align: top;

		.products-grid & {
			border: 2px solid transparent;
			display: inline-block;
			margin-left: 0;
			width: calc(99% / 2);
		}

		&:nth-child(2n + 1) {
			margin-left: 0;
		}

		&-name {
			font-size: 18px;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			-webkit-hyphens: auto;
			display: block;
			hyphens: auto;
			margin: 2px 0;
			word-wrap: break-word;
		}


		&-company {
			font-size: 14px;
			display: block;
			font-weight: bold;
			text-transform: uppercase;

			&.empty > span {
				display: none;
			}
		}

		&-info {
			max-width: 100%;
			width: 152px;

			.page-products & {
				width: 100%;
			}
		}

		&-actions {
			font-size: 0;

			> * {
				font-size: 1.4rem;
			}

			.actions-secondary {
				display: inline-block;
				font-size: 1.4rem;
				vertical-align: middle;

				> .action {
					&:before {
						margin: 0;
					}
					line-height: 35px;
					text-align: center;
					width: 35px;
				}
			}

			.actions-primary {
				display: inline-block;
				vertical-align: middle;
			}
		}

		&-description {
			margin: 10px 0;
		}

		.product-reviews-summary {
			.rating-summary {
				margin: 0 4px 0 0;
			}

			.reviews-actions {
				font-size: 12px;
				margin-top: 5px;
			}
		}

		.price-box {
			margin: 6px 0 0 0;

			.price {
				font-weight: 800;
				white-space: nowrap;
			}

			.price-label {
				margin-right: 3px;
				font-size: 12px;
				text-transform: uppercase;
			}
		}

		.old-price {
			margin: 5px 0;

			.price {
				font-weight: normal;
			}
		}

		.regular-price {
			.price-label {
				display: none;
			}
		}

		.minimal-price {
			.price-container {
				display: block;
			}
		}

		.minimal-price-link {
			margin-top: 5px;
		}

		.price-from,
		.price-to {
			margin: 0;
		}

		.tocart {
			font-size: 13px;
			border-radius: 0;
			line-height: 1;
			padding-bottom: 10px;
			padding-top: 10px;
			white-space: nowrap;
		}
	}
}

.column.main {
	.product {
		&-items {
			margin-left: 0;
		}

		&-item {
			padding-left: 0;
		}
	}
}

.price-container {
	.price {
		font-size: 14px;
	}

	.price-including-tax + .price-excluding-tax,
	.weee {
		margin-top: 5px;
	}

	.price-including-tax + .price-excluding-tax,
	.weee,
	.price-including-tax + .price-excluding-tax .price,
	.weee .price,
	.weee + .price-excluding-tax:before,
	.weee + .price-excluding-tax .price {
		font-size: 11px;
	}

	.weee {
		&:before {
			content: '(' attr(data-label) ': ';
		}

		&:after {
			content: ')';
		}

		+ .price-excluding-tax {
			&:before {
				content: attr(data-label) ': ';
			}
		}
	}
}

.products-list {
	.product {
		&-item {
			display: table;
			width: 100%;

			&-info {
				display: table-row;
			}

			&-photo {
				display: table-cell;
				padding: 0 30px 30px 0;
				vertical-align: top;
				width: 1%;
			}

			&-details {
				display: table-cell;
				vertical-align: top;
			}
		}
	}
}


//
//  Mobile
//  _____________________________________________
@include respond-to($screen__s, 'max') {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 10px 10px 0;
                width: 30%;
            }
        }
    }
}

@include respond-to($screen__s) {
    .product-item-info {
        .products-grid & {
            &:hover,
            &.active {
                border: 0;
                margin: 0;
                padding: 0;
                position: relative;
                z-index: 2;

                .product-item-inner {
                    display: none;
                }

                .sidebar & {
                    .product-item-photo {
                        left: 9px;
                        top: 9px;
                    }
                }
            }
        }
    }

    // .product-item-inner {
    //     .products-grid & {
    //         background: #fff;
	// 		box-shadow: 3px 4px 4px 0 rgba(0,0,0,.3);
    //         border: 1px solid #bbb;
    //         border-top: none;
    //         left: 0;
    //         margin: 10px 0 0 -1px;
    //         padding: 0 9px 9px;
    //         position: absolute;
    //         right: -1px;
    //         z-index: 2;
    //     }
    // }

    .product-item-actions {
        display: block;

        .products-grid & {
           margin: -10px 0 10px;
        }

        .actions-primary + .actions-secondary {
            display: inline-block;
            vertical-align: middle;

            > .action {
                line-height: 35px;
                text-align: center;
                width: 35px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .actions-primary {
            display: inline-block;
        }
    }

    .page-products {
        .products-grid {
            .product-item-inner {
                &:before {
                    background-color: #fff;
                    content: '';
                    height: 3px;
                    left: 0;
                    position: absolute;
                    top: -2px;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        .product-item-actions {
            position: relative;
            z-index: 1;
        }
    }

    .products-grid {
        .product-item {
            margin-bottom: 0;
            width: calc(98% / 3);
        }
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                margin-left: 0;
                width: calc(98% / 3);

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        .product-item-inner {
            box-shadow: 3px 6px 4px 0 rgba(0, 0, 0, .3);
            margin: 9px 0 0 -1px;
        }
    }
}

@include respond-to($screen__xs, 'max') {
    .product {
        &-item {
            .products-grid & {
                width: calc(100% / 1);
            }
        }

        &-item-photo {
			display: flex;

            .product-image-container {
                margin: 0 auto;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include respond-to($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                margin-left: 0;
                width: calc(99% / 2);

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: calc(100% / 4);
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: calc(100% / 2);
            }
        }
    }
}

@include respond-to($screen__l) {
    .products-grid {
        .product-item {
            width: calc(100% / 5);
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: calc(100% / 6);
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: calc(100% / 4);
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: 0 !important;
                width: calc(100% / 3 - 5px) !important;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: calc(100% / 4);
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.catalog-category-view,
.catalogsearch-result-index {
    .columns {
        .toolbar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            padding: 0;
            background-color: transparent;
            text-align: center;

            .limiter {
                display: none !important;
            }


            .action {
                border: none;

                &::before {
                    font-size: 54px;
                }

                &.disabled-pager-action {
                    &::before {
                        opacity: 0.3;
                        cursor: default;
                    }
                }
            }

            .item {
                margin: 0 12px 0 0;
            }

            .pages a.page {
                color: #000;
                display: inline-block;
                padding: 0 5px;
                text-decoration: none;

                span {
                    font-weight: 400;
                    font-size: 17px;
                }
            }

            .current {
                span {
                    text-decoration: underline;
                    font-size: 17px;
                }
            }
        }
    }

	.breadcrumbs-toolbar-wrapper {
		.toolbar-products {
			.toolbar-amount {
				@include respond-to(641px, max) {
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
	}

}

.catalog-category-view {
    .slick-next::before {
        font-family: 'icons-blank-theme';
        content: '\e622' ;
        transform: rotate(270deg);
        display: block;
    }

    .slick-prev::before {
        font-family: 'icons-blank-theme';
        content: '\e622' ;
        transform: rotate(90deg);
        display: block;
    }
}

.catalog-category-view,
.catalogsearch-result-index {
	.category-view {
		.category-image {
			.image {
				width: 100%;
			}

			.banner-title-tag {
				transform: translate(25px, -50%);
				background: none;
				font-size: 18px;
				padding: 20px 50px;
				text-align: center;
				line-height: 1.2;
				@include respond-to(small) {
					font-size: 24px;
					padding: 0;
				}
				@include respond-to(medium) {
					font-size: 34px;
					padding: unset;
					letter-spacing: 7px;
					right: 7%;
					top: 50%;
					left: unset;
					transform: translate(25px, -50%);
					line-height: 1.1;
				}
				@include respond-to(768px, max) {
					position: absolute;
					left: 0;
					right: 0;
					margin-inline: auto;
					width: fit-content;
					transform: translate(0%, -50%);

				}
				@include respond-to(large) {
					letter-spacing: 8px;
					font-size: 40px;
				}

				span {
					background: rgba(128, 128, 128, 0.7);
					display: block;
					padding: 10px 20px;
					@include respond-to(small) {
						padding: 20px;
					}
				}
			}
		}
	}
}

.categorypath-services-smith-shop,
.categorypath-services-custom-cerakoting,
.categorypath-services-gunsmithing,
.categorypath-services-custom-ammo {
	.category-view {
		.category-cms {
			.pagebuilder-poster-overlay {
				height: 353px;
				aspect-ratio: unset;
			}
		}
	}
}
