// sass-lint:disable class-name-format nesting-depth no-important

// NOTE: some of the menu properties here are affected by global styles in _header.scss
// Mainly hide/show of mobile vs. desktop behaviors

nav {

	// Global styles
	[class*='__inner-item--all'] {
		display: none;
	}
}
