// sass-lint:disable-all

.contact-index-index .page-main {
    max-width: 1440px;
    padding: 0;

    [data-content-type='row'][data-appearance='contained'] {
        div[data-element="inner"] {
            height: 354px;
            position: relative;
            background-position: center !important;
        }

        h1 {
            font-size: 24px;
            position: absolute;
            top: 50%;
            width: 100%;
            font-weight: 800;
            line-height: 32px;
            text-align: center;
            letter-spacing: 7px;
            text-transform: uppercase;
            color: #fff;
            transform: translate(0, -50%);

            @include respond-to(medium) {
                width: auto;
                font-size: 40px;
                right: 106px;
                line-height: 150px;
                text-align: right;
            }

            @include respond-to(xlarge) {
                top: 97px;
            }
        }
    }

    h2.mid-top-title {
       font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        letter-spacing: 3.63636px;
        color: #000;
        text-transform: uppercase;
        margin: 102px auto;
    }

    .contact-form {
        background: #000;
        padding: 90px 30px;

        @include respond-to(xlarge) {
            padding: 90px 100px;
        }
    }

    .form-wrapper {
        min-width: unset;
        max-width: 1240px;
        width: 100%;
        margin: 0 auto;
        position: relative;

        &:before {
            content: ' ';
            background: url(../images/contact-background-form-new.jpg) no-repeat;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
            opacity: 0.2;
        }
    }

    .column:not(.sidebar-additional) .form.contact {
        min-width: unset !important;
        max-width: 100%;
        width: 100%;

        @include respond-to(large) {
            max-width: 275px;
        }
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        height: 646px;
        max-width: 905px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 15px 0;

        @include respond-to(large) {
            flex-direction: row;
            padding: 147px 15px 0;
        }

        .form.contact {
            input{
                min-height: unset;
                margin: 0 0 20px;
            }
            .required {
                display: none;
            }
            label {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.125rem;
                letter-spacing: 0.03125rem;
                color: #838a94;
                border: 0;
                background: unset;
                padding-left: 0;
                height: max-content;
                }
            span{
                font-size: 1.25rem;
                font-weight: 800;
                line-height: 3.75rem;
                letter-spacing: 0.125rem;
                text-transform: uppercase;
                color: #fff;
                margin-bottom: 20px;
            }
            .amcform-toolbar{
                justify-content: center;
                .amcform-submit {
                    background: #fff;
                    border-color: #fff;
                    max-width: 115px;
                    width: 148px;
                    height: 44px;
                    color: #0a0a0a;
                }
            }
        }

        .info {
            max-width: 296px;
            width: 100%;
            position: relative;

            @include respond-to(large) {
                top: 124px;
            }

            [data-content-type='heading'],
            div[data-content-type='text'] {
                text-align: left;
                font-weight: 800;
                color: #fff;
            }

            [data-content-type='heading'] {
               font-size: 20px;
                line-height: 60px;
                letter-spacing: 2px;
                text-transform: uppercase;
                position: relative;
                top: unset;
                right: unset;

            }

            div[data-content-type='text'] {
                font-size: 22px;
                line-height: 51px;

                a {
                    color: #fff;
                }
            }
        }
    }

    .legend > span {
       font-size: 20px;
        font-weight: 800;
        line-height: 60px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
    }

    .fieldset {
        > .legend {
            border: 0;
            margin: 0;
            padding: 0;
        }

        > .field > .label {
            display: none;
        }


        input[type="text"],
        input[type="password"],
        input[type="url"],
        input[type="tel"],
        input[type="search"],
        input[type="number"],
        input[type="datetime"],
        input[type="email"],
        select,
        textarea {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.5px;
            color: #838a94;
            min-height: 45px;
            border: 0;
            border-bottom: 1px solid #838a94;
            background: unset;
            padding-left: 0;
            height: max-content;

            &:active,
            &:focus {
                box-shadow: unset;
            }

            &::placeholder {
                color: #838a94;
            }

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #838a94;
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: #838a94;
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: #838a94;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: #838a94;
            }
        }
    }

    .actions-toolbar > .primary .action:last-child {
        background: #fff;
        border-color: #fff;
        max-width: 115px;
        width: 148px;
        height: 44px;

        span {
            color: #000;
            letter-spacing: 1px;
        }
    }

    .contact-map {
        max-width: 1240px;
        width: 100%;
        margin: 112px auto;

        .pagebuilder-column-group > div {
            &:first-child {
                height: 362px;
            }

            &:nth-child(2) {
                background: #000;

                [data-content-type='heading'] {
                    font-size: 20px;
                    font-weight: 800;
                    line-height: 25px;
                    text-align: center;
                    letter-spacing: 2px;
                    color: #fff;
                    position: relative;
                    top: unset;
                    right: unset;
                }

                div[data-content-type='text'] {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #fff;
                    margin: 16px 0 0 !important;

                    &.sched {
                        font-size: 20px;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }

                [data-content-type='buttons'] {
                    margin: 16px 0 0 !important;
                }

                [data-content-type='button-item'] [data-element='link'] {
                    background-color: #d32700;
                    border: 1px solid #d32700;
                    color: #fff;
                    height: 28px;
                    border-radius: unset;
                    margin: 0;
                    padding: 6px 20px;
                    line-height: 10px;

                    span {
                        font-size: 14px;
                        font-weight: 800;
                        letter-spacing: 0.5px;
                        line-height: 14px;
                        text-align: center;
                        text-transform: uppercase;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
