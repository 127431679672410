// Store Global
// sass-lint:disable-all
a,
button,
div {

	&.action.primary {
		height: 40px;
		font-size: 14px;
		line-height: 1.5;
	}

	&.action.back,
	&.white-button {
		display: inline-block;
		height: 40px;
		padding: 10px 30px;
		color: color(black-regular) !important;
		background: color(white-regular);
		border-radius: 0;
		border: 1px solid color(black-regular);
		font-size: 14px !important;
		line-height: 1.5 !important;
		margin: 0 !important;
		text-transform: uppercase;
		transition: all 0.3s ease-out;
		text-decoration: none;

		&:visited {
			color: color(black-regular);
		}

		&:active,
		&:focus {
			background: color(white-regular);
		}

		&:hover {
			border: 1px solid color(snow-red);
			background: color(snow-red);
		}

		span {
			position: relative;
			padding-right: 25px;

			&::before,
			&::after {
				position: absolute;
				content: '';
			}

			&::before {
				top: 0;
				right: 0;
				width: 16px;
				height: 16px;
				background: color(snow-red);
				border-radius: 50%;
			}

			&::after {
				top: 4px;
				right: 4px;
				width: 0;
				height: 0;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-left: 6px solid color(black-regular);
			}
		}
	}

	&.action.back {
		span {
			padding-right: 0;
			padding-left: 25px;

			&::before,
			&::after {
				position: absolute;
				content: '';
			}

			&::before {
				right: inherit;
				left: 0;
			}

			&::after {
				right: inherit;
				left: 4px;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-left: 0;
				border-right: 6px solid color(black-regular);
			}
		}
	}
}

.amform-date,
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
select,
textarea {
	width: 100%;
	height: 36px;
	border: 1px solid #737373;
	background-color: #EEEEEE;
	color: #000000;
	font-family: family(muli);
	font-size: 15px;
	letter-spacing: 0;
	line-height: 19px;

	&:focus {
		outline: none;
		box-shadow: none;
	}

	&.mage-error {
		border: 1px solid #e02b27;
	}

	&::placeholder {
		color: color(black-light);
	}
}

.rendered-form {
	&.amform-form {
		.amform-date {
			padding: 0 10px;
			width: calc(100% - 50px);
		}

		input[type="number"] {
			max-width: 100%;
		}

		.fieldset .fields .amform-layout-two,
		.fieldset .fields .amform-layout-three {
			@include respond-to(nav_xs, max) {
				margin-right: 0;
				width: 100%;
				max-width: 100%;
			}
		}

		.amcform-toolbar {
			display: flex;
			justify-content: flex-end;

			.action.primary.amcform-next,
			.action.primary.amcform-submit,
			.action.primary.amcform-prev {
				margin: 20px 0;
			}

			.action.primary.amcform-prev {
				margin-right: 15px;
			}
		}
	}
}

select {
	padding-right: 26px;
	background-image: inline-svg('#{config(icons_output)}/chevron-down.svg', (path: (fill: color(black-regular))));
	background-repeat: no-repeat;
	background-position: right 5px top 5px;
	background-size: 24px;
	-moz-appearance: none;
	appearance: none;

	// removes the select toggle from IE
	&::-ms-expand {
		display: none;
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

select[multiple="multiple"] {
	height: 150px;
	overflow: auto;

	option {
		padding: 5px;
	}
}

._keyfocus :focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus {
	outline: none;
	box-shadow: none;
}

input[type='radio'],
input[type='checkbox'] {
	@include screen-reader-text;
}

input[type='radio'] + label,
input[type='checkbox'] + label {
	position: relative;
	margin-left: 0;
	padding: 3px 0 0 26px;
	opacity: 1;
	font-style: normal;
	cursor: pointer;

	&::before {
		position: absolute;
		top: 3px;
		left: 0;
		width: 18px;
		height: 18px;
		border: 1px solid #737373;
		background-color: #EEEEEE;
		content: '';
	}
}

input[type='radio'] + label {
	&::before {
		border-radius: 50%;
	}
}

input[type='checkbox']:checked + label {

	&::before {
		background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.2%209%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M4%209L0%205l1.8-1.7L4%205.5%209.4%200l1.8%201.8z%22%2F%3E%3C%2Fsvg%3E') no-repeat center center / 70% 70%;
		background-size: 12px;
	}
}

input[type='radio']:checked + label {
	&::before {
		background: none;
	}

	&::after {
		position: absolute;
		top: 7px;
		left: 4px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: color(black-regular);
		content: '';
	}
}