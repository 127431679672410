// sass-lint:disable-all

.page-bottom-cta {
	padding: 60px 0;

	@include respond-to(medium) {
		padding: 150px 0;
	}
}

.page-footer {
	background-color: color(black-regular);
	color: color(white-regular);

	.footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 1340px !important;
		margin-top: 0;
		padding: 50px 22px 35px !important;
		border-top: none;

		@include respond-to(medium) {
			padding: 73px 30px 48px !important;
		}
	}

	.customer-service {
		width: 100%;
		margin-bottom: 40px;

		@include respond-to(large) {
			width: 254px;
			margin-bottom: 0;
		}

		li {
			margin-bottom: 5px !important;
		}

		a {
			color: color(white-regular);
			font-size: 14px;

			&:hover {
				color: color(white-regular);
				text-decoration: underline;
			}
		}

		.customer-service-heading {
			div {
				margin-bottom: 20px;
				padding-bottom: 11px;
				border-bottom: 1px solid #979797;
				font-size: 17px;
				font-weight: $font-weight__bold;
				text-transform: uppercase;
			}
		}
	}

	.block.newsletter {
		width: 100%;

		@include respond-to(large) {
			width: calc(53% - 254px);
		}

		.title {
			margin-bottom: 20px;
			padding-bottom: 11px;
			border-bottom: 1px solid #979797;
			font-size: 17px;
			font-weight: $font-weight__bold;
			text-transform: uppercase;
		}

		.label {
			display: block;
			margin-bottom: 16px;
			font-size: 14px;
		}

		.subscribe {
			display: flex;

			.newsletter {
				width: calc(100% - 102px);

				input {
					height: 40px;
					padding: 0 10px 0 22px;
					border-radius: 1px;
				}
			}
		}

		.actions {
			width: 102px;

			.primary {
				width: 100%;
				padding: 0 10px;
				color: color(white-regular);
				font-weight: $font-weight__bold;
				line-height: 34px;
				text-align: center;

				span {
					width: 100%;
				}
			}

			.primary:hover {
				background: color(snow-red-hover) !important;
				color: color(white-regular);
			}
		}
	}

	.map-info {
		width: 100%;
		padding: 20px 30px;
		background: #121212;

		@include respond-to('large') {
			width: 35.6%;
		}

		@include respond-to('xlarge') {
			padding: 42px 60px;
		}

		.pagebuilder-column-line {
			justify-content: space-between;
		}

		.map-info-image {
			width: 96px !important;
			flex-basis: initial !important;
		}

		h3 {
			margin-top: 0;
			margin-bottom: 20px;
			font-size: 17px;
			font-weight: $font-weight__bold;
			text-transform: uppercase;
		}

		figure {
			width: 96px;
			height: 96px;
		}

		.map-info-content {
			flex-basis: initial !important;
			width: calc(100% - 115px) !important;

			@include respond-to('large') {
				width: calc(100% - 120px) !important;
			}

			p {
				font-size: 14px;
			}

			a {
				color: color(white-regular);
				font-size: 12px;
				font-weight: $font-weight__bold;
				text-decoration: underline;

				&:hover {
					color: color(white-regular);
					text-decoration: none;
				}
			}
		}
	}

	.copyright {
		display: block;
		padding: 14px;
		border-top: 1px solid #262626;
		font-size: 10px;
		letter-spacing: 0.3px;
		line-height: 13px;
		text-align: center;
	}

	.social-media {
		display: flex;
		margin-top: 18px !important;

		.social-media-list {
			margin-right: 10px;
		}

		.social-media-link {
			display: block;
			width: 22px;
			height: 22px;
			padding: 3px;
			border-radius: 50%;
			background: color(white-regular);
		}

		svg {
			display: block;
			width: 16px;
			height: 16px;
		}

		.facebook-link:hover {
			svg {
				fill: #3b5998;
			}
		}

		.youtube-link:hover {
			svg {
				fill: #FF0000;
			}
		}

		.instagram-link:hover {
			svg {
				fill: #8a3ab9;
			}
		}

		.pinterest-link:hover {
			svg {
				fill: #E60023;
			}
		}
	}
}
