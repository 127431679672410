@import 'maps';
@import 'functions';
@import 'mixins';

/*   Base styles and layout
	--------------------------------------------------------------------------  */

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

// sass-lint:disable no-universal-selectors
*,
*::before,
*::after {
	box-sizing: inherit;
}
// sass-lint:enable no-universal-selectors

.group {
	@include clearfix;
}

.screen-reader-text {
	@include screen-reader-text;
}

body {
	@include font-smoothing;
}
