// sass-lint:disable-all
//
//  Tooltips variables
//  _____________________________________________

$tooltip__background    : $color-white !default;
$tooltip__border-color  : $color-gray-light2 !default;
$tooltip__border-width  : 1px !default;
$tooltip__border-radius : false !default;
$tooltip__color         : $primary__color !default;
$tooltip__cursor        : help !default;
$tooltip__font-size     : false !default;
$tooltip__font-family   : false !default;
$tooltip__font-weight   : false !default;
$tooltip__line-height   : 1.4 !default;
$tooltip__font-style    : false !default;
$tooltip__margin        : false !default;
$tooltip__padding       : 12px 16px !default;
$tooltip__min-width     : 210px !default;
$tooltip__max-width     : 360px !default;
$tooltip__z-index       : 100 !default;

$tooltip-arrow__size    : 5px !default;
$tooltip-arrow__offset  : 10px !default;
