.utility-menu-wrapper {
	background-color: #000;
	max-width: 100%;
	width: auto;
	padding: 10px 20px;
	position: relative;
	border-top: 1px solid #262626;
}

.utility-menu {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	max-width: 1440px;
	margin: 0 auto;

	> .contact-menu {
		order: -1;
		list-style-type: none;

		@include respond-to(nav_md, max) {
			display: none;
		}

		a {
			position: relative;
			font-size: 12px;
			letter-spacing: 0.7px;
			text-transform: uppercase;
			transition: 0.3s all ease-in-out;
			color: #fff;
			font-weight: 600;
			padding: 0;
			margin: 0 10px 0;
			top: -2px;

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: transparent;
				content: '';
			}

			&:hover {
				&::after {
					background-color: color(snow-red);
				}
			}
		}
	}

	.block {
		margin-block-end: 0;
	}

	.block-search,
	.block-account {
		display: block;
		float: inherit;
		width: auto;
		padding: 0 12px;
		cursor: pointer;

		.block-title {
			position: relative;
			display: flex;
			align-items: center;

			strong {
				position: relative;
				margin-left: 22px;
				font-size: 12px;
				letter-spacing: 0.7px;
				text-transform: uppercase;
				transition: 0.3s all ease-in-out;
				color: #fff;

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: transparent;
					content: '';
				}

				&:hover {
					&::after {
						background-color: color(snow-red);
					}
				}
			}

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				width: 16px;
				height: 16px;
				background-repeat: no-repeat;
				background-position: left;
				background-size: 16px;
				content: '';
			}
		}

		@include respond-to(nav_md) {
			display: block;
		}
	}

	.block-search {
		position: static;
		margin: 0;
        z-index: unset;

		@media only screen and (max-width: 639px) {
			margin: 0;
		}

		.block-title {
			&::before {
				background-image: url('../images/search-icon.svg');
			}
		}

		.block-content {
            z-index: 6;
			display: none;
		}

		&.active {
			.block-title strong {
				&::after {
					background-color: color(snow-red);
				}
			}
		}
	}

	.block-account {
		.block-title {
			&::before {
				background-image: url('../images/user-icon.svg');
				background-size: 13px;
			}
		}

		&.active {
			.block-title strong {
				&::after {
					background-color: color(snow-red);
				}
			}
		}
	}

	.minicart-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		@include respond-to(nav_md, max) {
			margin-block-start: 0;
		}

		.showcart {
			position: relative;
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 12px;
			letter-spacing: 0.7px;
			text-transform: uppercase;
			transition: 0.3s all ease-in-out;
			color: #fff;

			&:hover {
				.text {
					&::after {
						background-color: color(snow-red);
					}
				}
			}

			&::before {
				display: block;
				width: 20px;
				height: 20px;
				background-image: inline-svg('#{config(icons_output)}/shopping-icon.svg', (path: (fill: #fff)));
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px;
				content: '' !important; // sass-lint:disable-line no-important
			}

			&::after {
				position: absolute;
				right: 5px;
				bottom: 0;
				width: 23px;
				height: 2px;
				background-color: transparent;
				content: '';
			}

			.text {
				clip: unset;
				width: auto;
				height: auto;
				overflow: visible;
				position: relative;

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: transparent;
					content: '';
				}
			}
		}
	}
}
