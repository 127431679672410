.sub-menu {

	&:not(.sub-menu-level-2) {
		position: fixed;
		z-index: 1001;
		top: 0;
		left: 100%;
		width: 100%;
		padding: 0;
		background-color: color(black-regular);
		transition: left 0.3s ease-out;

		@include respond-to(nav_md, max) {
			height: 100vh;
		}

		@include respond-to(nav_md) {
			position: absolute;
			z-index: 0;
			top: 100%;
			left: 0;
			display: block;
			clip-path: inset(0 -4px calc(100%) -4px);
			transition: clip-path 0.35s ease-out;
		}

		&.active {
			position: fixed;
			z-index: 6;
			top: 73px;
			left: 80px;
			width: calc(100% - 80px);
			overflow: auto;

            @include respond-to(nav_xs, max) {
                left: 60px;
                width: calc(100% - 60px);
            }

			.menu-container {
				max-width: 100%;

				> ul {
					grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
				}
			}

			.menu-item {
                img {
                    @include respond-to(large, 'max') {
                        display: none;
                    }
                }
				.title {
					@at-root .services & {
						@include respond-to(nav_md, 'max') {
							display: block;
						}
					}
				}
			}
		}
	}

	.menu-container {
		max-width: 1270px;
		margin: 0 auto;

		@include respond-between(nav_md, xlarge) {
			max-width: 100%;
		}

		@at-root .parts & {
			max-width: 1150px;

			@include respond-between(nav_md, xlarge) {
				max-width: 100%;
			}
		}

        @at-root .smr-products & {
            max-width: 1150px;

            @include respond-between(nav_md, xlarge) {
                max-width: 100%;
            }
        }

		> ul {
			display: grid;
			padding: 54px 20px;
			grid-template-columns: repeat(auto-fill,minmax(19%, 1fr));
			column-gap: 5px;
			row-gap: 5px;
            @include respond-to(nav_md, max) {
                padding: 15px 20px;
            }

			@include respond-between(nav_md, xlarge) {
				grid-template-columns: repeat(auto-fill,minmax(20%, 1fr));
			}

			@at-root .services & {
				justify-content: center;

				@include respond-to(xlarge) {
					grid-template-columns: 21% 21% 21% 21%;
				}

				@include respond-between(nav_md, xlarge) {
					grid-template-columns: repeat(auto-fill,minmax(21%, 1fr));
				}
			}

			@at-root .parts & {
				grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;

				@include respond-between(nav_md, xlarge) {
					grid-template-columns: repeat(auto-fill,minmax(18%, 1fr));
				}
			}
            @at-root .smr-products & {
	            text-wrap: wrap;

                @include respond-between(nav_md, 1300px) {
                    grid-template-columns: repeat(auto-fill,minmax(20%, 1fr));
                }
            }
		}
	}

	.menu-item {

		&:not(.menu-item-level-3) {
			padding: 25px 14px 30px;
			background: rgba(177,177,177,0.1);
			margin-block-end: 0;
		}

		@at-root .services & {
			position: relative;
		}

		@at-root .custom-rifle & {
			position: relative;
		}

		> .menu-item-link {
			display: block;

            .menu-item-image {
                width: 100%;
                display: flex;
                height: 70px;
                align-items: center;

                @include respond-to(nav_md, 'max') {
                    display: none;
                }
            }

			@at-root .parts & {
				color: color(white-regular);
				font-size: 17px;
				font-weight: 800;
				letter-spacing: 0.5px;
				line-height: 28px;
				text-align: left;
				text-transform: uppercase;
				padding-block-end: 18px;
                white-space: normal;
			}
            @at-root .smr-products & {
                color: color(white-regular);
                font-size: 17px;
                font-weight: 800;
                letter-spacing: 0.5px;
                line-height: 28px;
                text-transform: uppercase;
                padding-block-end: 18px;
                span {
                    text-align: left;
                }
            }

			img {
				object-fit: cover;

				@at-root .custom-rifles & {
					width: 199px;
					height: 36px;
				}
			}
		}

		.title {
			display: block;
			color: color(white-regular);
			font-size: 17px;
			font-weight: 800;
			letter-spacing: 0.5px;
			line-height: 28px;
			text-align: left;
			text-transform: uppercase;

			@at-root .services & {

				@include respond-to(nav_md, 'max') {
					display: none;
				}
			}

			@at-root .custom-rifle & {
				text-align: left;
                white-space: normal;
			}
		}

		.description {
			color: color(dark-gray);
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0.5px;
			line-height: 14px;
			text-align: right;
		}
	}

	&.sub-menu-level-2 {
		padding: 0;

		> .menu-item  {
			.menu-item-link {
				font-size: 14px;
				font-weight: 600;
				line-height: 12px;
				text-align: left;
				text-transform: none;
				padding-block-end: 0;
			}

			&.view-all {
				margin-block-start: 20px;

				.menu-item-link {
					color: #838a94;
					font-size: 12px;
					font-weight: 800;
					text-transform: uppercase;
					text-decoration: underline;
				}

				&:hover {
					.menu-item-link {
						text-decoration: none;
					}
				}
			}
		}
	}
}
@include respond-to(nav_md, max) {

    .custom-rifle {
        .sub-menu {
            .menu-container>ul {
                padding: 12px 21px;
            }
        }
        .sub-menu .menu-item>.menu-item-link {
            padding: 10px 0.875rem 10px;
        }
        .sub-menu .menu-item:not(.menu-item-level-3) {
            padding: unset;
        }
    }
    .smr-products {
        .sub-menu {
            .menu-container>ul {
                .menu-item {
                    padding: 10px 15px;
                    .menu-item-link{
                        padding-block-end: 0px
                    }
                }
            }
        }
    }
    .parts {
        .sub-menu .menu-item:not(.menu-item-level-3) {
            padding: 10px 15px;
        }
        .sub-menu .menu-container>ul {
            padding: 0.9375rem 1.25rem 90px;
        }
        .sub-menu .menu-item>.menu-item-link {
            padding-block-end:0px;
        }
        .sub-menu.sub-menu-level-2 {
            padding-top: 18px;
        }
    }
    .services {
        .sub-menu .menu-item .title {
            bottom: 10px;
        }
        .sub-menu .menu-item>.menu-item-link {
            padding: 10px 15px 10px;
        }
        .sub-menu .menu-item:not(.menu-item-level-3) {
            padding: unset;
        }
    }
}

.main-menu {
    .custom-rifle {
        img {
            max-height: 100%;
        }
    }
}
