
// sass-lint:disable no-important, no-ids
.page-layout-custom-rifle-landing {
	.page-main {
		max-width: none;
		padding: 0;

		> div:not(.custom-rifles-block) {
			max-width: 1440px;
			margin: 0 auto;
		}
	}

	.page-wrapper {
		overflow: hidden;
		background: #0a0a0a;
	}

	.custom-rifles-block {
		position: relative;
		max-width: none;
		background: #fff;

		ul {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			list-style: none;

			li {
				width: 100%;
				max-width: 325px;
				margin: 5px 10px 5px 0;
				text-align: center;
			}

			a  {
				position: relative;
				display: inline-block;
				width: 100%;
				max-width: 350px;
				padding: 11px 44px 11px 18px;
				background: #d32700;
				color: #fff;
				font-size: 18px;
				font-weight: 700;
				line-height: 1;
				text-transform: uppercase;


				&:hover {
					background: #ba2200;
					text-decoration: none;
				}

				&::after {
					position: absolute;
					top: 12px;
					right: 20px;
					width: 15px;
					height: 15px;
					transform: rotate(315deg);
					border: 1px solid  #fff;
					border-top: 0;
					border-left: 0;
					content: '';
				  }
			  }
		}
	}

	.custom-rifles-block-inner {
		max-width: 100%;
		margin: 0 auto;
		padding: 30px;
		letter-spacing: 1px;
		text-align: justify;

		@include respond-to(full) {
			max-width: 1440px;
			margin: 0 auto;
			padding: 78px 180px;
		}
	}

	.columns {
		padding: 20px !important;

		@include respond-to(large) {
			padding: 68px 70px !important;
		}
	}

	.products-list {
		.product-item {
			position: relative;
			display: block;
			margin-bottom: 20px;
			padding: 75px 0 39px;
			box-shadow: 0 0 9px 10px rgba(0, 0, 0, 0.3);
			background: #121212;

			&:nth-last-child(1) {
				margin-bottom: 0;
			}

			&[class^='alaskan'],
			&[class*='alaskan'] {
				.product-item-info {
					border-radius: 74px;
					background: transparent;
					transition: 0.5s;
				}

				&:hover {
					.product-item-info {
						background: #000;
					}
				}
			}

			&[class^='alpine'],
			&[class*='alpine'] {
				.product-item-bg {
					top: 0;
				}

				&:hover {
					.product-item-info {
						top: 20px;
					}
				}
			}

			.product-item-info,
			.product-item-info-main-cont {
				z-index: 5;
			}

			&:hover {
				box-shadow: none;

				.product-item-bg {
					visibility: visible;
					opacity: 1;
					box-shadow: 0 0 9px 10px rgba(0, 0, 0, 0.3);
				}

				.product-item-info {
					top: -10px;
				}

				.product-item-name a {
					border-bottom: 2px solid #d32700;
				}

				&.in-stock-selection {
					.image-product-info {
						padding: 30px 40px;
						border-radius: 74px;
						background: #000;

						.default-img {
							display: none;
						}

						.hover-img {
							display: block !important;
						}
					}
				}
			}

			.product-item-info {
				position: relative;
				top: 0;
				display: block;
				padding: 13px 20px 15px;
				transition: top 0.5s;

				.image-product-info {
					display: flex;
				}
			}

			.product-item-name {
				margin: 0 0 5px;

				a {
					color: #fff;
					font-size: 24px;
					font-weight: bold;
					letter-spacing: 1px;
					text-transform: uppercase;
					transition: 0.5s;

					@include respond-to(large) {
						font-size: 30px;
					}

					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.product-item-bg {
			position: absolute;
			z-index: 4;
			bottom: 0;
			left: -30px;
			width: 1440px;
			height: 105.75%;
			visibility: hidden;
			opacity: 0;
			background-color: #000;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			transition: 0.5s;

			@include respond-to(medium) {
				left: -70px;
			}

			span {
				display: none;
			}
		}

		.product-item-info {
			position: relative;
			top: 0;
			display: block;
			width: 100%;
			padding: 40px 79px 12px 70px;
			transition: 0.5s;
		}

		.image-product-info {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.product-item-photo {
			width: 100%;

			@include respond-to(large) {
				width: 68%;
			}
		}

		.product-item-info-main-cont {
			width: 100%;
			margin-top: 0;
			text-align: right;

			@include respond-to(large) {
				width: 30%;
			}
		}

		.product-attributes {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			padding: 0;
			list-style: none;

			.attribute {

				@include respond-to(medium) {
					padding-left: 20px;
				}
			}

			.value {
				position: relative;
				padding-left: 14px;
				color: #979797;
				font-size: 13px;

				&::before {
					position: absolute;
					top: 2px;
					display: block;
					width: 8px;
					height: 15px;
					background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8"%3F%3E%3Csvg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.69 345.19"%3E%3Cg id="Layer_1-2"%3E%3Cpath d="m26.97,152.74v-25.01h53.52v25.01c0,11.52,16.44,12.08,16.3,52.4l-.14,88.5-85.97.14c.14-26.41.14-52.68.14-78.81,0-21.77-.42-36.67,13.63-54.51,2.25-2.95,2.53-3.09,2.53-7.73Zm0-35.68c.14-22.9,3.65-37.51,9.83-57.46,2.67-8.43,5.06-17,7.59-25.29,1.12-3.51,3.65-13.77,6.18-19.95,2.81-6.74,4.92-5.34,6.74.84,10.4,34.98,23.04,65.04,23.18,101.85H26.97ZM51.97.17c-8.43,1.26-10.54,7.31-12.92,15.17-19.67,65.46-22.9,60.83-22.62,132.9,0,9.55-8.01,12.22-13.06,29.36-4.5,14.89-3.09,36.67-3.09,53.1,0,10.4-.84,90.61.42,108.59.56,7.31,5.76,5.76,14.75,5.76,4.92.14,9.83-.28,12.36-.28h70.52c5.62,0,8.29.14,8.85-5.48v-56.19c0-23.6,2.67-91.59-4.21-108.73-13.06-32.17-11.94-5.9-11.94-44.25,0-50.57-3.79-51.28-17-96.37C69.39,18.15,66.72-2.08,51.97.17Z" style="fill:%23ffffff; fill-rule:evenodd;"/%3E%3C/g%3E%3C/svg%3E');
					background-repeat: no-repeat;
					background-size: contain;
					content: '';
				}
			}
		}

		.product-item-link {
			color: #fff;
			font-size: 35px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
			transition: 0.5s;
		}

		.product-item-short-description p {
			color: #979797;
			font-size: 16px;

			@include respond-to(medium) {
				font-size: 22px;
			}
 		}
	}

	.filter {
		display: none;
	}
}
