// General (ugly) overrides to the Magento base theme - there's a lot
// to dig through and it's easier to just disable it all here
// sass-lint:disable-all

body,
html {
	height: 100%;
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&>.page.messages {
		z-index: 1;

		.messages {
			margin-bottom: -11px;
		}
	}
}

.page-main {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	width: 100%;
}

.container {
	box-sizing: border-box;
	width: auto;
	max-width: $layout__max-width;
	margin-right: auto;
	margin-left: auto;
	padding-right: $layout-indent__width;
	padding-left: $layout-indent__width;
}

.columns .column.main {
	padding-bottom: 0;
}


//
//  Common
//  _____________________________________________


//  Category view
.old-price,
.old.price {
	color: #000;
}

.prices-tier {
	.price-container {
		.price-including-tax {
			+ .price-excluding-tax {
				&:before {
					content: '(' attr(data-label) ':';
				}

				&:last-child:after {
					content: ')';
				}
			}
		}

		.weee[data-label] {
			display: inline;

			&:before {
				content: ' +' attr(data-label) ':';
			}
		}
	}
}

.actual-price {
	font-weight: 700;
}

.product.name a {
	color: #000;
}

.category-image {
	.image {
		display: block;
		height: auto;
		max-width: 100%;
	}
}

.category-image,
.category-description {
	margin-bottom: 16px;
}

//
//  Product images general container
//  -----------------------------------------

.page-products .products-grid .product-item-photo {
	display: block;
	text-align: center;
}

.product-image-container {
	display: inline-block;
	max-width: 100%;
}

.product-image-wrapper {
	display: block;
	height: 0;
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.product-image-photo {
	bottom: 0;
	display: block;
	height: auto;
	left: 0;
	margin: auto;
	max-width: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

//
//  Product view
//  -----------------------------------------

.product.media {

	.placeholder .photo.container {
		max-width: 100%;
	}

	.notice {
		margin: 10px 0;
		color: #000;
		font-size: 12px;
	}

	.product.thumbs {
		margin: 10px 0 14px;
	}

	.items.thumbs {
		> li {
			margin: 1rem 0 0;
		}

		img {
			display: block;
		}

		.active {
			display: block;
			line-height: 1;
		}
	}
}

.product.info.detailed {
	clear: both;
	margin-bottom: 30px;

	.additional-attributes {
		width: auto;
		padding: 0 10px 10px 20px;
	}
}

.product-info-main {
	.product-info-price {
		color: #000;
		border-bottom: 1px solid #000;
		display: table;
		margin-bottom: 10px + 10px;
		width: 100%;

		.price-box {
			display: table-cell;
			vertical-align: top;
			width: 1px;

			.price-container {
				> span {
					display: block;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.price-including-tax + .price-excluding-tax,
		.weee + .price-excluding-tax,
		.weee {
			font-size: 14px;
			.price {
				font-size: 14px;
				font-weight: 600;
				line-height: 16px;
			}
		}

		.price-wrapper .price {
			font-size: 22px;
			font-weight: 600;
			line-height: 22px;
		}

		.old-price {
			.price-wrapper .price {
				font-size: 20px;
				font-weight: 300;
			}
		}

		.special-price {
			.price-label {
				&:after {
					content: ': ';
				}
			}
		}

		.price {
			white-space: nowrap;
		}
	}

	.product-info-stock-sku {
		display: table-cell;
		padding-bottom: 10px;
		padding-left: 10%;
		text-align: right;
		vertical-align: top;
	}

	.stock {
		margin: 0 0 3px;

		&.available,
		&.unavailable {
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	.product.attribute.sku {
		word-break: break-all;
		word-wrap: break-word;
	}

	.product {
		&.attribute {
			&.sku {
				.type {
					font-weight: normal;
					margin-right: 10px;

					&:after {
						content: '#:';
					}
				}

				.value {
					display: inline-block;
				}
			}

			&.overview {
				margin: 16px 0;
			}
		}

		&.alert {
			margin: 10px 0;
		}
	}

	.product-add-form {
		clear: both;
		padding-top: 10px + 10px;
	}

	.product-reviews-summary {
		float: left;
	}

	.product-options-bottom {
		.box-tocart {
			@include respond-to(xlarge) {
				margin-top: 10px;
			}
		}
	}
}

.product-options-wrapper {
	.fieldset {
		&:focus {
			box-shadow: none;
		}
	}

	.fieldset-product-options-inner {
		.legend {
			font-weight: 600;
			font-size: 14px;
			border: none;
			display: inline-block;
			float: none;
			margin: 0 0 8px;
			padding: 0;
		}

		//  Date & Time custom option (Affect Time that goes only after Date)
		input.datetime-picker {
			~ select.datetime-picker {
				margin-top: 10px;
			}
		}

		&.required,
		&._required {
			.legend {
				&:after {
					content: '*';
				}
			}
		}
	}

	.field {
		.note {
			display: block;
			margin-top: 10px;
		}
	}
	.field {
		.note.mage-error {
			color: red;
		}
	}
}

.product-options-bottom .price-box,
.product-info-price .price-box {
	color: #000;
	display: table-cell;
	padding-bottom: 10px;
	vertical-align: top;

	.old-price {
		font-size: 20px;
		font-weight: 300;

		.price-container > span {
			display: inline-block;
		}
	}

	.price-container {
		> span {
			display: block;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.price {
			font-size: 22px;
			font-weight: 600;
			line-height: 22px;
		}

		.price-including-tax + .price-excluding-tax,
		.weee + .price-excluding-tax,
		.weee {
			font-size: 12px;
			line-height: 14px;

			.price {
				font-size: 12px;
				font-weight: 700;
			}
		}
	}
}

.box-tocart {
	.action.tocart {
		vertical-align: top;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

.product-addto-links {
	display: inline;

	.action {
		margin-right: 5%;
	}
}

.product-social-links {
	margin: 0 0 20px;
	text-align: center;
}

.prices-tier {
	margin-bottom: 10px + 10px;

	.item {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.price-excluding-tax,
	.price-including-tax {
		display: inline-block;
	}
}

.minimal-price-link,
.price-excluding-tax,
.price-including-tax {
	display: block;
	white-space: nowrap;
}

//
//  Sidebar product view
//  -----------------------------------------

.sidebar {
	.product-items {
		.product-item {
			margin-bottom: 16px;
			position: relative;
		}

		.product-item-info {
			position: relative;
			width: auto;

			.product-item-photo {
				left: 0;
				position: absolute;
				top: 0;
			}
		}

		.product-item-name {
			margin-top: 0;
		}

		.product-item-details {
			margin: 0 0 0 85px;
		}

		.product-item-actions {
			display: block;
			margin-top: 10px;
		}

		.price-box {
			display: block;
			margin: 7px 0;
		}

		.text {
			margin-right: 8px;
		}

		.counter {
			color: #000;
			font-size: 12px;
			white-space: nowrap;
		}

		.minilist {
			.price {
				display: inline;
				padding: 0;
			}

			.weee:before {
				display: inline-block;
			}
		}

		.action {
			&.delete {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.block-compare {
		.action {
			&.delete {
				right: initial;
			}
		}
	}

	.action.tocart {
		border-radius: 0;
	}

	//
	//  Product images only
	//  -------------------------------------

	.product-items-images {
		//&:extend(.abs-add-clearfix all);
		margin-left: -10px;

		.product-item {
			//&:extend(.abs-add-box-sizing all);
			float: left;
			padding-left: 10px;
		}
	}

	//
	//  Product names only
	//  -------------------------------------

	.product-items-names {
		.product-item {
			display: flex;
			margin-bottom: 10px;
		}

		.product-item-name {
			margin: 0;
		}
	}
}

//
//  Category page 1 column layout
//  ---------------------------------------------

.catalog-category-view.page-layout-1column {
	.column.main {
		min-height: inherit;
	}
}

.catalog-category-view .category-view {
	.category-image {
		margin: 0;
		position: relative;

		.banner-title-tag {
			position: absolute;
			right: 7%;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			color: #fff;
			font-size: 34px;
			font-weight: 800;
			letter-spacing: 5px;
			margin: 0;
			background: rgba(128, 128, 128, 0.7);
			padding: 20px;


			@include respond-to(medium) {
				font-size: 40px;
				letter-spacing: 8px;
			}

			@include respond-to($screen__m, 'max') {
				right: auto;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}
}

.catalogsearch-result-index {
	.products-grid .product-item .product-item-inner {
		display: none;
	}

	.columns {

		.block {
			.title {
				margin-bottom: 12px !important;
			}

			a {
				color: #000;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.results  {
			.block {
				margin-top: 62px;
			}
		}
	}
}

.catalog-category-view,
.catalogsearch-result-index {
	.columns {
		.column.main {
			padding: 0;

			@include respond-to(medium, max) {
				order: 2;
			}

			.message.notice,
			.message.info.empty {
				margin-top: 61px;
			}

			.message.info.empty {
				font-size: 26px;
				text-transform: uppercase;
				padding: 0 30px;
				margin-bottom: 18px;
				font-weight: bold;
				background: none;
				color: #000;
				text-align: center;
				line-height: 1.5;

				& > div {
					&:before {
						content: none;
					}
				}
			}
		}
	}

	.page-title-wrapper,
	.category-description {
		display: none;
	}

	.breadcrumbs-toolbar-wrapper, .breadcrumbs-top-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 30px;
		background-color:#161616;
		margin-top: -1px;

		.breadcrumbs {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			margin: 0;
			max-width: 100%;
			padding: 0;

			.items {
				text-transform: uppercase;

				.item {
					line-height: 18px;
					letter-spacing: 1px;

					a,
					strong {
						color: #979797;
						font-weight: bold;
					}

					a {
						text-decoration: underline;
					}
				}
			}

			.items,
			a:visited {
				color: #979797;
				font-size: 12px;
			}

			.item:not(:last-child):after {
				color: #fff;
				margin: 0;
				font-size: 38px;

				@include respond-to(medium) {
					margin: 0 16px;
				}
			}

			.toolbar-amount {
				margin-right: 0;
				color: #979797;
			}

			.toolbar-products {
				margin-bottom: 0;
			}
		}

		.modes {
			display: none;
		}

		.limiter {
			display: block;

			.label {
				font-size: 12px;
				text-transform: uppercase;
			}

			.limiter-text {
				display: none;
			}

			.limiter-options {
				margin: 0 44px 0 15px;
			}
		}

		.toolbar select {
			font-size: 14px;
			border: none;
			color: #fff;
			font-weight: 700;
			text-transform: uppercase;
			cursor: pointer;
			background: url(../images/arrow-down.svg)#262626 no-repeat right 15px bottom 50%;
			padding: 5px 55px 5px 19px;
			margin: 0 7px;
			height: 45px;
		}

		.sorter {
			padding: 0;
			margin-right: 45px;
		}

		.toolbar-amount {
			padding: 0;
		}

		.toolbar-products {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			.toolbar-amount,
			.sorter-label,
			span {
				color: #979797;
				font-size: 12px;
				text-transform: uppercase;
			}

			.sorter-action {
				vertical-align: middle;

				&::before {
					color: #fff;
				}
			}
		}
	}

	.page-main {
		padding: 0;
	}

	.category-cms {
		.pagebuilder-poster-overlay {
			position: relative;
			height: 100%;
			aspect-ratio: 4;

			.pagebuilder-poster-content {
				position: static;
				width: 100%;
				z-index: 2;
			}

			[data-element='content'] {
				padding: 0 20px;

				h1 {
					font-size: 18px;
					margin-bottom: 8px;
					font-weight: 800;
					letter-spacing: 5px;
					background: rgba(128, 128, 128, 0.7);
					padding: 10px 20px;
					width: fit-content;
					margin: auto;
					line-height: 1.2;

					@include respond-to(small) {
						font-size: 24px;
						padding: 20px;
					}

					@include respond-to(medium) {
						font-size: 34px;
						letter-spacing: 8px;
						line-height: 1.1;
					}
					@include respond-to(large, max) {
						letter-spacing: 6.1px;
					}
				}

				h2 {
					font-size: 20px;
					font-weight: bold;
					letter-spacing: 1px;

					span {
						color: #fff !important;
					}
				}
			}
		}
	}

	.columns {
		padding-top: 0;
		padding-bottom: 106px;

		.block .title {
			margin-bottom: 48px;
			text-transform: uppercase;

			strong {
				font-size: 22px;
			}
		}
	}

	.no-product-catalog {
		.sidebar.sidebar-main {
			margin: 0 !important;
			padding: 0 !important;
			max-width: 0 !important;
		}
	}
}

.catalog-category-view.page-layout-category-products-list-view {
	.column.main {
		.product-items {
			padding: 0 30px;

			li {
				border-bottom: 1px solid #E8E8E8;
				padding: 30px 0;

				.product-item-info {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;

					.product-item-photo {
						flex: 1 1 60%;
						flex-direction: row;
						display: block;
						text-align: center;
						padding: 0;
					}

					.product-item-details {
						flex: 1 1 40%;
						display: block;
						padding-left: 30px;

						.product-item-link {
							font-size: 20px;
							font-weight: 800;
							letter-spacing: 0.71px;
							line-height: 16px;
						}

						.product-item-description .action.more {
							padding: 10px 30px;
							background-color: #000;
							color: #fff;
							font-size: 14px;
							font-weight: 800;
							letter-spacing: 0.5px;
							line-height: 16px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}

//
//  Mobile @screen__m
//  _____________________________________________

@include respond-to($screen__m, 'max') {
    .catalog-product-view {
        .column.main {
			display: flex;
			flex-direction: column;
        }

        .product.media {
            order: 1;
        }
    }

    .catalog-category-view,
    .catalogsearch-result-index {
        .category-cms {
            .pagebuilder-poster-overlay {
                [data-element='content'] {
	                h1, h2 {
                        text-align: center !important;
                    }
                }

                &:before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .3);
                    content: '';
                    z-index: 1;
                    top: 0;
                    left: 0;
                }
            }
        }

        .breadcrumbs-toolbar-wrapper {
            padding: 20px 20px;

            .toolbar-products {
                width: 100%;
                margin-top: 0 !important;
	            margin: auto;

                .toolbar-sorter,
                .limiter-options {
                    margin-right: 5px;
                }

                .toolbar-amount {
                    margin-right: 0;
                }
            }

            .breadcrumbs {
                display: none;
            }
        }

        .columns .toolbar {
            justify-content: center;
        }

        .no-product-catalog {
            .breadcrumbs-toolbar-wrapper {
                display: none;
            }
        }
    }
}

//
//  Mobile @screen__s
//  _____________________________________________

@include respond-to($screen__s, 'max') {
    .product-social-links {
        .action.tocompare {
            display: none;
        }
    }

    .product-info-price {
        margin: 0 -10px 0;

        > *:first-child {
            padding-left: 10px;
        }

        > *:last-child {
            padding-right: 10px;
        }
    }

    .catalog-category-view.page-layout-category-products-list-view {
        .column.main {
           
			.product-items {
                li {
                    padding: 0;

                    .product-item-info {
                        flex-direction: column !important;

                        .product-item-photo {
                            width: 100%;
                        }

                        .product-item-details {
                            padding-left: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include respond-to($screen__m) {
    .product-info-main {
        .page-title-wrapper {
            h1 {
               margin-bottom: 16px;
            }
        }

        .product-add-form {
            padding-top: 16px;
        }
    }

    .box-tocart {
        .action.tocart {
            margin-bottom: 0;
            margin-right: 1%;
            width: 49%;
        }
    }

    .product-social-links {
        text-align: left;
    }

    .product-options-bottom,
    .product-info-price {
        .price-box {
            .price-container {
                font-size: 18px;

                .price {
                    font-size: 36px;
                    line-height: 36px;
                }
            }

            .price-including-tax + .price-excluding-tax {
                .price {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }

    //
    //  Category page layout
    //  -----------------------------------------

    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: 14px;
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }

        .product.media {
            width: 57%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns,
    .page-layout-category-products-list-view {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }

    .product-add-form {
        .product-options-wrapper {
            .field {
                .control {
                    width: 80%;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 10px 10px 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    //
    //  Category page 1 column layout
    //  ---------------------------------------------

    .catalog-category-view.page-layout-1column {
        .column.main {
            min-height: inherit;
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include respond-to($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }

    .catalog-category-view,
    .catalogsearch-result-index {
        .breadcrumbs-toolbar-wrapper {
            padding: 20px 60px;
        }

        &.page-layout-2columns-left, &.page-layout-category-products-list-view {
            .sidebar-main {
                margin-left: 60px;
                max-width: 300px;
                padding-right: 60px;
            }
        }
    }
}

//
//  Common
//  _____________________________________________

//
//  Compare Products Page
//  -----------------------------------------

body.catalog-product-compare-index {
	.action.print {
		float: right;
		margin: 15px 0;
	}
}

.table-wrapper.comparison {
	clear: both;
	max-width: 100%;
	overflow-x: auto;
	position: relative; // Needed for Safari(iOS) to properly render 'overflow-x' rule.

	.table-comparison > tbody > tr {
		> th,
		> td {
			border-top: 0;
		}
	}
}

.table-comparison {
	table-layout: fixed;

	.cell {
		padding: 15px;
		width: 180px;

		&.label {
			.attribute.label {
				display: block;
				width: 100%;
				word-wrap: break-word;
			}
		}

		&.attribute {
			font-size: 13px;

			img {
				height: auto;
				max-width: 100%;
			}
		}

		&.remove {
			padding-bottom: 0;
			padding-top: 0;
			text-align: right;

			.action.delete {
				margin-right: .6rem;
			}
		}

		.attribute.value {
			overflow: hidden;
			width: 100%;
		}
	}

	.product-item-photo {
		display: block;
		margin: 0 auto 15px;
	}

	.product-image-photo {
		margin-left: 0;
	}

	.product-item-actions,
	.price-box,
	.product.rating,
	.product-item-name {
		display: block;
		margin: 15px 0;
	}

	.product-addto-links {
		margin-top: 15px;

		.action {
			&.toggle {
				padding: 0;
			}
		}
	}

	.action {
		&.tocart {
			white-space: nowrap;
		}
	}
}

.comparison.headings {
	background: #fff;
	left: 0;
	position: absolute;
	top: 0;
	width: auto;
	z-index: 2;
}

.block-compare {
	.product-item .product-item-name {
		margin-left: 22px;
	}

	.action.delete {
		left: 0;
		position: absolute;
		top: 0;
	}

	.actions-toolbar {
		margin: 17px 0 0;
	}
}


//
//  Mobile
//  _____________________________________________

@include respond-to($screen__s, 'max') {
    .table-wrapper.comparison {
        .table-comparison > tbody > tr {
            > th,
            > td {
                display: table-cell;
            }
        }
    }
}

@include respond-to($screen__m, 'max') {
    .compare.wrapper,
    [class*='block-compare'] {
        display: none;
    }
    .catalog-product_compare-index {
        .columns {
            .column {
                &.main {
                    flex-basis: inherit;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include respond-to($screen__l) {
    .catalog-category-view,
    .catalogsearch-result-index {
        &.page-layout-2columns-left, &.page-layout-category-products-list-view {
            .sidebar-main {
                max-width: 23%;
            }
        }
    }
}

@include respond-to($screen__m) {
    .catalog-category-view {
        &.page-layout-2columns-left, &.page-layout-category-products-list-view {
            .column.main {
                width: 80%;
                order: 2;
            }

            .sidebar-main {
                width: 23%;
                padding-right: 5%;
            }
        }
    }

    .catalog-category-view,
    .catalogsearch-result-index {
        &.page-layout-2columns-left, &.page-layout-category-products-list-view {
            .columns {
                display: flex;
				flex-wrap: nowrap;
            }

            .column.main {
                flex: 1;
                width: auto;
            }

            .sidebar-main {
                margin-left: 30px;
                margin-top: 61px;
                max-width: 27%;
                padding-right: 30px;
                width: 100%;
            }
        }

        .breadcrumbs-toolbar-wrapper .toolbar-products {
            justify-content: center;
        }

		.search-page-banner .pagebuilder-poster-overlay,
        .category-cms .pagebuilder-poster-overlay {
            .pagebuilder-poster-content {
                position: absolute;
	            right: 5.3%;
	            top: 37.1%;
	            width: auto;
            }

            [data-element='content'] {
                padding: 0;

                h1 {
                    font-size: 24px;
                    margin-bottom: 19px;

					@include respond-to(medium) {
						font-size: 40px;
					}
                }

                h2 {
                    font-size: 26px;
                    padding-right: 11px;

                    span {
                        opacity: 0.5;
                        color: #e8e8e8 !important;
                    }
                }
            }
        }
    }

    .compare.wrapper {
        float: right;

        .action.compare {
            line-height: 40px;
        }

        .counter.qty {
            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }

	.search-page-banner .pagebuilder-poster-overlay {
		position: relative;
	}
}


@include respond-to($screen__1200) {
    .catalog-category-view,
    .catalogsearch-result-index {
        .breadcrumbs-toolbar-wrapper {
			flex-direction: row;
        }
    }
}

@include respond-to($screen__1200, 'max') {
    .catalog-category-view,
    .catalogsearch-result-index {
        .breadcrumbs-toolbar-wrapper {
            .breadcrumbs {
                justify-content: center;
            }

            .toolbar-products {
                margin-top: 20px;
            }
        }
    }
}

@include respond-to($screen__s) {
    .catalog-category-view,
    .catalogsearch-result-index {
        .breadcrumbs-toolbar-wrapper {
            .toolbar {
                justify-content: space-between;
            }
        }
    }
}

@include respond-to($screen__s, 'max') {
    .catalog-category-view,
    .catalogsearch-result-index {
        .breadcrumbs-toolbar-wrapper {
            .toolbar {
               flex-wrap: wrap;

                .limiter,
                .toolbar-sorter {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;

                    label {
                        display: block;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                    }
                }

                .limiter-options {
                    margin: 0;
                }

                .sorter-options {
                    margin-left: 0;
                }
            }
        }

        .products-grid .product-item-details .product-item-inner {
            display: none;
        }
    }
}

//
//  Common
//  _____________________________________________



.block.related {
	.action.select {
		vertical-align: top;
	}

	.product-item-details {
		position: relative;
		z-index: 1;
	}

	.related-available .product-item-name {
		margin-left: 20px;
	}

	.field.choice {
		left: 0;
		position: absolute;
		top: 2px;
	}
}

//
//  Desktop large
//  _____________________________________________
@include respond-to($screen__l) {
    .box-tocart {
        .paypal:first-of-type {
            margin-top: 13px;
        }
    }
}
